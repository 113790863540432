<template>
  <div class="card">
    <div class="card-content">
      <div class="row">
        <div class="col-sm-12">
          <slot name="title"></slot>
        </div>
        <div class="col-sm-12">
          <slot name="header"></slot>
        </div>
        <div class="col-sm-12">
          <slot name="content"></slot>
        </div>
        <div class="col-sm-12">
          <slot name="qtys"></slot>
        </div>
        <div class="col-xs-12">
          <slot name="button"></slot>
        </div>
        <div class="col-xs-12">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>

<!--    <div class="card-footer">-->
<!--      <slot name="footer">-->
<!--&lt;!&ndash;        <hr>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="footer-title">&ndash;&gt;-->
<!--&lt;!&ndash;          <slot name="footer-title"></slot>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="pull-right">&ndash;&gt;-->
<!--&lt;!&ndash;          <slot name="footer-right"></slot>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </slot>-->
<!--    </div>-->
  </div>
</template>
<script>
export default {
  name: 'shipmentitem-card',
  props: ['linkto']
}

</script>
<style scoped>
.card-content{
  min-height: 85px;
}
.card .numbers{
  /*min-height: 180px;*/
}
.card .numbers.fullheight{
  max-height: none;
  height: auto;
}
.card img{
  max-width: 200px;
  max-height: 200px;
}

.card .numbers{
  font-size: 16px;
  color: #252422;
  text-align: center;
  height: auto;
  overflow: hidden;
  margin: 1em;
}

.card .buttons{
  text-align: right;
}

.card-footer{
  /* background-color: #eeeeee; */
  padding: 15px !important;
}

</style>
