<template>
  <div>
    <form @submit.prevent="submitShippingForm">
      <div class="input-group">
        <input :inputmode="keyboard" v-model="shipmentid" ref="inputfield" v-focus type="text" class="form-control" placeholder="Sendungsnummer">
        <span @click="submitShippingForm" class="input-group-addon"><i class="fa fa-search"></i></span>
      </div>
    </form>

    <stats></stats>
  </div>
</template>
<script>
  import axios from 'axios'
  import auth from '@/auth.js'
  import config from '@/config.js'
  import {Loading} from 'element-ui'

  import Stats from './StatsView.vue'

  export default {
    components: {
      Stats
    },
    data () {
      return {
        shipmentid: '',
        items: {}
      }
    },
    methods: {
      submitShippingForm () {
        if (this.shipmentid) {
          this.handleScan(this.shipmentid)
          this.shipmentid = ''
        }
      },
      handleScan (shipmentId) {
        let url = config.routes.commissionshipment
        if (shipmentId) {
          localStorage.setItem('commission_shipment', JSON.stringify(this.shipment))
          let loadingInstance = Loading.service({})
          axios
            .get(url, {
              params: {incrementId: shipmentId},
              headers: auth.getAuthHeader()
            })
            .then(response => {
              var items = response.data['items']
              if (items !== null) {
                localStorage.setItem('commission_shipmentId', JSON.stringify(shipmentId))
                localStorage.setItem('commission_shipment', JSON.stringify(response.data))
                localStorage.setItem('commission_scanItems', JSON.stringify(items))
                this.saveshipmentscan()
                this.saveshipmentcomment('Sendungslabel zum Kommissionieren gescannt')
                this.$router.push({name: 'Kommissionieren'})
              } else {
                this.$showErrorMessage(this.$notify,
                'Es wurden keine Sendung für "' + shipmentId + '" gefunden'
                )
                this.$router.push({ name: 'Picken' })
              }
              loadingInstance.close()
              this.backlink = shipmentId
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
              this.$showErrorMessage(this.$notify,
              'Es wurden keine Sendung für "' + shipmentId + '" gefunden'
              )
              console.error(e)
            })
        }
      },
      saveshipmentscan () {
        let url = config.routes.commissionscanshipment
        let shipment = JSON.parse(localStorage.getItem('commission_shipment'))
        if (shipment) {
          let loadingInstance = Loading.service({})
          axios
            .post(url, {
              'shipmentScan': {
                'shipmentId': shipment.shipment_id,
                'shipmentIncrementId': shipment.increment_id,
                  // "duration": 0,
                'packer': localStorage.getItem('username'),
                'timetype': 1,
                'ordervalue': shipment.order_value,
                'storeId': shipment.store_id
              }
            },
              { headers: auth.getAuthHeader() }
              )
            .then(response => {
              localStorage.setItem('commission_shipmentscan_id', JSON.stringify(response.data.shipmentscan_id))
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      saveshipmentcomment (comment) {
        let url = config.routes.commissionshipmentcomment
        let shipment = JSON.parse(localStorage.getItem('commission_shipment'))
        comment = comment + '<br/>by ' + localStorage.getItem('username')
        if (shipment) {
          let loadingInstance = Loading.service({})
          axios
            .post(url, {
              'shipmentId': shipment.shipment_id,
              'comment': comment
            },
              { headers: auth.getAuthHeader() }
              )
            .then(() => {
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
    },
    mounted () {
    },
    created () {
      this.keyboard = localStorage.getItem('keyboard')
      if (localStorage.getItem('commission_shipmentscan_id')) {
        localStorage.removeItem('commission_shipmentscan_id')
      }
      if (this.$route.params.q) {
        this.handleScan(this.$route.params.q)
      }
      if (localStorage.getItem('commission_scanItems') && localStorage.getItem('commission_shipmentId')) {
        localStorage.removeItem('commission_scanItems')
        localStorage.removeItem('commission_shipmentId')
        localStorage.removeItem('commission_shipment')
      }
    },
    computed: {},
    destroyed () {}
  }
</script>
