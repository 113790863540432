<template>
  <div>
    <div class="card">
      <div class="card-content">
        <div class="row">
          <div class="col-lg-12 col-sm-12">
            <h4 class="red">Bitte Auffälligkeiten und Anmerkungen hier eingeben</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <div class="col-sm-12">
              <textarea v-focus v-model="comment" class="form-control" placeholder="" rows="3"
                        tabindex="1"></textarea><br/><br/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6">
            <button ref="checkbutton" type="button" class="btn btn-md btn-warning btn-fill" @click="stepBack()"
                    tabindex="3">
                <span class="btn-label">
                    <i class="ti-angle-left"></i>
                </span>
              Zurück
            </button>

          </div>
          <div class="col-xs-6 text-right">

            <button ref="checkbutton" type="button" class="btn btn-md btn-success btn-fill" @click="save()"
                    tabindex="2">
              Speichern
              <span class="btn-label">
                    <i class="ti-check"></i>
                </span>
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from '@/config'
import {Loading} from 'element-ui'
import axios from 'axios'
import auth from '@/auth'

export default {
  data () {
    return {
      items: [],
      comment: '',
    }
  },
  methods: {
    stepBack () {
      this.$router.go(-1)
    },
    save () {
      let comment = ''
      let shipment = JSON.parse(localStorage.getItem('shipment'))
      if (shipment.comment !== this.comment) {
        // this.savescancomment(this.comment)
        comment = 'Pack Kommentar <br/>' + 'Kommentar: ' + this.comment + '<br/>'
        this.saveshipmentcomment(comment)
      }
      this.stepBack()
    },
    saveshipmentcomment (comment) {
      let url = config.routes.shipmentcomment
      let shipment = JSON.parse(localStorage.getItem('shipment'))
      shipment.comment = comment
      localStorage.setItem('shipment', JSON.stringify(shipment))
      comment = comment + 'by ' + localStorage.getItem('username')
      if (shipment) {
        let loadingInstance = Loading.service({})
        axios
          .post(url, {
            'shipmentId': shipment.shipment_id,
            'comment': comment
          },
            {headers: auth.getAuthHeader()}
          )
          .then(() => {
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
      }
    }
  },
  created () {
    if (localStorage.getItem('shipment')) {
      this.comment = JSON.parse(localStorage.getItem('shipment')).comment
    }
  },
  computed: {}
}
</script>
