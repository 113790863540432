<template>
  <div>
<!--    <form @submit.prevent="submitBoxForm">-->
<!--      <div class="input-group">-->
<!--        <input :inputmode="keyboard" v-model="boxid" ref="inputfield" type="text" v-focus class="form-control" placeholder="Karton" tabindex="1">-->
<!--        <span class="input-group-addon" @click="submitBoxForm"><i class="fa fa-search"></i></span>-->
<!--      </div>-->
<!--    </form>-->

    <div class="row">
      <div class="col-xs-9">
        <h4 :style="{ color: euCountry ? '' : 'red'}">Versandland: {{ shipment.shipment_country }}</h4>
        <h5 v-if="!euCountry && shipment.order_value > 1000">ACHTUNG! Warenwert ist {{ shipment.order_value.toFixed(2) }}!</h5>
      </div>

      <div class="col-xs-3 text-right">
        <button ref="checkbutton" type="button" class="btn btn-md btn-danger btn-fill pull-right"
                @click="gotocomment()">
          <span class="btn-label">
            <i class="fa fa-comment-dots fa-xl"></i>
          </span>
        </button>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 text-center" v-if="resultMessage">

        <h4 v-for="(message, index) in resultMessage.split('|')" :key="index">{{message}}</h4>

        <button v-if="resultMessage.indexOf('bereits gedruckt') !== -1" type="button"
                class="btn btn-mini btn-secondary btn-warning"
                @click="printLabel(1)">Nochmal drucken</button>

        <br/><br/>
      </div>
    </div>

    <form>
    <div class="row">
      <div class="col-lg-12 col-sm-12 text-center">
        <div class="col-lg-12 col-sm-12" v-for="(item, index) in boxes" :key="index">
          <box-card>
            <div class="numbers" slot="content">
<!--              <h4>SKU {{item.sku}} | {{item.name}}</h4>-->
              <h4>
                <button ref="checkbutton" type="button" class="btn btn-md btn-fill"
                        :disabled="!item.length || !item.width || !item.height || !item.weight"
                        @click="printShipmentBox(shipmentId, item)"
                        tabindex="2">
                        <span class="btn-label">
                            <i class="ti-printer"></i>
                        </span>
                </button>
              </h4>
              <div class="row">
                <div class="col-sm-12">
                  <div class="checkbox packages" v-if="item.box">
                    <input :id="item.box_id" type="checkbox" v-model="item.is_package" @change="onChange(item.box_id, $event)">
                    <label :for="item.box_id">
                      <span v-if="item.is_package">Paket {{item.packagenumber}}</span>
                      <span v-else>weiteres Paket</span>
                    </label>
                  </div>
                </div>
              </div>
                <div class="row" v-if="item.is_package === true">
                  <div class="col-sm-3">
                    <label>Seite 1 (cm)</label>
                    <ValidationProvider name="Seite 1" rules="required|integer|positive|max_value:999" v-slot="{ errors, classes }">
                      <div :class="classes">
                        <input v-focus type="number" v-model="item.length" class="form-control" placeholder="Seite 1" :tabindex="(item.packagenumber-1)*4+1+2" @blur="calculateMeasurements(); saveSizes(item)" v-on:keyup.enter="nextfield">
                        <ul><li v-for="(error, index) in errors" :key="index">{{ error }}</li></ul>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-3">
                    <label>Seite 2 (cm)</label>
                    <ValidationProvider name="Seite 2" rules="required|integer|positive|max_value:999" v-slot="{ errors, classes }">
                      <div :class="classes">
                        <input type="number" v-model="item.width" class="form-control" placeholder="Seite 2" :tabindex="(item.packagenumber-1)*4+2+2" @blur="calculateMeasurements(); saveSizes(item)" v-on:keyup.enter="nextfield">
                        <ul><li v-for="(error, index) in errors" :key="index">{{ error }}</li></ul>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-3">
                    <label>Seite 3 (cm)</label>
                    <ValidationProvider name="Seite 3" rules="required|integer|positive|max_value:999" v-slot="{ errors, classes }">
                      <div :class="classes">
                        <input type="number" v-model="item.height" class="form-control" placeholder="Seite 3" :tabindex="(item.packagenumber-1)*4+3+2" @blur="calculateMeasurements(); saveSizes(item)" v-on:keyup.enter="nextfield">
                        <ul><li v-for="(error, index) in errors" :key="index">{{ error }}</li></ul>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-3">
                    <label>Gewicht (kg)</label>
                    <ValidationProvider name="Gewicht" rules="required|positive|max_value:999" v-slot="{ errors, classes }">
                      <div :class="classes">
                        <input type="number" v-model="item.weight" class="form-control" placeholder="Gewicht" :tabindex="(item.packagenumber-1)*4+4+2" @blur="calculateMeasurements()" v-on:keyup.enter="nextfield">
                        <ul><li v-for="(error, index) in errors" :key="index">{{ error }}</li></ul>
                      </div>
                    </ValidationProvider>
                  </div>
                  </div>
                <div class="row" v-if="item.is_package">
                  <div class="col-sm-3"><br/><br/><label>VG</label><input type="text" disabled="true" v-model="item.volume_weight" class="form-control" placeholder="Gewicht"></div>
                  <div class="col-sm-3"><br/><br/><label>GM</label><input type="text" disabled="true" v-model="item.belt_size" class="form-control" placeholder="Gurtmaß"></div>
                  <div class="col-sm-3"><br/><br/><label>Liter</label><input type="text" disabled="true" v-model="item.volume" class="form-control" placeholder="Volumen"></div>
                  <div class="col-sm-3"><br/><br/><label>Seiten</label><input type="text" disabled="true" v-model="item.sides_total" class="form-control" placeholder="Seiten"></div>
                  <div class="col-sm-3"><br/><br/><label>Falltest durchgeführt?</label><br/>
                    <p-switch class="" v-model="item.falltest">
                      <i class="fa fa-check" slot="on"></i>
                      <i class="fa fa-times" slot="off"></i>
                    </p-switch>
                  </div>

                  <div class="row" v-if="item.is_package">
                    <div class="col-sm-12 text-center"><br/>
                      <p class="danger" v-if="!checkLogistician('DHL', item)">ACHTUNG: Paket überschreitet Standardmaß von DHL</p>
                      <p class="danger"  v-if="!checkLogistician('DPD', item)">ACHTUNG: Paket überschreitet Standardmaß von DPD</p>
                      <p class="danger"  v-if="!checkLogistician('UPS', item)">ACHTUNG: Paket überschreitet Standardmaß von UPS</p>
                    </div>
                  </div>
                </div>
            </div>
            <div slot="button">
              <br/>
              <div class="col-sm-6" style="padding-left:0;">
                <button ref="checkbutton"
                        type="button"
                        class="btn btn-sm btn-danger btn-fill pull-left"
                        @click="calculateDonbotPrice(item)"
                        :disabled="!item.length || !item.width || !item.height || !item.weight"
                >
                  <span class="btn-label">
                      <i class="fa ti-truck"></i>
                  </span>
                  Paketpreis berechnen
                </button>
              </div>
              <div class="col-sm-6" style="padding-right:0;">
                <button ref="checkbutton" type="button" class="btn btn-sm btn-danger btn-fill pull-right" @click="showSwal(item)">
                  <span class="btn-label">
                      <i class="fa ti-trash"></i>
                  </span>
                  löschen
                </button>
              </div>
              <br/>
            </div>
          </box-card>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12" style="margin-bottom:1em">
        <button ref="checkbutton" type="button" class="btn btn-md btn-primary btn-fill" @click="submitBoxForm()">
                  <span class="btn-label">
                      <i class="fa ti-package"></i>
                  </span>
          neues Paket
        </button>
      </div>
      <div class="col-xs-6">
        <button ref="checkbutton" type="button" class="btn btn-md btn-warning btn-fill" @click="stepBack()">
                <span class="btn-label">
                    <i class="ti-angle-left"></i>
                </span>
          Zurück
        </button>
      </div>
      <div class="col-xs-6 text-right">
        <button ref="checkbutton" type="button" class="btn btn-wd btn-success btn-fill" @click="prefinish()" :tabindex="(boxes.length*4)+1+2">

          Abschließen <span class="btn-label">
                    <i class="ti-check"></i>
                </span>
        </button>
        <br/>
        <br/>
      </div>
    </div>
    </form>
  </div>
</template>
<script>
  import {SoundOk, SoundError} from '@/main.js'
  import config from '@/config.js'
  import BoxCard from '@/components/Cards/BoxCard'
  import Swal from 'sweetalert2'
  import numeral from 'numeral'
  import {Loading} from 'element-ui'
  import axios from 'axios'
  import auth from '@/auth'
  import { extend, configure } from 'vee-validate'
  import PSwitch from '@/components/Switch.vue'

  export default {
    components: {
      BoxCard,
      PSwitch
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    data () {
      return {
        boxes: [],
        lvItems: [],
        shipment: {},
        boxid: '',
        isFirst: 0,
        print_place: '',
        resultMessage: '',
        euCountry: true
      }
    },
    methods: {
      submitBoxForm () {
        if (! this.boxid) {
          this.boxid = 'lv022'
        }
        this.handleScan(this.boxid)
        this.boxid = ''
      },
      stepBack () {
        this.$router.push({ name: 'Prüfen' })
      },
      handleScan (searchString) {
        if (searchString === config.endSequence) {
          this.cancel()
          return
        }

        let item = this.findItem(searchString)
        let shipment = JSON.parse(localStorage.getItem('shipment'))
        if (item !== undefined) {
          SoundOk.play()
          item.is_package = true

          let url = config.routes.boxes
          let loadingInstance = Loading.service({})
          axios
            .post(url, {
              'box': {
                'shipmentId': shipment.shipment_id,
                'shipmentIncrementId': shipment.increment_id,
                'sku': item.sku,
                'packer': localStorage.getItem('username'),
                'is_package': item.is_package ? 1 : 0
              }
            },
            { headers: auth.getAuthHeader() }
          )
          .then(response => {
            item.box_id = response.data.box_id
            item.volume = item.volume_weight = item.belt_size = item.sides_total = 0
            item.label_printed = false
            localStorage.setItem('shipmentscan_id_check', JSON.stringify(response.data.box_id))
            this.boxes.push(item)
            this.updateLocalStorage()
            this.boxes = JSON.parse(localStorage.getItem('boxItems'))
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })

          if (localStorage.getItem('boxItems')) {
            this.boxes = JSON.parse(localStorage.getItem('boxItems'))
            this.calculateMeasurements()
          }
        }
      },
      showSwal: function (item) {
        Swal.fire({
          icon: 'question',
          title: 'Willst du sicher den Karton' + item.sku + ' entfernen?',

          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-danger btn-fill',
            cancelButton: 'btn btn-success btn-fill'
          },
          confirmButtonText: 'Ja',
          cancelButtonText: 'Nein',
          buttonsStyling: false,
          reverseButtons: false
        }).then((result) => {
          if (result.value) {
            let url = config.routes.boxes + '/' + item.box_id
            let loadingInstance = Loading.service({})
            axios
              .delete(url, { headers: auth.getAuthHeader() })
              .then(() => {
                loadingInstance.close()
              })
              .catch(e => {
                loadingInstance.close()
                if (e.response.status == 401) {
                  auth.logout()
                }
              })
            this.delete(item.box_id)
          }
        })
      },
      cancel () {
        localStorage.removeItem('scanItems')
        localStorage.removeItem('boxItems')
        localStorage.removeItem('shipmentId')
        localStorage.removeItem('shipment')
        this.$router.push({ name: 'Packen' }).catch(() => {})
      },
      delete (boxId) {
        let index = this.getItemIndex(boxId)
        if (index >= 0) {
          this.boxes.splice(index, 1)
          this.updateLocalStorage()
          this.$refs.inputfield.focus()
        }
      },
      findItem (searchString) {
        let items = this.lvItems
        let f
        let found
        found = items.findIndex(function (item, index) {
          f = index
          return item.sku.toLowerCase() === searchString.toLowerCase() || item.box_id === searchString.toLowerCase()
        })
        if (found >= 0) {
          return items[f]
        } else {
          SoundError.play()
        }
      },
      getItemIndex (boxId) {
        let items = this.boxes
        let f
        let found
        found = items.findIndex(function (item, index) { f = index; return item.box_id === boxId })
        if (found >= 0) {
          return f
        }
      },
      onChange () {
        this.updateLocalStorage()
      },
      gotocomment () {
        this.$router.push({name: 'Packkommentar'})
      },
      async saveBoxes () {
        if (this.checkBoxes() === false) {
          Swal.fire({
            title: 'Bitte mindestens ein Paket erstellen!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
              cancelButton: 'btn btn-danger btn-fill'
            },
            buttonsStyling: false,
            reverseButtons: true,
            didClose: () => {
              this.$refs.inputfield.focus()
            }
          })
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('Bitte mindestens ein Paket erstellen!')
        } else if (this.checkMeasurements() === false) {
          Swal.fire({
            title: 'Bitte bei jedem Paket die Maße/Gewicht eingeben',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
              cancelButton: 'btn btn-danger btn-fill'
            },
            buttonsStyling: false,
            reverseButtons: true
          })
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('Bitte bei jedem Paket die Maße/Gewicht eingeben')
        } else {
          for (let item of this.boxes) {
            this.saveSizesForBox(item)
          }
        }
      },
      saveSizesForBox (item) {
        let url = config.routes.boxes + '/' + item.box_id
        let loadingInstance = Loading.service({})
        axios
          .put(url, {
                'box': {
                  'length': item.length * 1,
                  'width': item.width * 1,
                  'height': item.height * 1,
                  'weight': item.weight * 1,
                  'volume': item.volume * 1,
                  'volume_weight': item.volume_weight * 1,
                  'belt_dimension': item.belt_size * 1,
                  'sides_total': item.sides_total * 1,
                  'is_package': item.is_package * 1,
                  'falltest': item.falltest * 1
                }
              },
              { headers: auth.getAuthHeader() }
          )
          .then(() => {
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
      },
      checkBoxes () {
        this.updateLocalStorage()

        let hasPackage = false
        if (this.boxes.length === 0) {
          return false
        }

        for (let item of this.boxes) {
          if (item.is_package === true) {
            hasPackage = true
          }
        }

        return hasPackage !== false;


      },
      checkMeasurements () {
        this.updateLocalStorage()
        for (let item of this.boxes) {
          if (item.is_package === true) {
            if (!item.height || !item.width || !item.length || !item.weight) {
              return false
            }
          }
        }
        return true
      },
      checkLogistician (logistician, box) {
        if (!box.length || !box.width || !box.height || !box.weight) {
          return true
        }
        let sizes = [box.length, box.width, box.height]
        sizes.sort((a, b) => b - a)
        if (logistician === 'DHL') {
          if (sizes[0] > 120 || sizes[1] > 60 || sizes[2] > 60 || box.weight > 31.5) {
            return false
          }
        } else if (logistician === 'DPD') {
          if (sizes[0] > 130 || sizes[1] > 80 || sizes[2] > 80 || box.weight > 31.5 || box.belt_size > 300) {
            return false
          }
        } else if (logistician === 'UPS') {
          if (sizes[0] > 100 || sizes[1] > 76 || sizes[2] > 76 || box.weight > 32 || box.belt_size > 300) {
            return false
          }
        }/* else if (logistician === 'GLS') {
          if (sizes[0] > 100 || sizes[1] > 76 || sizes[2] > 76 || box.weight > 32 || box.belt_size > 300) {
            return false
          }
        }*/
        return true
      },
      checkSizesAndWeight () {
        for (let item of this.boxes) {
          if (item.is_package === true) {
            if (!this.checkLogistician('DHL', item) && !this.checkLogistician('DPD', item) && !this.checkLogistician('UPS', item)) {
              return false
            }
          }
        }
        return true
      },
      calculateMeasurements () {
        for (let item of this.boxes) {
          if (item.is_package === true) {
            if (item.height) {
              item.height = Math.ceil(item.height)
              if (item.height < 0) item.height = 0
            }
            if (item.length) {
              item.length = Math.ceil(item.length)
              if (item.length < 0) item.length = 0
            }
            if (item.width) {
              item.width = Math.ceil(item.width)
              if (item.width < 0) item.width = 0
            }
            if (item.weight) {
              item.weight = Math.round(item.weight * 10 + Number.EPSILON) / 10
              if (item.weight < 0) item.weight = 0
            }
            if (!item.height || !item.width || !item.length) {
              item.belt_size = item.volume = item.volume_weight = item.sides_total = ''
              return false
            } else {
              item.volume_weight = (item.height * item.width * item.length) / 5000
              item.volume = (item.height * item.width * item.length) / 1000

              let largestSide = Math.max(item.height, item.width, item.length)
              if (largestSide === item.length * 1) {
                item.belt_size = (item.height * 1 + item.width * 1) * 2 + item.length * 1
              } else if (largestSide === item.height * 1) {
                item.belt_size = (item.length * 1 + item.width * 1) * 2 + item.height * 1
              } else if (largestSide === item.width * 1) {
                item.belt_size = (item.length * 1 + item.height * 1) * 2 + item.width * 1
              }
              item.sides_total = item.height + item.width + item.length
            }
          }
        }
        this.updateLocalStorage()
      },
      saveSizes (item) {
        if (item.length && item.width && item.height) {
          if (this.euCountry === false) {
            if (item.label_printed === false) {
              this.printShipmentBox(this.shipment.increment_id, item)
            }
          }
          this.saveSizesForBox(item)
        }
      },
      prefinish () {
        if (!this.checkSizesAndWeight()) {
          Swal.fire({
            title: 'Das Paket scheint sehr groß oder sehr schwer zu sein. Bist du sicher?',
            icon: 'error',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
              cancelButton: 'btn btn-danger btn-fill'
            },
            buttonsStyling: false,
            reverseButtons: true,
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
          }).then((result) => {
            if (result.value) {
              this.finish()
            }
          })
        } else {
          this.finish()
        }
      },
      finish () {
        this.saveBoxes()
          .then(() => {
            this.checkIsFirstShipment()
              .then(() => {
                let comment = 'Packen Ende<br/><br/>'
                let boxes = []
                if (localStorage.getItem('boxItems')) {
                  boxes = JSON.parse(localStorage.getItem('boxItems'))
                  let idx = 0
                  for (let item of boxes) {
                    if (item.is_package) {
                      if (idx) {
                        comment = '<br/><br/>' + comment
                      }
                      /**
                       Paketnummer: 1
                       SKU LV022: Altkartonage
                       Seite 1-3: 27cm / 19cm / 9cm
                       Gewicht: 0,4kg
                       VG: 0,9kg / GM: 83cm / Liter: 4,6
                       */
                      let sizes = [item.length, item.width, item.height]
                      sizes.sort((a, b) => b - a)
                      comment = comment +
                        'Paket: ' + item.packagenumber + ' <br/>' +
                        'SKU ' + item.sku + ': ' + item.name + ' <br/>' +
                        'Seite 1-3: ' + sizes[0] + 'cm / ' + sizes[1] + 'cm / ' + sizes[2] + 'cm <br/>' +
                        'Gewicht: ' + item.weight + 'kg' + ' <br/>' +
                        'VG: ' + item.volume_weight.toFixed(1) + 'kg / GM: ' + item.belt_size.toFixed(1) +
                        ' / Seiten: ' + item.sides_total.toFixed(1) + 'cm ' +
                        'cm / Liter: ' + item.volume.toFixed(1) + 'l' + ' <br/>' +
                        'Falltest durchgeführt? ' + (item.falltest ? 'ja' : 'nein') + ' <br/>'
                      if (this.print_place) {
                        comment = comment + 'Platz: ' + config.printplaces[this.print_place] + '<br/>'
                      } else {
                        comment = comment + 'Platz: nicht ausgewählt<br/>'
                      }
                      comment = comment + '<br/>'
                    } else {
                      comment = comment +
                        'zusätzlicher Karton: <br/>' +
                        'SKU ' + item.sku + ': ' + item.name + ' '
                    }
                    idx++
                  }
                  comment = comment + ' <br/>'
                }

                this.endshipment().then(() => {
                  let shipment = JSON.parse(localStorage.getItem('shipment'))
                  let packed = 1
                  let promiseExecution = async () => {
                    await Promise.all([this.printLabel(0), this.setShipmentStatus(shipment, packed), this.saveshipmentcomment(comment), this.deleteAllComments(), this.createInvoice(shipment.shipment_id), this.checkCommissionBox()]).then(function () {
                      console.log('all finished')
                    })
                  }
                  promiseExecution()
                })
              })
          })
          .catch(e => {
            console.log(e.message)
          })
      },
      endshipment () {
        let shipment = JSON.parse(localStorage.getItem('shipment'))
        let shipmentScanId = JSON.parse(localStorage.getItem('shipmentscan_id_pack'))
        let isFirst = this.isFirst
        if (shipment) {
          let url = config.routes.scanshipment + '/' + shipmentScanId
          let loadingInstance = Loading.service({})
          console.log('end shipment' + url)
          return axios
            .put(url, {
              'shipmentScan': {
                'endtime': new Date().toISOString().replace('T', ' ').substr(0, 10) + ' ' + new Date().toLocaleTimeString(),
                'isFirst': isFirst
              }
            }, { headers: auth.getAuthHeader() })
            .then(() => {
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      saveshipmentcomment (comment, shipmentId = '') {
        let url = config.routes.shipmentcomment
        console.log(url)
        let shipment = JSON.parse(localStorage.getItem('shipment'))
        if (shipmentId === '') {
          shipmentId = shipment.shipment_id
        }
        comment = comment + '<br/>by ' + localStorage.getItem('username')
        if (shipmentId) {
          axios
            .post(url, {
              'shipmentId': shipmentId,
              'comment': comment
            },
              {headers: auth.getAuthHeader()}
            )
            .then(() => {
            })
            .catch(e => {
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      checkIsFirstShipment () {
        let shipmentId = JSON.parse(localStorage.getItem('shipmentId'))
        let url = config.routes.scanshipment + '/search/?' +
          'searchCriteria[filter_groups][0][filters][0][field]=shipment_increment_id&' +
          'searchCriteria[filter_groups][0][filters][0][value]=' + shipmentId + '&' +
          'searchCriteria[filter_groups][0][filters][0][condition_type]=eq&' +
          'searchCriteria[filter_groups][1][filters][0][field]=timetype&' +
          'searchCriteria[filter_groups][1][filters][0][value]=2&' +
          'searchCriteria[filter_groups][1][filters][0][condition_type]=eq&' +
          'searchCriteria[filter_groups][2][filters][0][field]=is_first&' +
          'searchCriteria[filter_groups][2][filters][0][value]=1&' +
          'searchCriteria[filter_groups][2][filters][0][condition_type]=eq'
        let loadingInstance = Loading.service({})
        return axios
          .get(url, {
            params: {incrementId: shipmentId},
            headers: auth.getAuthHeader()
          })
          .then(response => {
            loadingInstance.close()
            var result = response.data
            if (result.items.length === 0) {
              this.isFirst = 1
            } else {
              this.isFirst = 0
            }
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
      },
      updateLocalStorage () {
        let nrPackages = 0
        for (let item of this.boxes) {
          if (item.is_package === true) {
            nrPackages++
            item.packagenumber = nrPackages
          } else {
            item.packagenumber = 0
            item.volume = item.volume_weight = item.width = item.height = item.length = item.belt_size = item.sides_total = item.weight = ''
            item.falltest = false
          }
        }
        localStorage.setItem('boxItems', JSON.stringify(this.boxes))
      },
      nextfield: function (event) {
        let elem = event.target
        let currentIndex = elem.getAttribute('tabindex')
        let nextIndex = currentIndex * 1 + 1
        for (let element of elem.form.elements) {
          if (element.getAttribute('tabindex') === nextIndex + '') element.focus()
        }
      },
      printLabel (force) {
        if (!this.print_place) {
          return
        }
        let shipment = JSON.parse(localStorage.getItem('shipment'))
        let shipmentId = shipment.shipment_id
        let shipmentIncrementId = shipment.increment_id
        let url = config.routes.processshipment + '/' + shipmentIncrementId + '/' + this.print_place + '/' + force
        console.log('print label' + url)
        return axios
          .get(url, {
            params: {},
            headers: auth.getAuthHeader()
          })
          .then(response => {
            this.resultMessage = response.data[0]
            console.log(this.resultMessage)
            this.logistician = response.data[1]
            if (response.data[1]) {
              let comment = 'Label Druck<br/><br/>' +
                'Platz: ' + config.printplaces[this.print_place] + '<br/>' +
                'Modus: ' + 'Automatik (Pack)' + '<br/>' +
                'Logistiker: ' + this.logistician + '<br/>'
              return this.saveshipmentcomment(comment, shipmentId)
            }
          })
          .catch(e => {
            if (e.response.status == 401) {
              auth.logout()
            }
          })
      },
      createInvoice (shipmentId) {
        let url = config.routes.createinvoice + '/' + shipmentId
        console.log(url)
        return axios
          .get(url, {
            params: {},
            headers: auth.getAuthHeader()
          })
          .then(response => {
            this.resultMessage = response.data[0]
            this.logistician = response.data[1]
            if (response.data[1] === 'order created') {
              let comment = 'Rechnung Druck<br/><br/>'
              return this.saveshipmentcomment(comment, shipmentId)
            }
          })
          .catch(e => {
            if (e.response.status == 401) {
              auth.logout()
            }
          })
      },
      deleteAllComments () {
        let items = JSON.parse(localStorage.getItem('scanItems'))
        for (let i = 0; i < items.length; i++) {
          if (items[i].scan_comment[2] !== undefined) {
            this.resetComment(items[i])
          }
        }
      },
      resetComment(item){
        // delete pack comment
        let url = config.routes.scancomment + '/' + item.scan_comment[0]
        let loadingInstance = Loading.service({})
        axios
            .delete(url, {
              headers: auth.getAuthHeader()
            })
            .then(() => {
              loadingInstance.close()
              item.scan_comment = []
              // write shipment comment that pack comment has been deleted
              let shipment = JSON.parse(localStorage.getItem('shipment'))
              let shipmentComment = 'Packkommentar zum Artikel mit der SKU ' + item.sku + ' wurde von '
                  + localStorage.getItem('username') + ' gelöscht'
              axios
                  .post(config.routes.shipmentcomment, {
                        'shipmentId': shipment.shipment_id,
                        'comment': shipmentComment
                      },
                      {headers: auth.getAuthHeader()}
                  )
                  .then(() => {
                    loadingInstance.close()
                  })
                  .catch(e => {
                    loadingInstance.close()
                    if (e.response.status == 401) {
                      auth.logout()
                    }
                  })
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      },
      setShipmentStatus(shipment, packed) {
        let loadingInstance = Loading.service({})
        axios
            .post(config.routes.shipmentStatus, {
                  'shipmentStatus': {
                    'shipmentId': shipment.shipment_id,
                    'shipmentIncrementId': shipment.increment_id,
                    'packed': packed
                  }
                },
                { headers: auth.getAuthHeader() }
            )
            .then(() => {
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      },
      printShipmentBox: function (shipmentId, box) {
        let printPlace = JSON.parse(localStorage.getItem('print_place'))
        if (printPlace) {
          this.callPrintFunction(shipmentId, printPlace, box)
        } else {
          Swal.fire({
            title: 'Drucker',
            showCancelButton: true,
            cancelButtonText: 'Abbrechen',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
              cancelButton: 'btn btn-danger btn-fill',
            },
            input: 'select',
            inputOptions: config.printplaces,
            inputAttributes: {
              required: true
            },
            reverseButtons: true,
            validationMessage: 'Bitte Drucker auswählen',
            inputPlaceholder: 'Bitte wählen',
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.value) {
              printPlace = result.value
              this.callPrintFunction(shipmentId, printPlace, box)
            }
          })
        }
      },
      callPrintFunction(shipmentId, printPlace, box) {
        let url = config.routes.printlabel
        let loadingInstance = Loading.service({})
        let boxDimensions = {}
        if (box) {
          boxDimensions = {
            'boxLength': box.length,
            'boxWidth': box.width,
            'boxHeight': box.height,
            'boxWeight': box.weight
          }
        }
        axios
            .post(url, {
                  'shipmentIncrementId': shipmentId,
                  'boxDimensions': boxDimensions,
                  'productId': 0,
                  'qty': 1,
                  'printLocation': printPlace,
                  'attributeCode': ''
                },
                {
                  headers: auth.getAuthHeader()
                })
            .then(response => {
              if (response.data) {
                Swal.fire({
                  title: 'Erfolgreich',
                  html: 'Sendung ' + shipmentId + ' gedruckt',
                  width: 400
                })
                // sicherstellen, dass das Label nur 1x gedruckt wird
                for (let item of this.boxes) {
                  if (item.box_id === box.box_id) {
                    item.label_printed = true
                  }
                }
                localStorage.setItem('boxItems', JSON.stringify(this.boxes))
                this.saveshipmentcomment('Sendungslabel wurde gedruckt')
              }
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      },
      checkCommissionBox () {
        if (localStorage.getItem('commission_boxes_shipment')) {
          let scannedBox = JSON.parse(localStorage.getItem('commission_box'))
          let boxesShipments = JSON.parse(localStorage.getItem('commission_boxes_shipment'))
          let shipmentIncrementId = JSON.parse(localStorage.getItem('shipmentId'))
          const indexToDelete = boxesShipments.findIndex(
              (item) => item.shipmentIncrementId === shipmentIncrementId
          );
          if (indexToDelete !== -1) {
            boxesShipments.splice(indexToDelete, 1);
            if (boxesShipments.length > 1) {
              let returnValue = [boxesShipments]
              this.$router.push({name: 'PickBoxes', params: {q: scannedBox, s: returnValue}})
            } else if (boxesShipments.length == 1) {
              let incrementId = boxesShipments[0].shipmentIncrementId
              this.$router.push({name: 'Packen', params: {q: incrementId}})
            } else {
              this.$showErrorMessage(this.$notify,
                  'Es wurden keine weiteren Sendungen für "' + scannedBox + '" gefunden'
              )
              this.$router.push({name: 'Packen'}).catch(() => {})
            }
          } else {
            this.$router.push({ name: 'Packen' }).catch(() => {})
          }
        } else {
          this.$router.push({ name: 'Packen' }).catch(() => {})
        }
      },
      calculateDonbotPrice(box) {
        let url = config.routes.donbot
        let loadingInstance = Loading.service({})
        axios.get(url, {
          params: {
            "length": box.length,
            "width": box.width,
            "height": box.height,
            "weight": box.weight,
            "amount": this.shipment.order_value,
            "country": this.shipment.shipment_country,
            "zipcode": this.shipment.shipment_zip,
            "express": 0,
            "return": 0,
          },
          headers: auth.getAuthHeader()
        })
        .then((result) => {
          if (result.data[0]) {
            const logisticans = result.data[0]
            let message = "Preise der Logistikanbieter:\n\n";

            for (const logisticanName in logisticans) {
              const price = logisticans[logisticanName].price;
              message += `${logisticanName}: ${price} Euro\n`;
            }

            Swal.fire({
              title: message,
              width: 400
            })
          }
          loadingInstance.close()
        })
        .catch(error => {
          Swal.showValidationMessage(error)
          loadingInstance.close()
        })
      },
      getEuCountry (shipment) {
        if (shipment.shipment_country) {
          // check whether country is EU or not -> if false print label of shipment automatically
          let loadingInstance = Loading.service({})
          axios
              .get(config.routes.eucountry + '/' + shipment.shipment_country,
                  { headers: auth.getAuthHeader() }
              )
              .then(response => {
                this.euCountry = response.data
                loadingInstance.close()
              })
              .catch(e => {
                loadingInstance.close()
                if (e.response.status == 401) {
                  auth.logout()
                }
              })
        } else {
          this.euCountry = true;
          this.shipment.shipment_country = '';
        }
      }
    },
    mounted () {
    },
    created () {
      this.keyboard = localStorage.getItem('keyboard')
      if (localStorage.getItem('lvItems')) {
        this.lvItems = JSON.parse(localStorage.getItem('lvItems'))
      }
      if (localStorage.getItem('boxItems')) {
        this.boxes = JSON.parse(localStorage.getItem('boxItems'))
        this.calculateMeasurements()
      }
      if (localStorage.getItem('print_place')) {
        this.print_place = JSON.parse(localStorage.getItem('print_place'))
      }
      this.shipment = JSON.parse(localStorage.getItem('shipment'))
      // this.volume_weight = numeral(this.shipment.volume_weight).format('0.00')
      // this.weight = numeral(this.shipment.weight).format('0.00')
      // load a locale
      if (numeral.locales['de'] === undefined) {
        numeral.register('locale', 'de', {
          delimiters: {
            thousands: '.',
            decimal: ','
          },
          abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't'
          },
          currency: {
            symbol: '€'
          }
        })
      }
      numeral.locale('de')

      // Add a rule.
      extend('positive', value => {
        if (value > 0) {
          return true
        }

        return '{_field_} muss eine Zahl größer 0 sein'
      })

      configure({
        classes: {
          valid: 'is-valid',
          invalid: 'has-error'
        }
      })
      if (this.boxes.length === 0) {
        this.submitBoxForm()
      }
      this.getEuCountry(this.shipment)
    },
    computed: {
      shipmentId () {
        if (localStorage.getItem('shipmentId')) {
          return JSON.parse(localStorage.getItem('shipmentId'))
        } else { return {} }
      }
    },
    destroyed () {
    }
  }
</script>

<style>
p.danger{
  font-weight: bold;
  color: red;
}
</style>
