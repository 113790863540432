<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright pull-right">
        &copy; Coded with
        <i class="fa fa-heart heart"></i>
      </div>
      <button class="copyright" @click="Keyboard()">
        <i class="fa fa-keyboard"></i> {{ keyboard }}
      </button>
    </div>
  </footer>
</template>
<script>
  export default {
    methods: {
      Keyboard() {
        let keyboard = localStorage.getItem('keyboard')
        if (keyboard == 'none') {
          keyboard = 'text'
        } else if (keyboard == 'text') {
          keyboard = 'none'
        } else {
          keyboard = 'none'
        }
        localStorage.setItem('keyboard', keyboard)
        location.reload()
      }
    },
    created () {
      let keyboard = localStorage.getItem('keyboard')
      if (keyboard == 'none') {
        keyboard = 'OFF'
      } else if (keyboard == 'text') {
        keyboard = 'ON'
      } else {
        keyboard = 'OFF'
      }
      this.keyboard = keyboard
    }
  }

</script>
<style>

</style>
