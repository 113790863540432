<template>
  <div>
    <form @submit.prevent="submitShippingItemForm" v-if="!checkAllDone()">
      <div class="input-group">
        <input :inputmode="keyboard" v-model="articleid" ref="inputfield" type="text" v-focus class="form-control" tabindex="1" placeholder="Artikel">
        <span class="input-group-addon" @click="submitShippingItemForm"><i class="fa fa-search"></i></span>
      </div>
    </form>
    <div class="row" v-if="items.length">
      <div class="col-xs-10">
        <h4>{{shipmentId}}
          <button ref="checkbutton" type="button" class="btn btn-xs btn-fill"
                  @click="printShipment(shipmentId)"
                  tabindex="2">
          <span class="btn-label">
              <i class="ti-printer"></i>
          </span>
          </button>
        </h4>
      </div>
      <div class="col-xs-9">
        <h5 :style="{ color: isCountryRed(shipment.shipment_country) ? 'red' : ''}">Versandland: {{ shipment.shipment_country }}</h5>
      </div>
      <div class="col-xs-2">
        <span v-for="(item, index) in items" :key="index">
          <span class="pull-right icon-problem" v-if="item.scan_comment[2]">
            <i class="icon-danger ti-alert"></i>
          </span>
        </span>
      </div>
    </div>
    <div class="row" v-if="items.length">
      <div class="col-lg-6 col-sm-6" v-for="(item, index) in items" :key="index">
        <shipmentitem-card :class="item.qty_scanned == item.qty || item.checkedComplete == true ? 'finished' : 'false' " :linkto="{ name: 'Produkt', params: { productid: item.id}}">>
          <div class="text-center" :class="`icon-info`" slot="header">
            <table class="storageLocationTable">
              <tr class="label-parc2" v-for="(commissionBox, index) in item.commission_boxes" :key="index">
                <td>{{ commissionBox }}</td>
              </tr>
            </table>
            <div class="image-container">
              <img :src="item.image" @click="showSwalImage(item)" @error="item.image = '/img/placeholder.jpg'" />
              <span v-if="item.scan_comment[2]" @click="showSwalImage(item)">
                <i class="watermark-icon icon-danger ti-alert"></i>
              </span>
            </div>
          </div>
          <div class="numbers" slot="content">
            <button ref="checkbutton" :disabled="item.qty_scanned == item.qty || item.checkedComplete ? 'disabled' : false" type="button" class="btn btn-sm btn-success btn-fill pick-button" @click="showSwal(item, this)">
              zu packen  <span class="qty-scanned">{{item.qty - item.qty_scanned}}</span>  Stück
            </button>
            <span>{{item.name}}</span><br/>
          </div>
          <div class="buttons" slot="button">
            <button ref="checkbutton" type="button" class="btn btn-lg btn-simple pull-left"
                    @click="toggleItem(item)">
              <span class="btn-label">
                  <i class="ti-more-alt"></i>
              </span>
            </button>
            <button v-if="item.shipping_note" ref="checkbutton" type="button" class="btn btn-md btn-simple btn-shipping-info btn-fill"
                    @click="openShippingNote(item)">
              <span class="btn-label">
                  <i class="ti-info"></i>
              </span>
            </button>
            <button ref="checkbutton" type="button" class="btn btn-md btn-danger btn-fill pull-right"
                    @click="showSwalComment(item)">
                      <span class="btn-label">
                        <i class="fa fa-comment-dots fa-xl"></i>
                    </span>
            </button>
          </div>
          <div slot="footer">
            <br/>
            <div v-if="item.shownumbers">
              <div class="col-xs-12">
                <table class="table table-sm table-striped">
                  <tbody>
                  <tr>
                    <td>insg. benötigt</td>
                    <th>{{ item.qty }} Stück</th>
                  </tr>
                  <tr v-if="item.manufacturer_sku">
                    <td>Man. SKU</td>
                    <th>
                      <router-link :to="{ name: 'Produkt', params: { productid: item.product_id}}" target="_blank">
                        {{item.manufacturer_sku}}
                      </router-link>
                    </th>
                  </tr>
                  <tr v-if="item.manufacturer_ean">
                    <td>Man. EAN</td>
                    <th>{{item.manufacturer_ean}}</th>
                  </tr>
                  <tr>
                    <td>SKU</td>
                    <th>{{item.sku}}</th>
                  </tr>
                  <tr v-if="item.ean">
                    <td>EAN</td>
                    <th>{{item.ean}}</th>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </shipmentitem-card>
      </div>
    </div>

    <div class="row" v-if="items.length">
      <div class="col-xs-12 text-right">
        <br/><br/>
        <br/>
        <br/>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-sm-12 text-center">
        <button ref="checkbutton" type="button" class="btn btn-md btn-fill pull-left" @click="showSwalAddress(shipment)">
          Adresse
        </button>
        <button ref="checkbutton" type="button" class="btn btn-md btn-danger btn-fill" tabindex="99" @click="showSwalReset(shipmentId)">
          Reset
        </button>
        <button v-if="items.length && checkAllDone()" ref="checkbutton" type="button" class="btn btn-md btn-success btn-fill pull-right" tabindex="2" @click="nextStep()">
          Weiter <span class="btn-label">
                    <i class="ti-angle-right"></i>
                </span>
        </button>
        <br/><br/>
      </div>
    </div>

  </div>
</template>
<script>
  import {EventBus, SoundOk, SoundError} from '@/main.js'
  import config from '@/config.js'
  import ShipmentitemCard from '@/components/Cards/ShipmentItemCard'
  import Swal from 'sweetalert2'
  import {Loading} from 'element-ui'
  import axios from 'axios'
  import auth from '@/auth'

export default {
    components: {
      ShipmentitemCard
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    data () {
      return {
        shippingid: '',
        articleid: '',
        items: [],
        shipment: '',
        loading: false
      }
    },
    methods: {
      submitShippingItemForm () {
        if (this.articleid) {
          this.handleScan(this.articleid)
          this.articleid = ''
        }
      },
      handleScan (searchString) {
        if (searchString === config.endSequence) {
          this.cancel()
          return
        }

        let item = this.findItem(searchString)
        if (item !== undefined) {
          this.checkItem(item)
        }
      },
      showSwal: function (item) {
        Swal.fire({
          title: item.name,
          html: '<img src="' + item.image + '" height="150"><br/><br/><span style="font-size: 1.7em">Willst du sicher die <b style="font-size: 1.3em; color:#EB5E28;">Menge ' + item.qty + '</b> bestätigen?</span><br/><br/>',
          width: 600,
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: 'Ja',
          cancelButtonText: 'Nein',
          buttonsStyling: false,
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.checkCompleteItem(item)
          }
          this.$refs.inputfield.focus()
        })
      },
      showSwalReset: function (shipmentId) {
        Swal.fire({
          icon: 'question',
          title: 'Willst du sicher die Scans der Sendung ' + shipmentId + ' zurücksetzen?',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: 'Ja',
          cancelButtonText: 'Nein',
          buttonsStyling: false,
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.reset()
          }
        })
      },
      showSwalImage: function (item) {
        Swal.fire({
          html: '<img src="' + item.image + '" width="100%">',
          width: 600,
          showConfirmButton: false,
          showCloseButton: true,
          customClass: {
            closeButton: 'btn btn-danger btn-fill'
          },
          buttonsStyling: false
        })
      },
      reset () {
        this.shipment.history = []
        this.resetshipment()
        this.saveshipmentcomment('Packen zurückgesetzt')
        localStorage.removeItem('scanItems')
        localStorage.removeItem('boxItems')
        localStorage.removeItem('shipmentId')
        this.$router.push({ name: 'Packen' }).catch(() => {})
      },
      cancel () {
        localStorage.removeItem('scanItems')
        localStorage.removeItem('shipmentId')
        localStorage.removeItem('shipment')
        localStorage.removeItem('boxItems')
        this.$router.push({ name: 'Packen' }).catch(() => {})
      },
      savearticlescan (sku, ok) {
        let url = config.routes.scanarticle
        let shipment = JSON.parse(localStorage.getItem('shipment'))
        if (shipment) {
          let loadingInstance = Loading.service({})
          axios
            .post(url, {
              'articleScan': {
                'shipmentId': shipment.shipment_id,
                'shipmentIncrementId': shipment.increment_id,
                'packer': localStorage.getItem('username'),
                'sku': sku,
                'scan_ok': ok
              }
            },
              { headers: auth.getAuthHeader() }
            )
            .then(() => {
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      saveshipmentcomment (comment) {
        let url = config.routes.shipmentcomment
        let shipment = JSON.parse(localStorage.getItem('shipment'))
        comment = comment + '<br/>by ' + localStorage.getItem('username')
        if (shipment) {
          let loadingInstance = Loading.service({})
          axios
            .post(url, {
              'shipmentId': shipment.shipment_id,
              'comment': comment
            },
              {headers: auth.getAuthHeader()}
            )
            .then(() => {
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      resetshipment () {
        let shipment = JSON.parse(localStorage.getItem('shipment'))
        if (shipment) {
          let url = config.routes.shipment + '/' + shipment.shipment_id
          let loadingInstance = Loading.service({})
          axios
            .delete(url, {headers: auth.getAuthHeader()})
            .then(() => {
              loadingInstance.close()
              axios
                  .post(config.routes.shipmentStatus, {
                        'shipmentStatus': {
                          'shipmentId': shipment.shipment_id,
                          'shipmentIncrementId': shipment.increment_id,
                          'packed': 0
                        }
                      },
                      { headers: auth.getAuthHeader() }
                  )
                  .then(() => {
                    loadingInstance.close()
                  })
                  .catch(e => {
                    loadingInstance.close()
                    if (e.response.status == 401) {
                      auth.logout()
                    }
                  })
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      endshipment () {
        let shipment = JSON.parse(localStorage.getItem('shipment'))
        let shipmentScanId = JSON.parse(localStorage.getItem('shipmentscan_id_check'))
        if (shipment) {
          let url = config.routes.scanshipment + '/' + shipmentScanId
          let loadingInstance = Loading.service({})
          axios
            .put(url, {
              'shipmentScan': {
                'endtime': new Date().toISOString().replace('T', ' ').substr(0, 10) + ' ' + new Date().toLocaleTimeString()
              }
            }, { headers: auth.getAuthHeader() })
            .then(() => {
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      startpack () {
        let shipment = JSON.parse(localStorage.getItem('shipment'))
        let shipmentScanId = JSON.parse(localStorage.getItem('shipmentscan_id_pack'))
        if (shipment && !shipmentScanId) {
          let url = config.routes.scanshipment
          let loadingInstance = Loading.service({})
          axios
            .post(url, {
              'shipmentScan': {
                'shipmentId': shipment.shipment_id,
                'shipmentIncrementId': shipment.increment_id,
                'packer': localStorage.getItem('username'),
                'timetype': 2,
                'ordervalue': shipment.order_value,
                'storeId': shipment.store_id
              }
            }, { headers: auth.getAuthHeader() })
            .then(response => {
              localStorage.setItem('shipmentscan_id_pack', JSON.stringify(response.data.shipmentscan_id))
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      findItem (searchString) {
        let items = this.items
        let f
        let found
        searchString = searchString.toLowerCase()
        found = items.findIndex(function (item, index) {
          f = index
          return item.sku.toLowerCase() === searchString.toLowerCase() ||
            (item.ean && item.ean.toLowerCase() === searchString) ||
            (item.manufacturer_sku && item.manufacturer_sku.toLowerCase() === searchString) ||
            (item.manufacturer_ean && item.manufacturer_ean.toLowerCase() === searchString)
        })
        if (found !== -1) {
          return items[f]
        } else {
          SoundError.play()
        }
      },
      checkItem (checkItem) {
        let items = this.items
        let f
        items.findIndex(function (item, index) { f = index; return item.id === checkItem.id })
        if (items[f].qty_scanned < items[f].qty) {
          items[f].qty_scanned++
          this.savearticlescan(items[f].sku, 0)
          this.saveshipmentcomment('SKU: ' + items[f].sku +
            '<br/>Scan<br/>QTY: ' + items[f].qty_scanned + '/' + items[f].qty)
          this.items = items
          if (items[f].qty_scanned === items[f].qty) {
            let newItem = items[f]
            this.items.splice(f, 1)
            this.items.push(newItem)
          }
          SoundOk.play()
          localStorage.setItem('scanItems', JSON.stringify(this.items))
          if (this.checkAllDone()) {
            this.nextStep()
          }
        }
      },
      checkCompleteItem (checkItem) {
        let items = this.items
        let f
        items.findIndex(function (item, index) { f = index; return item.id === checkItem.id })
        items[f].qty_scanned = checkItem.qty
        items[f].checkedComplete = true

        this.savearticlescan(items[f].sku, 1)
        this.saveshipmentcomment('SKU: ' + items[f].sku + '<br/>geprüft')

        let newItem = items[f]
        this.items.splice(f, 1)
        this.items.push(newItem)
        localStorage.setItem('scanItems', JSON.stringify(this.items))
        SoundOk.play()
        if (this.checkAllDone()) {
          this.nextStep()
        }
      },
      checkAllDone () {
        for (let item of this.items) {
          if (item.qty_scanned !== item.qty && item.checkedComplete !== true) {
            return false
          }
        }
        return true
      },
      nextStep () {
        this.endshipment()
        // this.saveshipmentcomment('CHECK FINISH')
        this.startpack()
        this.$router.push({name: 'Kartons'})
      },
      toggleItem: function (item) {
        item.shownumbers = item.shownumbers !== true
      },
      resetComment(item){
        // delete pack comment
        let url = config.routes.scancomment + '/' + item.scan_comment[0]
        let loadingInstance = Loading.service({})
        axios
            .delete(url, {
              headers: auth.getAuthHeader()
            })
            .then(() => {
              loadingInstance.close()
              item.scan_comment = []
              // write shipment comment that pack comment has been deleted
              let shipment = JSON.parse(localStorage.getItem('shipment'))
              let shipmentComment = 'Packkommentar zum Artikel mit der SKU ' + item.sku + ' wurde von '
                  + localStorage.getItem('username') + ' gelöscht'
              axios
                  .post(config.routes.shipmentcomment, {
                        'shipmentId': shipment.shipment_id,
                        'comment': shipmentComment
                      },
                      {headers: auth.getAuthHeader()}
                  )
                  .then(() => {
                    loadingInstance.close()
                  })
                  .catch(e => {
                    loadingInstance.close()
                    if (e.response.status == 401) {
                      auth.logout()
                    }
                  })
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      },
      saveScanComment (item) {
        if (item.scan_comment[2] !== undefined) {
          item.scan_comment[3] = 1
          item.scan_comment[1] = localStorage.getItem('username')
          let loadingInstance = Loading.service({})
          // save comment in shipment history
          let shipment = JSON.parse(localStorage.getItem('shipment'))
          let shipmentComment = 'Packen Problem <br/>' + 'Kommentar: ' + item.scan_comment[2]
              + '<br/>Artikel SKU: ' + item.sku + '<br/>'
              + 'by ' + localStorage.getItem('username')
          axios
              .post(config.routes.shipmentcomment, {
                  'shipmentId': shipment.shipment_id,
                  'comment': shipmentComment
                },
                {headers: auth.getAuthHeader()}
              )
              .then(() => {
                loadingInstance.close()
                // save comment in pack comments
                axios
                  .post(config.routes.scancomment, {
                    'scanComment': {
                      'shipmentId': shipment.shipment_id,
                      'shipmentIncrementId': shipment.increment_id,
                      'comment': item.scan_comment[2],
                      'packer': localStorage.getItem('username'),
                      'problem': item.scan_comment[3],
                      'shipmentItemId': item.id
                    }
                  },
                  {headers: auth.getAuthHeader()}
                  )
                  .then(response => {
                    item.scan_comment[0] = response.data.scancomment_id
                    localStorage.setItem('scanItems', JSON.stringify(this.items))
                    location.reload()
                  })
                  .catch(e => {
                    loadingInstance.close()
                    if (e.response.status == 401) {
                      auth.logout()
                    }
                  })
              })
              .catch(e => {
                loadingInstance.close()
                if (e.response.status == 401) {
                  auth.logout()
                }
              })
        }
      },
      showSwalComment: function (item) {
        let inputValue = ''
        let verfasser = ''
        if (item.scan_comment[2] !== undefined){
          inputValue = item.scan_comment[2]
          verfasser = 'von ' + item.scan_comment[1]
        }

        Swal.fire({
          title: 'Problem',
          text: verfasser,
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: 'Speichern',
          cancelButtonText: 'Löschen',
          input: 'textarea',
          inputValue: inputValue,
          inputPlaceholder: 'Kommentar',
          buttonsStyling: false,
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            item.scan_comment[2] = result.value
            this.saveScanComment(item)
          }
          if (result.dismiss === 'cancel'){
            this.resetComment(item)
          }
        })
      },
      printShipment: function (shipmentId) {
        let printPlace = JSON.parse(localStorage.getItem('print_place'))
        if (printPlace) {
          this.callPrintFunction(shipmentId, printPlace)
        } else {
          Swal.fire({
            title: 'Drucker',
            showCancelButton: true,
            cancelButtonText: 'Abbrechen',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
              cancelButton: 'btn btn-danger btn-fill',
            },
            input: 'select',
            inputOptions: config.printplaces,
            inputAttributes: {
              required: true
            },
            reverseButtons: true,
            validationMessage: 'Bitte Drucker auswählen',
            inputPlaceholder: 'Bitte wählen',
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.value) {
              printPlace = result.value
              this.callPrintFunction(shipmentId, printPlace)
            }
          })
        }
      },
      callPrintFunction(shipmentId, printPlace) {
        let url = config.routes.printlabel
        let loadingInstance = Loading.service({})
        axios
            .post(url, {
                  'shipmentIncrementId': shipmentId,
                  'boxDimensions': {},
                  'productId': 0,
                  'qty': 1,
                  'printLocation': printPlace,
                  'attributeCode': ''
                },
                {
                  headers: auth.getAuthHeader()
                })
            .then(response => {
              if (response.data) {
                Swal.fire({
                  title: 'Erfolgreich',
                  html: 'Sendung ' + shipmentId + ' gedruckt',
                  width: 400
                })
                this.saveshipmentcomment('Sendungslabel wurde gedruckt')
              }
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      },
      isCountryRed(country) {
        return country === 'CH' || country === 'GB'
      },
      checkWarenpost(items, shipment) {
        let size = '';
        for (let i = 0; i < items.length; i++) {
          if (items[i].warenpost) {
            if (shipment.shipment_country == 'DE') {
              size = ' (5cm Box)';
            }
            if (items[i].qty < 4 && items[i].qty > 1) {
              Swal.fire({
                title: '<span style="color:orange">WARENPOST' + size + '</span>',
                html: '<h4 style="color:orange">Bitte prüfen, ob dieser Artikel als Warenpost verschickt werden kann!</h4>',
                width: 600,
                allowOutsideClick: false,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-orange btn-fill',
                },
                confirmButtonText: 'Ich prüfe die Warenpost Möglichkeit!',
              })
            } else if (items[i].qty == 1) {
              Swal.fire({
                title: '<span style="color:red">WARENPOST' + size + '</span>',
                html: '<h4 style="color:red">Dieser Artikel MUSS mit Warenpost verschickt werden!</h4>',
                width: 500,
                allowOutsideClick: false,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-red btn-fill',
                },
                confirmButtonText: 'Ich packe den Artikel als Warenpost!',
              })
            }
          }
        }
      },
      openShippingNote(item) {
        Swal.fire({
          title: 'Verpackungshinweis',
          html: item.shipping_note,
          width: 400,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
          },
        })
      },
      showSwalAddress(shipment) {
        Swal.fire({
          title: 'Kunden Adresse',
          html: shipment.shipment_address,
          width: 400,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
          },
        })
      }
    },
    created () {
      this.keyboard = localStorage.getItem('keyboard')
      if (localStorage.getItem('scanItems')) {
        let items = JSON.parse(localStorage.getItem('scanItems'))
        this.items = JSON.parse(localStorage.getItem('scanItems'))
        this.comment = JSON.parse(localStorage.getItem('shipment')).comment
        this.shipment = JSON.parse(localStorage.getItem('shipment'))
        let newitems = []
        this.items.reverse()
        for (let i = 0; i < items.length; i++) {
          items[i].shownumbers = false
          if (items[i].qty_scanned === items[i].qty) {
            newitems.push(items[i])
          } else {
            newitems.unshift(items[i])
          }
        }
        this.items = newitems
        this.checkWarenpost(this.items, this.shipment)
      } else {
        this.$router.push({name: 'Packen'})
      }
      EventBus.$off('scanArticle')
      EventBus.$on('scanArticle', articleId => {
        this.handleScan(articleId)
      })
    },
    mounted () {
    },
    computed: {
      shipmentId () {
        if (localStorage.getItem('shipmentId')) {
          return JSON.parse(localStorage.getItem('shipmentId'))
        } else { return {} }
      }
    },
    destroyed () {
    }
  }
</script>

<style>
.btn-red {
  background-color: red !important;
  border-color: red !important;
}
.btn-orange {
  background-color: orange !important;
  border-color: orange !important;
}
.btn-shipping-info {
  background-color: orange !important;
}

@media (max-width: 400px) {
  .btn-shipping-info {
    margin-left: 10%;
  }
}
@media (min-width: 401px) {
  .btn-shipping-info {
    margin-left: 35%;
  }
}
</style>
