
import * as axios from 'axios'

import config from './config.js'
import auth from './auth.js'

function upload (formData) {
  // headers['Content-Type'] = 'application/json'
  return axios.post(config.routes.addimage, formData, { headers: auth.getAuthHeader() })
  // get data
    .then(response => {
      console.log(response.data)
    })
    .catch(e => {
      console.log(e.message)
    })
}

function getImage (file) {
  return new Promise((resolve) => {
    const fReader = new FileReader()
    const img = document.createElement('img')

    fReader.onload = () => {
      img.src = fReader.result
      resolve(img.src.toString().substr(img.src.toString().indexOf('base64,') + 7))
    }

    fReader.readAsDataURL(file)
  })
}

export { upload, getImage }
