var SidebarLinks = {
  getLinks: function () {
    var links = [
      {
        name: 'Info',
        icon: 'ti-info-alt',
        path: '/dashboard',
        resources: ['Parc_StorageAppStoragePlace::all', 'Magento_Backend::all']
      },
      {
        name: 'Notizen',
        icon: 'ti-calendar',
        path: '/note',
        resources: ['Parc_StorageAppNote::all', 'Magento_Backend::all']
      },      {
        name: 'Picken',
        icon: 'ti-shopping-cart-full',
        path: '/pick',
        resources: ['Parc_StorageAppCommission::all', 'Magento_Backend::all']
      },
      {
        name: 'Packen',
        icon: 'ti-package',
        path: '/pack',
        resources: ['Parc_StorageAppPack::all', 'Magento_Backend::all']
      },
      {
        name: 'Print',
        icon: 'ti-printer',
        path: '/print',
        resources: ['Parc_StorageAppPrint::all', 'Magento_Backend::all']
      },
      {
        name: 'Retouren',
        icon: 'ti-back-left',
        path: '/returns',
        resources: ['Parc_StorageAppRma::all', 'Magento_Backend::all']
      },
      {
        name: 'Donbot',
        icon: 'ti-truck',
        path: '/donbot',
        resources: ['Parc_StorageAppDonbot::all', 'Magento_Backend::all']
      }
    ]

    var userLinks = []
    var userPermissions = this.getAcls()

    if (userPermissions === null) {
      return userLinks
    }

    let i = 0
    let len = links.length
    for (; i < len; i++) {
      if (this.checkAcls(links[i].resources, userPermissions)) {
        userLinks.push(links[i])
      }
    }
    return userLinks
  },
  getAcls: function () {
    return JSON.parse(localStorage.getItem('permissions'))
  },
  checkAcls: function (resources, permissions) {
    var result = permissions.some(r => resources.indexOf(r) >= 0)
    return result
  }
}
module.exports = SidebarLinks
