<template>
  <div class="card">
    <div class="card-content">
      <div class="row">
        <div class="col-xs-12">
          <slot name="header"></slot>
        </div>
        <div class="col-xs-12">
          <slot name="content"></slot>
        </div>
        <div class="col-xs-6">
          <slot name="qtys"></slot>
        </div>
        <div class="col-xs-12">
          <slot name="button"></slot>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  export default {
    name: 'box-card',
    props: ['linkto']
  }

</script>
<style scoped>
  .card-content{
    min-height: 85px;
  }
  .card .numbers.fullheight{
    max-height: none;
    height: auto;
  }
  .card img{
    max-height: 150px;
  }

  .card .numbers{
    font-size: 16px;
    color: #252422;
    text-align: left;
  }

  .card .buttons{
    text-align: right;
  }

  @media (min-width: 992px) {
    .card .numbers {
      overflow: hidden;
    }
  }

  @media (min-width: 768px) {
    .card .numbers {
      overflow: hidden;
    }
  }

</style>
