<template>
  <div>
    <form @submit.prevent="submitShippingForm">
      <div class="input-group">
        <input :inputmode="keyboard" v-model="shipmentid" ref="inputfield" v-focus type="text" class="form-control" placeholder="Sendungsnummer">
        <span class="input-group-addon"><i class="fa fa-search"></i></span>
      </div>
    </form>

    <ValidationObserver v-slot="{ handleSubmit, reset }">
      <form @submit.prevent="handleSubmit(calculate)" @reset.prevent="reset();resetForm();">
        <div class="row">
          <div class="col-lg-12 col-sm-12">
            <div class="card">
              <div class="card-content">
                    <div class="row">
                      <div class="col-lg-12 col-sm-12">
                        <el-collapse :value="['calculator', 'carrier-0']">
                          <el-collapse-item v-if="Object.keys(costs).length == 0"
                              :title="shipment ? 'Sendung ' + shipment.increment_id : 'Paket Preis Kalkulator'"
                              :name="'calculator'"
                          >
                            Land
                            <ValidationProvider name="Land" rules="required" v-slot="{ errors, classes }">
                              <div :class="classes">
                                <el-select
                                    class="select-success"
                                    v-model="country"
                                    size="mini"
                                    filterable
                                    placeholder="Land">
                                  <el-option
                                      class="select-default"
                                      v-for="item in countries"
                                      :key="item.id"
                                      :label="item.label"
                                      :value="item.id">
                                  </el-option>
                                </el-select>
                                <ul><li v-for="(error, index) in errors" :key="index">{{ error }}</li></ul>
                              </div>
                            </ValidationProvider>
                            PLZ
                            <ValidationProvider name="PLZ" rules="required" v-slot="{ errors, classes }">
                              <div :class="classes">
                                <input v-model="zip" class="form-control" placeholder="PLZ">
                                <ul><li v-for="(error, index) in errors" :key="index">{{ error }}</li></ul>
                              </div>
                            </ValidationProvider>
                            Warenwert (nur außerhalb EU)
                            <ValidationProvider name="Warenwert" :rules="{ regex: /^(\d{1,5}|\d{0,5}(\.|,)\d{1,2})$/, required: true}" v-slot="{ errors, classes }">
                              <div :class="classes">
                                <input v-model="order_value" class="form-control" placeholder="Warenwert">
                                <ul><li v-for="(error, index) in errors" :key="index">{{ error }}</li></ul>
                              </div>
                            </ValidationProvider>
                            Länge
                            <ValidationProvider name="Länge" rules="required|integer|positive|max_value:999" v-slot="{ errors, classes }">
                              <div :class="classes">
                                <input type="number" v-model="measurements.side1" class="form-control" placeholder="Seite 1" ref="lengthInput">
                                <ul><li v-for="(error, index) in errors" :key="index">{{ error }}</li></ul>
                              </div>
                            </ValidationProvider>
                            Breite
                            <ValidationProvider name="Breite" rules="required|integer|positive|max_value:999" v-slot="{ errors, classes }">
                              <div :class="classes">
                                <input type="number" v-model="measurements.side2" class="form-control" placeholder="Seite 2">
                                <ul><li v-for="(error, index) in errors" :key="index">{{ error }}</li></ul>
                              </div>
                            </ValidationProvider>
                            Höhe
                            <ValidationProvider name="Höhe" rules="required|integer|positive|max_value:999" v-slot="{ errors, classes }">
                              <div :class="classes">
                                <input type="number" v-model="measurements.side3" class="form-control" placeholder="Seite 3">
                                <ul><li v-for="(error, index) in errors" :key="index">{{ error }}</li></ul>
                              </div>
                            </ValidationProvider>
                            Gewicht
                            <ValidationProvider name="Gewicht" :rules="{ regex: /^(\d{1,5}|\d{0,5}(\.|,)\d{1,2})$/, required: true}" v-slot="{ errors, classes }">
                              <div :class="classes">
                                <input v-model="weight" class="form-control" placeholder="Gewicht">
                                <ul><li v-for="(error, index) in errors" :key="index">{{ error }}</li></ul>
                              </div>
                            </ValidationProvider>

                            <el-select
                                class="select-success"
                                v-model="sendtype"
                                size="mini"
                                placeholder="Versandart">
                              <el-option
                                  class="select-default"
                                  v-for="item in sendtypes"
                                  :key="item"
                                  :label="item"
                                  :value="item">
                              </el-option>
                            </el-select><br/><br/>

                            <button type="reset" class="btn btn-sm btn-danger btn-fill">
                              <span class="btn-label">
                                  <i class="fa ti-trash"></i>
                              </span>
                                            reset
                                          </button>&nbsp;
                                          <button ref="checkbutton" type="submit" class="btn btn-sm btn-success btn-fill">
                              <span class="btn-label">
                                  <i class="fa ti-truck"></i>
                              </span>
                              berechnen
                            </button><br/><br/>
                          </el-collapse-item>
                          <div v-if="Object.keys(costs).length != 0">
                          <button type="button" class="btn btn-sm btn-info btn-fill" @click="resetForm()">
                              <span class="btn-label">
                                  <i class="fa ti-eraser"></i>
                              </span>
                            Neuen Paketpreis berechnen
                          </button>&nbsp;
                          <button type="button" class="btn btn-sm btn-info btn-fill" @click="resetCosts()">
                              <span class="btn-label">
                                  <i class="fa ti-pencil-alt"></i>
                              </span>
                            Eingaben ändern
                          </button><br/><br/></div>
                          <el-collapse-item v-if="Object.keys(costs).length != 0" title="Eingegebene Daten" :name="'data'">
                            <div class="row">
                              <div class="col-xs-6 col-sm-4 summary">
                                <i class="ti-world"></i> Land: {{countryName}}<br/>
                                <i class="ti-location-pin"></i> Postleitzahl: {{zip}}<br/>
                                <i class="fa fa-scale-balanced"></i> Gewicht: {{formatLength(weight, 1)}} kg<br/>
                                <i class="fa fa-euro"></i> Warenwert: {{formatPrice(order_value)}} EUR
                              </div>
                              <div class="col-xs-6 col-sm-4">
                                <i class="fa fa-ruler-horizontal"></i> Seite 1: {{formatLength(sortMeasurements(measurements, 0), 0) }} cm<br/>
                                <i class="fa fa-ruler-horizontal"></i> Seite 2: {{formatLength(sortMeasurements(measurements, 1), 0) }} cm<br/>
                                <i class="fa fa-ruler-horizontal"></i> Seite 3: {{formatLength(sortMeasurements(measurements, 2), 0) }} cm<br/>
                                <i class="fa fa-ruler-combined"></i> Seiten gesamt: {{formatLength(sides_total, 0) }} cm
                              </div>
                              <div class="col-xs-6 col-sm-4">
                                <i class="ti-move"></i> Volumen: {{formatLength(volume, 1) }} l<br/>
                                <i class="ti-package"></i> Volumengewicht: {{ formatLength(volume_weight, 1) }}<br/>
                                <i class="fa fa-retweet"></i> Gurtmaß: {{formatLength(belt_dimension, 0)}} cm
                              </div>

                            </div>
                          </el-collapse-item>
                          <el-collapse-item v-for="(details, carrier, idx) in (costs)" :key="carrier" :name="'carrier-' + idx">
                            <div slot="title">
                              <span v-if="carrier_icons[carrier]">
                                <img
                                   :src="'/img/logistiker/' + carrier_icons[carrier]" width="42">
                                <span v-if="sendtype != 'Normal'"> {{sendtype}}</span>
                              </span>
                              <span v-else>{{ carrier }}</span>
                            </div>
                            <div class="carrier-price"><span class="net-price">{{ formatPrice(details.price) }} EUR</span> (Brutto: {{ formatPrice(details.gross_price) }} EUR)</div>

                            <el-collapse>
                              <el-collapse-item title="Details">
                                <span v-if="details.base_price">Basispreis {{ formatPrice(details.base_price) }} EUR </span><br/>
                                <span v-if="details.go_green_surcharge">+ Go Green {{ formatPrice(details.go_green_surcharge) }} EUR<br/></span>
                                <span v-if="details.toll_surcharge">+ Maut {{ formatPrice(details.toll_surcharge) }} EUR<br/></span>
                                <span v-if="details.climate_surcharge">+ Klima Protect {{ formatPrice(details.climate_surcharge) }} EUR<br/></span>
                                <span v-if="details.diesel_surcharge">+ Diesel {{ formatPrice(details.diesel_surcharge) }} EUR<br/></span>
                                <span v-if="details.private_surcharge">+ Privatzustellung {{ formatPrice(details.private_surcharge) }} EUR<br/></span>
                                <span v-if="details.sperrgut_surcharge">+ Sperrgutzuschlag {{ formatPrice(details.sperrgut_surcharge) }} EUR<br/></span>
                                <span v-if="details.großpaket_surcharge">+ Großpaket {{ formatPrice(details.großpaket_surcharge) }} EUR<br/></span>
                                <span v-if="details.brexit_surcharge">+ Brexitzuschlag {{ formatPrice(details.brexit_surcharge) }} EUR<br/></span>
                                <span v-if="details.energy_surcharge">+ Energie {{ formatPrice(details.energy_surcharge) }} EUR<br/></span>
                                <span v-if="details.safety_surcharge">+ Sicherheit {{ formatPrice(details.safety_surcharge) }} EUR<br/></span>
                                <span v-if="details.zipcode_surcharge">+ PLZ Zuschlag {{ formatPrice(details.zipcode_surcharge) }} EUR<br/></span>
                                <span v-if="details.season_surcharge">+ Saison Zuschlag {{ formatPrice(details.season_surcharge) }} EUR<br/></span>
                                <span v-if="details.duty">+ Zoll Zuschlag {{ formatPrice(details.duty) }} EUR<br/></span>
                              </el-collapse-item>
                            </el-collapse>

                          </el-collapse-item>
                        </el-collapse>
                      </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
  import axios from 'axios'
  import auth from '@/auth.js'
  import config from '@/config.js'
  import {Loading} from 'element-ui'
  import Swal from "sweetalert2";
  import {configure, extend} from "vee-validate";


  export default {
    components: {
    },
    data () {
      return {
        shipmentid: '',
        shipment: null,
        order_value: '',
        measurements: {
          side1: '',
          side2: '',
          side3: '',
        },
        weight: '',
        zip: '',
        country: '',
        sendtype: 'Normal',
        //express: 0,
        //retoure: 0,

        costs: {},
        sendtypes: {
          'normal': 'Normal',
          'retoure': 'Retoure',
          'express': 'Express'
        },
        carrier_icons: {
          'dhl': 'dhl.svg',
          'warenpost': 'warenpost.png',
          'ups': 'ups.svg',
          'dpd': 'dpd.svg',
          'dpd letter': 'dpdletter.png',
          'gls': 'gls.png',

          'dhl_express': 'dhl.svg',
          'ups_express': 'ups.svg',
          'dpd_express': 'dpd.svg',
          'gls_express': 'gls.png',

          'dhl_return': 'dhl.svg',
          'ups_return': 'ups.svg',
          'dpd_return': 'dpd.svg',
          'gls_return': 'gls.png',
        },
        countries: {

        },
        showdetails: false
      }
      },
    methods: {
      submitShippingForm () {
        if (this.shipmentid) {
          this.handleScan(this.shipmentid)
          this.shipmentid = ''
        }
      },
      handleScan (shipmentId) {
        // alert(shipmentId)
        let url = config.routes.shipment
        if (shipmentId) {
          let loadingInstance = Loading.service({})
          axios
            .get(url, {
              params: {incrementId: shipmentId},
              headers: auth.getAuthHeader()
            })
            .then(response => {
              var items = response.data['items']
              if (items.length) {
                this.shipment = response.data
                this.order_value = this.shipment.order_value + ''
                this.country = this.shipment.shipment_country
                this.zip = this.shipment.shipment_zip

                // focus length input
                this.$refs.lengthInput.focus()

                localStorage.setItem('donbot_shipment', JSON.stringify(this.shipment))
              } else {
                this.$showErrorMessage(this.$notify,
                'Es wurden keine Sendung für "' + shipmentId + '" gefunden'
                )
              }
              loadingInstance.close()
              this.backlink = shipmentId
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
              this.$showErrorMessage(this.$notify,
              'Es wurden keine Sendung für "' + shipmentId + '" gefunden'
              )
              console.error(e)
            })
        }
      },
      resetForm(){
        localStorage.removeItem('donbot_shipment')
        this.shipmentid = ''
        this.shipment = null
        this.order_value = ''
        this.measurements.side1 = ''
        this.measurements.side2 = ''
        this.measurements.side3 = ''
        this.weight = ''
        this.zip = ''
        this.country = ''
        this.sendtype = ''
        this.costs = {}
      },
      resetCosts(){
        this.costs = {}
      },
      calculate(){
        let url = config.routes.donbot
        let loadingInstance = Loading.service({})
        axios.get(url, {
          params: {
            "length": this.measurements.side1,
            "width": this.measurements.side2,
            "height": this.measurements.side3,
            "weight": this.weight ? this.weight.replaceAll(',', '.') : 0,
            "amount": this.order_value ? this.order_value.replaceAll(',', '.') : 0,
            "country": this.country,
            "zipcode": this.zip,
            "express": this.sendtype == 'Express' ? 1 : 0,
            "return": this.sendtype == 'Retoure' ? 1 : 0,
          },
          headers: auth.getAuthHeader()
        })
            .then((result) => {
              this.costs = result.data[0]
              loadingInstance.close()
            })
            .catch(error => {
              Swal.showValidationMessage(error)
              loadingInstance.close()
            })
      },
      loadCountries() {
        let url = config.routes.countries
        axios
            .get(url, {
              headers: auth.getAuthHeader()
            })
            .then(response => {
              if (response.data.length) {

                var items = []
                // eslint-disable-next-line no-unused-vars
                for (const [key, value] of Object.entries(response.data)) {
                  items.push({'id': value.id, 'label': value.full_name_locale + ' (' + value.id +')'})
                }
                localStorage.setItem('countries', JSON.stringify(items))
                this.countries = items
              }
            })
            .catch(e => {
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      },
      formatPrice: (value) => {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      sortMeasurements(measurements, idx) {
        let sizes = [measurements.side1, measurements.side2, measurements.side3]
        sizes.sort((a, b) => b - a)
        return sizes[idx]
      },
      formatLength: (value, decimals) => {
        let val = (value / 1).toFixed(decimals).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
    },
    mounted () {
    },
    created () {
      this.keyboard = localStorage.getItem('keyboard')
      if (this.$route.params.q) {
        this.handleScan(this.$route.params.q)
      }

      if (localStorage.getItem('donbot_shipment')) {
        this.shipment = JSON.parse(localStorage.getItem('donbot_shipment'))
        this.order_value = this.shipment.order_value
        this.country = this.shipment.shipment_country
        this.zip = this.shipment.shipment_zip
      }

      if (!localStorage.getItem('countries')) {
        this.loadCountries()
      }

      if (localStorage.getItem('countries')) {
        this.countries = JSON.parse(localStorage.getItem('countries'))
      }

      // Add a rule.
      extend('positive', value => {
        if (value > 0) {
          return true
        }

        return '{_field_} muss eine Zahl größer 0 sein'
      })

      configure({
        classes: {
          valid: 'is-valid',
          invalid: 'has-error'
        }
      })
    },
    computed: {
      sides_total () {
        return this.formatLength(this.measurements.side3 + this.measurements.side2 + this.measurements.side1, 0)
      },
      volume () {
        return (this.measurements.side3 * this.measurements.side2 * this.measurements.side1) / 1000
      },
      volume_weight () {
        return (this.measurements.side3 * this.measurements.side2 * this.measurements.side1) / 5000
      },
      belt_dimension () {
        let belt_size = 0
        let largestSide = Math.max(this.measurements.side3, this.measurements.side2, this.measurements.side1)
        if (largestSide === this.measurements.side1 * 1) {
          belt_size = (this.measurements.side3 * 1 + this.measurements.side2 * 1) * 2 + this.measurements.side1 * 1
        } else if (largestSide === this.measurements.side3 * 1) {
          belt_size = (this.measurements.side1 * 1 + this.measurements.side2 * 1) * 2 + this.measurements.side3 * 1
        } else if (largestSide === this.measurements.side2 * 1) {
          belt_size = (this.measurements.side1 * 1 + this.measurements.side3 * 1) * 2 + this.measurements.side2 * 1
        }
        return belt_size
      },
      countryName () {
        let countryCode = this.country

        let items = this.countries
        let f
        let found
        found = items.findIndex(function (item, index) {
          f = index
          return item.id.toLowerCase() === countryCode.toLowerCase()
        })
        if (found >= 0) {
          return items[f].label
        }

        return ''
      }
    },
    destroyed () {}
  }
</script>

<style>
.parc_collapse_inner, .parc_collapse_inner .el-collapse-item__content, .parc_collapse_inner .el-collapse-item__header {
  background-color: white !important;
}

.carrier-price{
  background-color: green;
  font-size: large;
  padding: 3px;
  margin: 3px;
  color: white;
  font-weight: bold;
}

.net-price{
  font-weight: bold;
  font-size: larger;
}

.summary i{
  width: 20px;
  display: inline-block;
  text-align: center;
}
</style>
