<template>
  <div>
    <div class="row no-gutter">
        <el-select class="select-success" @change="setPrintplace()"
                   placeholder="Standort"
                   size="mini"
                   v-model="print_place">
          <el-option v-for="(option, index) in config.printplaces"
                     class="select-success"
                     :value="index"
                     :label="option"
                     :key="index">
          </el-option>
        </el-select>
        <span class="pull-right">
          <el-select v-if="showUserStats" class="select-success"
                     style="width: 120px"
                     size="mini"
                     placeholder="User"
                     @change="loadShipmentStats"
                     v-model="username">
            <el-option v-for="option in this.users"
                       class="select-success"
                       :value="option"
                       :label="option"
                       :key="option">
            </el-option>
          </el-select>
          <el-select class="select-success"
                     size="mini"
                     style="width: 90px"
                     placeholder="Zeitraum"
                     @change="loadShipmentStats"
                     v-model="days">
            <el-option v-for="(label, index) in this.statsdays"
                       class="select-success"
                       :value="index"
                       :label="label"
                       :key="index">
            </el-option>
          </el-select>
          <button ref="checkbutton" type="button" class="btn btn-xs btn-success btn-fill" @click="loadShipmentStats">
              <span class="btn-label">
                  <i class="ti-reload"></i>
              </span>
          </button>
        </span>
    </div>
    <vue-tabs class="card-content">
      <v-tab title="Dashboard">
        <!--Stats cards-->
        <div class="row">
          <div class="col-lg-4 col-sm-6">
            <stats-card>
              <div class="icon-big text-center warning" :class="`icon-warning`" slot="header">
                <i class="fa fa-box"></i>
              </div>
              <div class="numbers" slot="content">
                <span class="icon-warning">{{statsData.packages}}</span>
                <p class="icon-info" >Team: {{statsData.packages_team}}</p>
              </div>
              <div class="stats" slot="footer">
                <p class="text-left">Pakete/Sendungen</p>
              </div>
            </stats-card>
          </div>
          <div class="col-lg-4 col-sm-6">
            <stats-card>
              <div class="icon-big text-center warning" :class="`icon-success`" slot="header">
                <i class="fa fa-stopwatch"></i>
              </div>
              <div class="numbers" slot="content">
                <span class="icon-warning">{{statsData.packagetime}}</span>
                <p class="icon-info" >Team: {{statsData.packagetime_team}}</p>
              </div>
              <div class="stats" slot="footer">
                <p class="text-left">Ø Packzeit/Sendung</p>
              </div>
            </stats-card>
          </div>
          <div class="col-lg-4 col-sm-6">
            <stats-card>
              <div class="icon-big text-center warning" :class="`icon-success`" slot="header">
                <i class="fa fa-weight"></i>
              </div>
              <div class="numbers" slot="content">
                <span class="icon-warning">{{statsData.volumeweight}}</span>
                <p class="icon-info" >Team: {{statsData.volumeweight_team}}</p>
              </div>
              <div class="stats" slot="footer">
                <p class="text-left">Ø VG/Sendung</p>
              </div>
            </stats-card>
          </div>
          <!--<div class="col-lg-3 col-sm-6">
            <stats-card>
              <div class="icon-big text-center warning" :class="`icon-danger`" slot="header">
                <i class="fa fa-stopwatch"></i>
              </div>
              <div class="numbers" slot="content">
                <p>Packzeit gesamt</p>
                <span class="icon-warning">{{statsData.packagetimetotal}}</span>
                <p class="icon-info" >Team: {{statsData.packagetimetotal_team}}</p>
              </div>
            </stats-card>
          </div>-->
<!--          <div class="col-lg-3 col-sm-6">-->
<!--            <stats-card>-->
<!--              <div class="icon-big text-center warning" :class="`icon-info`" slot="header">-->
<!--                <i class="fa fa-recycle"></i>-->
<!--              </div>-->
<!--              <div class="numbers" slot="content">-->
<!--                <span class="icon-warning">{{statsData.recyclerate}}</span>-->
<!--                <p class="icon-info" >Team: {{statsData.recyclerate_team}}</p>-->
<!--              </div>-->
<!--              <div class="stats" slot="footer">-->
<!--                <p class="text-left">Altkartonage</p>-->
<!--              </div>-->
<!--            </stats-card>-->
<!--          </div>-->
        </div>
        <div class="row visible-lg">
        <div class="col-lg-12">
          <chart-card :chart-data="chartData.packages_period" :chart-options="this.period_options">
            <span slot="title">Sendungen</span>
            <div slot="footer-title">
              <ul class="chart-legend">
                <li class="icon-warning" >Benutzer</li>
                <li class="icon-info" >Team</li>
              </ul>
            </div>
          </chart-card>
        </div>
        <div class="col-lg-12">
          <chart-card :chart-data="chartData.packagetime_period" :chart-options="this.period_options">
            <span slot="title">Minuten/Sendung</span>
            <div slot="footer-title">
              <ul class="chart-legend">
                <li class="icon-warning" >Benutzer</li>
                <li class="icon-info" >Team</li>
              </ul>
            </div>
          </chart-card>
        </div>
<!--        <div class="col-lg-12">-->
<!--          <chart-card :chart-data="chartData.recyclerate_period" :chart-options="this.period_options">-->
<!--            <span slot="title">Altkarton. %</span>-->
<!--            <div slot="footer-title">-->
<!--              <ul class="chart-legend">-->
<!--                <li class="icon-warning" >Benutzer</li>-->
<!--                <li class="icon-info" >Team</li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </chart-card>-->
<!--        </div>-->
      </div>
      </v-tab>
      <v-tab :title="'Vollständig (' + this.statsData.shipments.complete.length + ')'">
        <div class="visible-md visible-lg">
          <paginated-table :table-data="this.statsData.shipments.complete">
            <template slot="columns">
              <el-table-column label="Datum"  property="date" :formatter="dateFormatTimeDE"></el-table-column>
              <el-table-column label="Sendung">
                <template slot-scope="scope">
                  <a href="#" @click="shipmentClick(scope.$index, scope.row)"><span class="btn-label">{{scope.row.shipment_increment_id}}</span></a><br/>
                </template>
              </el-table-column>
              <el-table-column label="Packer"  property="user"></el-table-column>
            </template>
          </paginated-table>
        </div>
        <div class="visible-xs visible-sm">
          <paginated-table :table-data="this.statsData.shipments.complete">
            <template slot="columns">
              <el-table-column label="Info">
                <template slot-scope="scope">
                  <a href="#" @click="shipmentClick(scope.$index, scope.row)"><span class="btn-label">Sendung: {{scope.row.shipment_increment_id}}</span></a><br/>
                  <span class="btn-label">gepackt am: {{dateFormatTimeDE(scope.row, scope.$index, scope.row.date)}}</span><br/>
                  <span class="btn-label">Packer: {{scope.row.user}}</span><br/>
                </template>
              </el-table-column>
            </template>
          </paginated-table>
        </div>
      </v-tab>
      <v-tab :title="'Offen (' + this.openBoxesCount + ')'">
        <div class="card col-lg-12 col-sm-12">
          <div class="card-content">
            <el-collapse>
              <div v-for="(openBox, index) in this.statsData.openBoxes" :key="index">
                <el-collapse-item :title="openBox[0].box" :class="boxPriority(openBox)">
                  <el-collapse>
                    <div v-for="(shipment, index) in openBox" :key="index" class="row">
                      <h5 class="col-lg-6 col-sm-6" style="text-align:left; color:#66615b">
                        {{ shipment.shipmentIncrementId }}
                        <span class="delivery-date"> ({{dateFormatDE('', '', shipment.parcLatestDeliveryDate)}})</span>
                      </h5>
                      <div class="col-lg-6 col-sm-6">
                        <button ref="checkbutton" type="button" class="btn btn-md btn-danger btn-fill pull-right"
                                @click="packShipment(shipment.shipmentIncrementId)">
                          <i class="fa-regular fa-box fa-xl"></i> Packen
                        </button>
                      </div>
                    </div>
                  </el-collapse>
                </el-collapse-item>
              </div>
            </el-collapse>
          </div>
        </div>
      </v-tab>
      <v-tab :title="'Probleme (' + this.statsData.shipments.problems.length + ')'">
        <div class="visible-md visible-lg">
          <paginated-table :table-data="this.statsData.shipments.problems">
            <template slot="columns">
              <el-table-column label="Versand bis" property="parc_latest_delivery_date" :formatter="dateFormatDE"></el-table-column>
              <el-table-column label="Datum"  property="starttime" :formatter="dateFormatTimeDE"></el-table-column>
              <el-table-column label="Sendung">
                <template slot-scope="scope">
                  <a href="#" @click="shipmentClick(scope.$index, scope.row)"><span class="btn-label">{{scope.row.shipment_increment_id}}</span></a><br/>
                </template>
              </el-table-column>
              <el-table-column label="Packer"  property="packer"></el-table-column>
              <el-table-column label="Kommentar"  property="comment"></el-table-column>
            </template>
          </paginated-table>
        </div>
        <div class="visible-xs visible-sm">
          <paginated-table :table-data="this.statsData.shipments.problems">
            <template slot="columns">
              <el-table-column label="Info">
                <template slot-scope="scope">
                  <a href="#" @click="shipmentClick(scope.$index, scope.row)"><span class="btn-label">Sendung: {{scope.row.shipment_increment_id}}</span></a><br/>
                  <span class="btn-label">Datum: {{dateFormatTimeDE(scope.row, scope.$index, scope.row.starttime)}}</span><br/>
                  <span class="btn-label">Packer: {{scope.row.packer}}</span><br/>
                  <span class="btn-label">Kommentar: {{scope.row.comment}}</span><br/>
                  <span class="btn-label">Versand bis: {{dateFormatDE(scope.row, scope.$index, scope.row.parc_latest_delivery_date)}}</span><br/>
                </template>
              </el-table-column>
            </template>
          </paginated-table>
        </div>
      </v-tab>
    </vue-tabs>
  </div>
</template>
<script>
import StatsCard from '@/components/Cards/StatsCard.vue'
import ChartCard from '@/components/Cards/ChartCard.vue'
import PaginatedTable from '@/components/Table/Paginated.vue'
import config from '@/config'
import axios from 'axios'
import auth from '@/auth'
import {Loading} from 'element-ui'
import moment from 'moment'
import Swal from "sweetalert2";

export default {
  components: {
    StatsCard,
    ChartCard,
    PaginatedTable,
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data () {
    return {
      statsData: {
        shipments: {
          complete: [],
          // open: [],
          problems: []
        },
      },
      print_place: '',
      packages_period_options: {},
      packagetime_period_options: {},
      recyclerate_period_options: {},
      username: '',
      loggedInUser: '',
      days: 'today',
      users: [],
      statsdays: {'today': 'Heute', 'yesterday': 'Gestern', 'last7': '7 Tage', 'last30': '30 Tage', 'last90': '90 Tage', 'last365': '365 Tage'},
      article: ''
    }
  },
  created () {
    this.keyboard = localStorage.getItem('keyboard')
    this.openBoxesCount = ''
    this.username = localStorage.getItem('username')
    this.loggedInUser = localStorage.getItem('username')
    if (localStorage.getItem('shipmentstats_days')) {
      this.days = JSON.parse(localStorage.getItem('shipmentstats_days'))
    }
    if (localStorage.getItem('shipmentstats_user')) {
      this.username = JSON.parse(localStorage.getItem('shipmentstats_user'))
    }
    if (!localStorage.getItem('shipmentstatsusers')) {
      this.loadShipmentStatsUsers()
    }
    if (!localStorage.getItem('shipmentstats')) {
      this.loadShipmentStats()
    }
    if (localStorage.getItem('print_place')) {
      this.print_place = JSON.parse(localStorage.getItem('print_place'))
    }
    if (localStorage.getItem('shipmentstats')) {
      this.statsData = JSON.parse(localStorage.getItem('shipmentstats'))
      const shipmentIds = Object.values(this.statsData.openBoxes).flat().map((item) => item.shipmentId);
      const uniqueShipmentIds = Array.from(new Set(shipmentIds));
      const openBoxesCount = uniqueShipmentIds.length;
      this.openBoxesCount = openBoxesCount
    }
    if (localStorage.getItem('shipmentstatsusers')) {
      this.users = JSON.parse(localStorage.getItem('shipmentstatsusers'))
    }
    this.period_options = {
      height: '250px',
      seriesBarDistance: 15,
      axisX: {
        offset: 50,
        showGrid: true,
        showLabel: true,
        labelOffset: {
          x: -10,
          y: 0
        },
        labelInterpolationFnc: function skipLabels (value, index, labels) {
          let labelScale = Math.round((labels.length) / 25)
          if (index === 0 /* || index === labels.length - 1 */) {
            return value.substring(0, 5)
          } else if (labels.length > 32) {
            return index % labelScale === 0 ? value.substring(0, 5) : null
          } else {
            return value.substring(0, 5)
          }
        }
      },
      axisY: {
        offset: 40,
        showGrid: true
      }
    }
  },
  computed: {
    chartData () {
      return Object.assign({}, this.statsData)
    },
    showUserStats () {
      let resources = ['Parc_StorageAppPack::ShipmentUserStats', 'Magento_Backend::all']
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      if (permissions) {
        return permissions.some(r => resources.indexOf(r) >= 0)
      }
      return false
    },
    config() {
      return config
    }
  },
  methods: {
    showSwalImage: function (item) {
      Swal.fire({
        html: '<img src="' + item.image + '" width="100%">',
        width: 600,
        showConfirmButton: false,
        showCloseButton: true,
        customClass: {
          closeButton: 'btn btn-danger btn-fill'
        },
        buttonsStyling: false
      })
    },
    packShipment(incrementId) {
      this.$router.push({name: 'Packen', params: {q: incrementId}})
    },
    loadShipmentStats () {
      let url = config.routes.shipmentstats + '/' + this.username
      let today = new Date()
      let yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      switch (this.days) {
        case 'today': {
          url = url + '/0'
          break
        }
        case 'yesterday': {
          url = url + '/0/' + moment(yesterday).format('YYYY-MM-DD')
          break
        }
        case 'last7': {
          url = url + '/7/'
          break
        }
        case 'last30': {
          url = url + '/30/'
          break
        }
        case 'last90': {
          url = url + '/90/'
          break
        }
        case 'last365': {
          url = url + '/90/'
          break
        }
      }
      let loadingInstance = Loading.service({})
      axios
        .get(url, {
          headers: auth.getAuthHeader()
        })
        .then(response => {
          var stats = response.data
          if (stats.length) {
            this.statsData = stats[0]
            const shipmentIds = Object.values(this.statsData.openBoxes).flat().map((item) => item.shipmentId);
            const uniqueShipmentIds = Array.from(new Set(shipmentIds));
            const openBoxesCount = uniqueShipmentIds.length;
            this.openBoxesCount = openBoxesCount
            localStorage.setItem('shipmentstats', JSON.stringify(stats[0]))
            localStorage.setItem('shipmentstats_days', JSON.stringify(this.days))
            localStorage.setItem('shipmentstats_user', JSON.stringify(this.username))
          }
          loadingInstance.close()
        })
        .catch(e => {
          loadingInstance.close()
          if (e.response.status == 401) {
            auth.logout()
          }
        })
    },
    loadShipmentStatsUsers () {
      let url = config.routes.shipmentstatsusers
      let loadingInstance = Loading.service({})
      axios
        .get(url, {
          headers: auth.getAuthHeader()
        })
        .then(response => {
          var stats = response.data
          if (stats.length) {
            stats.unshift('alle')
            localStorage.setItem('shipmentstatsusers', JSON.stringify(stats))
            this.users = JSON.parse(localStorage.getItem('shipmentstatsusers'))
          }
          loadingInstance.close()
        })
        .catch(e => {
          loadingInstance.close()
          if (e.response.status == 401) {
            auth.logout()
          }
        })
    },
    dateFormatDE: (row, col, value) => {
      if (value) {
        return moment.utc(value).local().format('DD.MM')
      }
      return ''
    },
    dateFormatTimeDE: (row, col, value) => {
      if (value) {
        return moment.utc(value).local().format('DD.MM.YY HH:mm')
      }
      return ''
    },
    tableRowClassName ({row}) {
      let value = row.parc_latest_delivery_date
      let today = moment().format('YYYY-MM-DD')
      if (value < today) {
        return 'warning-row'
      } else {
        return ''
      }
    },
    // sortBy (a, b, field) {
    //   let valueA = a[field].toLowerCase()
    //   let valueB = b[field].toLowerCase()
    //   console.log(valueA)
    //   console.log(valueB)
    //   return valueA.localeCompare(valueB) > 0 ? 1 : -1
    // },
    // sortByNumeric (a, b, field) {
    //   let valueA = parseFloat(a[field]) * 100
    //   let valueB = parseFloat(b[field]) * 100
    //   console.log(valueA)
    //   console.log(valueB)
    //   return valueA - valueB
    // },
    shipmentClick (index, row) {
      let shipmentId = row.shipment_increment_id
      this.$router.push({name: 'Packen', params: {q: shipmentId}})
    },
    setPrintplace: function () {
      localStorage.setItem('print_place', JSON.stringify(this.print_place))
      localStorage.setItem('print_place_name', JSON.stringify(config.printplaces[this.print_place]))
    },
    boxPriority(openBox) {
      let priority = 'normal';
      var currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 1);
      // Remove the time component from currentDate to compare just the dates
      // currentDate.setHours(0, 0, 0, 0);
      for (let i = 0; i < openBox.length; i++) {
        // Convert the string to a Date object
        var deliveryDate = new Date(openBox[i].parcLatestDeliveryDate);
        // Compare the two dates
        if (
            deliveryDate.getFullYear() === currentDate.getFullYear() &&
            deliveryDate.getMonth() === currentDate.getMonth() &&
            deliveryDate.getDate() === currentDate.getDate()
        ) {
          priority = 'orange'
        } else if (deliveryDate < currentDate) {
          priority = 'red'
        }
      }
      return priority
    },
  }
}

</script>
<style>
.el-collapse-item__wrap{
  background-color: #F9F7F3 !important;
}
.el-collapse-item.red .el-collapse-item__header {
  color: red;
}

.el-collapse-item.orange .el-collapse-item__header {
  color: orange;
}
</style>
