import Vue from 'vue'
import VueRouter from 'vue-router'

import DashboardLayout from "../components/Layout/DashboardLayout.vue"
import LoginView from "../views/LoginView.vue"
import NoPermissionView from "../views/NopermissionView.vue"
import NotFoundView from "../views/NotFoundView.vue"
import DashboardView from "../views/DashboardView.vue"

import ProductView from "../views/Product/ProductView.vue"
import ManufacturerProductView from "../views/Product/ManufacturerProductView.vue"

import NoteView from "../views/Note/NoteView.vue"
import NotesView from "../views/Note/NotesView.vue"

import PickStartView from "../views/Pick/StartView.vue"
import PickCommentView from "../views/Pick/CommentView.vue"
import PickCheckView from "../views/Pick/CheckView.vue"
import GroupPickingCheckView from "../views/Pick/GroupPickingCheckView.vue"
import TrolleySortView from "../views/Pick/TrolleySortView.vue"

import PackStartView from "../views/Pack/StartView.vue"
import CheckView from "../views/Pack/CheckView.vue"
import BoxesView from "../views/Pack/BoxesView.vue"
import PackCommentView from "../views/Pack/CommentView.vue"
import PackCommissionBoxesView from "../views/Pack/CommissionBoxesView.vue"

import PrintStartView from "../views/Print/StartView.vue"

import ReturnsStartView from "../views/Returns/StartView.vue"
import ReturnsCheckView from "../views/Returns/CheckView.vue"
import ReturnsCommentView from "../views/Returns/CommentView.vue"

import DonbotStartView from "../views/Donbot/StartView.vue"

import auth from '../auth.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/',
    name: 'home',
    component: DashboardLayout,
    redirect: 'dashboard',
      children: [
        {
          path: 'dashboard/:q?',
          name: 'Dashboard',
          component: DashboardView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppStoragePlace::all', 'Magento_Backend::all']}
        },
        {
          path: 'product/:productid/:q?',
          name: 'Produkt',
          component: ProductView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppStoragePlace::all', 'Magento_Backend::all']}
        },
        {
          path: 'manufacturerproduct/:manufacturer_sku/:q?',
          name: 'Lieferanten Produkt',
          component: ManufacturerProductView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppStoragePlace::all', 'Magento_Backend::all']}
        },
        {
          path: 'note',
          name: 'Notizen',
          component: NotesView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppNote::all', 'Magento_Backend::all']}
        },
        {
          path: 'note/:noteid',
          name: 'Notiz',
          component: NoteView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppNote::all', 'Magento_Backend::all']}
        },
        {
          path: 'pick/:q?',
          name: 'Picken',
          component: PickStartView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppCommission::all', 'Magento_Backend::all']}
        },
        {
          path: 'commissioncheck/:q?',
          name: 'Kommissionieren',
          headline: 'Bitte alle Artikel gründlich prüfen',
          component: PickCheckView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppCommission::all', 'Magento_Backend::all']}
        },
        {
          path: 'commissioncomment',
          name: 'Pickkommentar',
          component: PickCommentView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppCommission::all', 'Magento_Backend::all']}
        },
        {
          path: 'grouppicking',
          name: 'Sammelkommissionierung',
          component: GroupPickingCheckView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppCommission::all', 'Magento_Backend::all']}
        },
        {
          path: 'trolleysort',
          name: 'Sortiervorgang',
          component: TrolleySortView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppCommission::all', 'Magento_Backend::all']}
        },
        {
          path: 'pack/:q?',
          name: 'Packen',
          component: PackStartView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppPack::all', 'Magento_Backend::all']}
        },
        {
          path: 'commissionboxes/:q?',
          name: 'PickBoxes',
          component: PackCommissionBoxesView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppPack::all', 'Magento_Backend::all']}
        },
        {
          path: 'check/:q?',
          name: 'Prüfen',
          headline: 'Bitte alle Artikel gründlich prüfen',
          component: CheckView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppPack::all', 'Magento_Backend::all']}
        },
        {
          path: 'boxes',
          name: 'Kartons',
          component: BoxesView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppPack::all', 'Magento_Backend::all']}
        },
        {
          path: 'packcomment',
          name: 'Packkommentar',
          component: PackCommentView,
          meta: {requiresAuth: true, allowedRoles: ['admin']}
        },
        {
          path: 'print/:q?',
          name: 'Print',
          component: PrintStartView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppPrint::all', 'Magento_Backend::all']}
        },
        {
          path: 'returns/:q?',
          name: 'Retouren',
          component: ReturnsStartView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppRma::all', 'Magento_Backend::all']}
        },
        {
          path: 'rmacheck',
          name: 'Retoure Prüfen',
          component: ReturnsCheckView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppRma::all', 'Magento_Backend::all']}
        },
        {
          path: 'rmacomment',
          name: 'Retourenkommentar',
          component: ReturnsCommentView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppRma::all', 'Magento_Backend::all']}
        },
        {
          path: 'donbot',
          name: 'Donbot',
          component: DonbotStartView,
          meta: {requiresAuth: true, resources: ['Parc_StorageAppDonbot::all', 'Magento_Backend::all']}
        },
        {
          path: 'nopermission',
          name: 'NoPermission',
          component: NoPermissionView,
          meta: {requiresAuth: false}
        }
      ]
  },
  {path: '*', component: NotFoundView}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes,
  // scrollBehavior: function (to, from, savedPosition) {
  //   return savedPosition || { x: 0, y: 0 }
  // }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.resources)) {
    let resources = to.meta.resources
    let permissions = JSON.parse(localStorage.getItem('permissions'))
    if (permissions) {
      var result = permissions.some(r => resources.indexOf(r) >= 0)
      if (!result) {
        next({path: '/nopermission'})
      }
    }
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!auth.checkAuth()) {
      next({ path: '/login' })
    } else {
      next()
    }
  } else {
    if (to.path === '/login' && auth.checkAuth()) {
      next({ path: '/dashboard' })
    } else {
      next() // make sure to always call next()!
    }
  }
})

export default router
