<template>
  <div>
    <form @submit.prevent="submitShippingForm">
      <div class="input-group">
        <input :inputmode="keyboard" v-model="shipmentid" ref="inputfield" v-focus type="text" class="form-control"
               placeholder="Sendungsnummer">
        <span class="input-group-addon" @click="submitShippingForm"><i class="fa fa-search"></i></span>
      </div>
    </form>

    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 text-left el-row--flex">
        <div>
          <el-select class="select-success" @change="loadShipments()"
                     placeholder="Standort"
                     size="mini"
                     v-model="print_place">
            <el-option v-for="(option, index) in config.printplaces"
                       class="select-success"
                       :value="index"
                       :label="option"
                       :key="index">
            </el-option>
          </el-select>
        </div>
        <div class="visible-sm visible-md visible-lg" style="padding-left: 10px;">
          Download
          <p-switch class="" v-model="statusSwitch">
            <i class="fa fa-check" slot="on"></i>
            <i class="fa fa-times" slot="off"></i>
          </p-switch>
        </div>
        <br/>
        <br/>
      </div>
      <div class="visible-sm visible-md visible-lg col-lg-6 col-md-12 col-sm-12 text-right" style="padding-bottom:10px;">
        <button type="button" class="btn btn-sm btn-success btn-fill"
                @click="showSwalUploadFile()">
                    <span class="btn-label">
                        <i class="fa ti-import"></i>
                    </span>
          Import
        </button>
      </div>
    </div>

    <div class="row" v-if="this.shipments.length">
      <div class="col-lg-12 col-sm-12">

        <div class="card">
          <div class="card-content">
            <el-collapse>
              <div v-for="(shipment, index) in shipments" :key="index">
                <el-collapse-item :title="shipment.increment_id">
                  <div class="row">
                    <div class="col-lg-12 col-sm-12">

                      <el-collapse :value="[shipment.increment_id + '-1', shipment.increment_id + '-2']">
                        <el-collapse-item class="parc_collapse_inner" title="Allgemein"
                                          :name="shipment.increment_id + '-1'">
                          <p>Kleines/Internes Sendungslabel drucken
                            <button ref="checkbutton" type="button" class="btn btn-xs btn-fill"
                                    @click="printShipment(shipment.increment_id, shipment.shipment_id, print_place)"
                                    tabindex="2">
                              <span class="btn-label">
                                  <i class="ti-printer"></i>
                              </span>
                            </button>
                          </p>
                          <p>
                            Bestellung <a :href="shipment.order_statuspage_url" target="_blank">
                            {{ shipment.order_increment_id }}</a> vom {{ dateFormatTimeDE(shipment.order_date) }}<br>
                          </p>
                          <p v-for="(item, index) in shipment.invoices" :key="index">
                            <span>Rechnung <a href="#"
                                              @click.prevent="printInvoice(item.increment_id, statusSwitch ? 'download' : 'filesystem', print_place)">{{
                                item.increment_id
                              }}</a> über
                              <span :class="isGBP135(item) ? 'highprice' : ''">
                              {{ formatPrice(item.base_grand_total - item.base_shipping_amount - item.base_shipping_tax_amount) }}
                              {{ item.base_currency_code }}
                              </span>
                            </span>
                            <span class="highprice" v-if="isGBP135(item)">Achtung Warenwert über 135 Pfund</span>
                          </p>
                          <p>
                            <span v-html="shipment.shipment_address">{{ shipment.shipment_address }}</span>
                            <br/>
                            <br/>
                            <a :href="shipment.google_url" target="_blank">
                              In Google Maps anzeigen <img src="/img/icons/google_maps.svg" width="20">
                            </a>
                          </p>
                        </el-collapse-item>
                        <el-collapse-item class="parc_collapse_inner" title="Pakete"
                                          :name="shipment.increment_id + '-2'">
                          <div v-for="(item, index) in shipment.boxes" :key="index">
                            <p
                                v-if="item.is_package">{{
                                index + 1
                              }}) {{ formatLength(sortMeasurements(item, 0), 0) }} x {{
                                formatLength(sortMeasurements(item, 1), 0)
                              }} x {{ formatLength(sortMeasurements(item, 2), 0) }}cm / {{
                                formatLength(item.weight, 1)
                              }}kg / GM: {{
                                formatLength(item.belt_dimension, 0)
                              }}
                              / VG: {{ formatLength(item.volume_weight, 1) }} / Vol: {{ formatLength(item.volume, 1) }}l / Seiten: {{ formatLength(item.sides_total, 0) }}cm
                            </p>
                            <div class="carrier_costs" v-if="item.carrier_costs && item.carrier_costs != undefined">
                              <div v-for="(cost, carrier) in JSON.parse(item.carrier_costs)" :key="carrier">
                                <div class="cost"
                                v-if="carrier.indexOf('_retoure') == -1 && carrier.indexOf('_express') == -1 && carrier.indexOf('ups') == -1">
                                  <button type="button" class="btn btn-md btn-neutral"
                                          @click.stop="processlabel(print_place, item.box_id, statusSwitch ? 'download' : 'filesystem', carrier)">
                                    <img v-if="carrier_icons[carrier]"
                                         :src="'/img/logistiker/' + carrier_icons[carrier]" width="42">
                                    <span v-else>{{ carrier }}</span>
                                  </button>
                                  <br/>
                                  <span>{{ formatPrice(cost.price) }} EUR</span>
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              Kosten konnten nicht berechnet werden
                            </div>
                            <hr v-if="shipment.boxes.length > 1" />
                          </div>
                          <div v-if="shipment.boxes.length > 1">
                            <strong>Gesamtkosten:</strong><br/>
                            <div class="carrier_costs">
                              <div v-for="cost in getTotalCosts(shipment.boxes)" :key="cost[1]">
                                <div class="cost"
                                    v-if="cost[0].indexOf('_retoure') == -1 && cost[0].indexOf('_express') == -1 && cost[0].indexOf('ups') == -1">
                                  <button type="button" class="btn btn-md btn-neutral"
                                          @click.stop="processtotallabel(print_place, shipment.boxes, statusSwitch ? 'download' : 'filesystem', cost[0])">
                                    <img v-if="carrier_icons[cost[0]]"
                                         :src="'/img/logistiker/' + carrier_icons[cost[0]]" width="42">
                                    <span v-else>{{ cost[0] }}</span>
                                  </button>
                                  <br/>
                                  <span>{{ formatPrice(cost[1]) }} EUR</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-collapse-item>
                        <el-collapse-item class="parc_collapse_inner" title="Trackingnummern"
                                          :name="shipment.increment_id + '-3'">
                          <div>
                            <p>
                              <button type="button" class="btn btn-sm btn-info btn-fill"
                                      @click="showSwalAddTrack(shipment)">
                              <span class="btn-label">
                                  <i class="fa ti-package"></i>
                              </span>hinzufügen
                              </button>
                            </p>
                          </div>
                          <div v-if="!shipment.tracks || shipment.tracks.length == 0">keine Sendungsnummern
                            vorhanden<br/><br/></div>
                          <div v-for="(track, index, i) in shipment.tracks" :key="index">
                            <hr  v-if="i !== 0 && i !== 1"/>
                            <div>Hinterlegt am {{ dateFormatTimeDE(track.created_at) }}</div>
                            <p>{{ track.title }}: <a :href="track.url" target="_blank">{{ track.track_number }}</a>
                            </p>
                            <div style="padding-top:10px; padding-bottom: 25px;">
                              <button type="button" class="btn btn-xs btn-danger btn-fill"
                                      @click="showSwalTrack(track, shipment)">
                                  <span class="btn-label">
                                      <i class="fa ti-trash"></i>
                                  </span>löschen
                              </button>
                            </div>
                          </div>
                        </el-collapse-item>
                        <el-collapse-item class="parc_collapse_inner" title="Artikel"
                                          :name="shipment.increment_id + '-4'">
                          <div class="row">
                            <div class="col-lg-6 col-sm-6" v-for="(item, index) in products" :key="index">
                              <shipmentitem-card>
                                <div class="text-center" :class="`icon-info`" slot="header">
                                  <img :src="item.image" @click="showSwalImage(item)" @error="item.image = '/img/placeholder.jpg'" />
                                </div>
                                <div class="numbers" slot="content">
                                  <button type="button" class="btn btn-sm btn-success btn-fill pick-button">
                                    <span class="qty-scanned">{{item.qty}}</span> Stück
                                  </button>
                                  <span>{{item.name}}</span><br/>
                                </div>
                                <div slot="footer">
                                  <br/>
                                    <div class="col-xs-12">
                                      <table class="table table-sm table-striped">
                                        <tbody>
                                        <tr v-if="item.manufacturer_sku">
                                          <td>Man. SKU</td>
                                          <th>
                                            <router-link :to="{ name: 'Produkt', params: { productid: item.product_id}}" target="_blank">
                                              {{item.manufacturer_sku}}
                                            </router-link>
                                          </th>
                                        </tr>
                                        <tr v-if="item.manufacturer_ean">
                                          <td>Man. EAN</td>
                                          <th>{{item.manufacturer_ean}}</th>
                                        </tr>
                                        <tr>
                                          <td>SKU</td>
                                          <th>{{item.sku}}</th>
                                        </tr>
                                        <tr v-if="item.ean">
                                          <td>EAN</td>
                                          <th>{{item.ean}}</th>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                </div>
                              </shipmentitem-card>
                            </div>
                          </div>
                        </el-collapse-item>
                        <el-collapse-item class="parc_collapse_inner" title="Sendungskommentare"
                                          :name="shipment.increment_id + '-5'">
                          <div class="row">
                            <div class="col-lg-12 col-sm-12">
                              <p>
                                <button type="button" class="btn btn-sm btn-info btn-fill"
                                        @click="showSwalAddComment(shipment)">
                              <span class="btn-label">
                                  <i class="fa ti-comment"></i>
                              </span> hinzufügen
                                </button>
                              </p>
                            </div>
                            <div class="col-lg-12 col-sm-12" v-for="(item, index, i) in shipment.comments" :key="index">
                              <hr  v-if="i !== 0 && i !== 1"/>
                              <div>
                                <span>{{ dateFormatTimeDE(item.created_at) }}</span>
                                <p v-html="item.comment"></p>
                              </div>
                            </div>
                          </div>
                          <div>
                          </div>
                        </el-collapse-item>
                      </el-collapse>
                    </div>
                  </div>
                </el-collapse-item>
              </div>
            </el-collapse>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import axios from 'axios'
import auth from '@/auth.js'
import config from '@/config.js'
import {Loading, Collapse, CollapseItem} from 'element-ui'
import Swal from 'sweetalert2'
import {SoundError, SoundOk} from '@/main'
import ShipmentitemCard from '@/components/Cards/ShipmentItemCard'
import PSwitch from '@/components/Switch.vue'

import Vue from 'vue'
import VueTabs from 'vue-nav-tabs'
import {getImage} from "@/file-upload.service";
import moment from "moment/moment";

Vue.use(VueTabs)
Vue.use(Collapse)
Vue.use(CollapseItem)

export default {
  components: {
    ShipmentitemCard,
    PSwitch
  },
  data() {
    return {
      shipmentid: '',
      lastboxid: null,
      lasttype: null,
      lastlogistician: null,
      shipment_provider: '',
      shipments: [],
      print_place: '',
      carrier_icons: {
        'dhl': 'dhl.svg',
        'warenpost': 'warenpost.png',
        'ups': 'ups.svg',
        'dpd': 'dpd.svg',
        'dpd letter': 'dpdletter.png',
        'gls': 'gls.png',
      },
      carriers: {},
      statusSwitch: true,
      resultMessage: '',
      logistician: ''
    }
  },
  methods: {
    submitShippingForm() {
      if (this.shipmentid) {
        this.handleScan(this.shipmentid)
        this.shipmentid = ''
      }
    },
    handleScan(shipmentId) {
      if (!this.print_place) {
        this.$showErrorMessage(this.$notify, 'Bitte erst einen Druckplatz auswählen')
        return
      }
      if (!this.findShipment(shipmentId)) {
        this.resultMessage = ''
        this.logistician = ''
        this.loadShipment(shipmentId)
      } else {
        this.$showErrorMessage(this.$notify, 'Die Sendung "' + shipmentId + '" ist bereits enthalten.')
        SoundError.play()
      }
    },
    showSwalImage: function (item) {
      Swal.fire({
        html: '<img src="' + item.image + '" width="100%">',
        width: 600,
        showConfirmButton: false,
        showCloseButton: true,
        customClass: {
          closeButton: 'btn btn-danger btn-fill'
        },
        buttonsStyling: false
      })
    },
    loadShipment(shipmentId) {
      let url = config.routes.shipment
      if (shipmentId) {
        let loadingInstance = Loading.service({})
        axios
            .get(url, {
              params: {incrementId: shipmentId},
              headers: auth.getAuthHeader()
            })
            .then(response => {
              if (response.data['is_commissioned'] === false) {
                this.$showErrorMessage(this.$notify,
                    'Die Sendung "' + shipmentId + '" wurde noch nicht vollständig kommissioniert.'
                )
                SoundError.play()
              } else if (response.data['is_packed'] === false) {
                this.$showErrorMessage(this.$notify,
                    'Die Sendung "' + shipmentId + '" wurde noch nicht vollständig gepackt.'
                )
                SoundError.play()
              } else {
                if (this.findShipment(response.data.increment_id)) {
                  this.$showErrorMessage(this.$notify, 'Die Sendung "' + shipmentId + '" ist bereits enthalten.')
                } else {
                  this.shipments = [response.data]
                  let products = this.shipments[0].items
                  for (let i = 0; i < products.length; i++) {
                    products[i].shownumbers = false
                  }
                  this.products = products
                  SoundOk.play()
                }
                this.updateLocalStorage()
              }
              loadingInstance.close()
              this.backlink = shipmentId
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
              this.$showErrorMessage(this.$notify,
                  'Es wurden keine Sendung für "' + shipmentId + '" gefunden'
              )
              SoundError.play()
              console.error(e)
            })
      }
    },
    reloadShipment(shipmentId) {
      let url = config.routes.shipment
      if (shipmentId) {
        let loadingInstance = Loading.service({})
        axios
            .get(url, {
              params: {incrementId: shipmentId},
              headers: auth.getAuthHeader()
            })
            .then(response => {
              //this.shipments.push(response.data)
              let index = this.getItemIndex(shipmentId)
              if (index >= 0) {
                this.$set(this.shipments, index, response.data)
                this.updateLocalStorage()
              }
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
              this.$showErrorMessage(this.$notify,
                  'Es wurden keine Sendung für "' + shipmentId + '" gefunden'
              )
              SoundError.play()
              console.error(e)
            })
      }
    },
    findShipment(searchString) {
      let items = this.shipments
      let f
      let found
      found = items.findIndex(function (item, index) {
        f = index
        return item.increment_id.toLowerCase() === searchString.toLowerCase()
      })
      if (found >= 0) {
        return items[f]
      }
    },
    showSwalTrack: function (track, shipment) {
      Swal.fire({
        icon: 'question',
        title: 'Willst du die Sendungnummer entfernen?',

        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-danger btn-fill',
          cancelButton: 'btn btn-success btn-fill'
        },
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        buttonsStyling: false,
        reverseButtons: false
      }).then((result) => {
        if (result.value) {
          let trackId = track.entity_id
          let comment = 'Trackingnummer gelöscht: ' + track.track_number;
          let shipmentId = shipment.shipment_id
          let outerthis = this
          let promiseExecution = async () => {
            await Promise.all([this.saveshipmentcomment(shipmentId, comment), this.deleteTrack(trackId)]).then(function () {
              outerthis.reloadShipment(shipment.increment_id)
            })
          }
          promiseExecution()
        }
      })
    },
    deleteTrack (trackId) {
      let url = config.routes.track + '/' + trackId
      let loadingInstance = Loading.service({})
      axios
          .delete(url, {headers: auth.getAuthHeader()})
          .then(() => {
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
      this.$refs.inputfield.focus()
    },
    getItemIndex (shipmentId) {
      let items = this.shipments
      let f
      let found
      found = items.findIndex(function (item, index) {
        f = index
        return item.increment_id === shipmentId
      })
      if (found >= 0) {
        return f
      }
    },
    updateLocalStorage () {
      localStorage.setItem('print_shipments', JSON.stringify(this.shipments))
    },
    loadShipments () {
      this.resultMessage = ''
      this.logistician = ''
      localStorage.setItem('print_place', JSON.stringify(this.print_place))
      localStorage.setItem('print_place_name', JSON.stringify(config.printplaces[this.print_place]))
      this.shipments = []
      this.updateLocalStorage()
    },
    getTotalCosts (boxes) {

      let totalCosts = []
      let totalValidCosts = []
      let totalBoxes = boxes.length

      for (let idx in boxes) {

        if(!boxes[idx].carrier_costs || boxes[idx].carrier_costs == undefined){
          continue
        }

        // build cost array per carrier
        let costs = JSON.parse(boxes[idx].carrier_costs)
        for (const [key, value] of Object.entries(costs)) {
          if (!totalCosts[key]) {
            totalCosts[key] = []
          }
          totalCosts[key].push(parseFloat(value.price))
        }
      }

      // sum up cots per carrier
      for (let carrier in totalCosts) {
        // only if carrier is available for all boxes
        if (totalCosts[carrier].length >= totalBoxes) {
          totalValidCosts.push([carrier, this.sumArray(totalCosts[carrier])])
        }
      }

      // sort total costs
      totalValidCosts.sort(function(a,b){
        return a[1]-b[1]
      });

      return totalValidCosts
    },
    sumArray (array) {
      let sum = 0;
      array.forEach(item => {
        sum += item;
      });
      return sum;
    },
    showForcePrint () {
      Swal.fire({
        title: 'Label bereits gedruckt. Nochmal drucken?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.processlabel(this.print_place, this.lastboxid, this.lasttype, this.lastlogistician, 1)
        }
      })
    },
    processlabel (printplace, boxid, type, logistician, force = 0) {

      this.lastboxid = boxid
      this.lasttype = type
      this.lastlogistician = logistician

      if (this.isMobile()){
        type = 'filesystem'
      }

      let loadingInstance = Loading.service({})
      let url = config.routes.processlabel + '/' + logistician + '/' + type + '/' + boxid + '/' + printplace + '/' + force
      let header = auth.getAuthHeader()
      if (type === 'download') {
        header['Accept'] = 'application/octet-stream'
      }
      axios
          .get(url, {
            params: {},
            headers: header,
            responseType: type === 'download' ? 'blob' : 'text'
          })
          .then(response => {
            if (type === 'filesystem') {
              this.resultMessage = response.data[0]
              if (this.resultMessage != '') {
                this.showForcePrint()
                loadingInstance.close()
                return;
              }

              let filename = response.data[1]
              Swal.fire({
                title: 'Erfolgreich',
                html: 'gespeichert in ' + filename,
                width: 400
              })
            } else {
              var blobObj = new Blob([response.data])
              blobObj.text().then(text => {
                if (text.indexOf('bereits gedruckt') != -1) {
                  loadingInstance.close()
                  this.resultMessage = 'Das Label wurde bereits gedruckt.'
                  this.showForcePrint()
                  return;
                } else {
                  this.resultMessage = ''
                  const url = window.URL.createObjectURL(blobObj)
                  const link = document.createElement('a')
                  const contentDisposition = response.headers['content-disposition']
                  let fileName = '';
                  if (logistician == 'warenpost') {
                    fileName = 'dhl.csv'
                  } else {
                    fileName = logistician + '.csv'
                  }
                  if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                    var matches = filenameRegex.exec(contentDisposition)
                    if (matches != null && matches[1]) {
                      fileName = matches[1].replace(/['"]/g, '')
                    }
                  }
                  link.href = url
                  link.setAttribute('download', fileName)
                  document.body.appendChild(link)
                  link.click()
                }
              })
            }
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
    },
    processtotallabel (printplace, boxes, type, logistician, force = 0) {

      let boxids = []
      for(let box of boxes){
        boxids.push(box.box_id)
      }
      boxids = boxids.join(',')
      this.lastboxid = boxids
      this.lasttype = type
      this.lastlogistician = logistician

      let loadingInstance = Loading.service({})
      let url = config.routes.processlabel + '/' + logistician + '/' + type + '/' + boxids + '/' + printplace + '/' + force
      let header = auth.getAuthHeader()
      if (type === 'download') {
        header['Accept'] = 'application/octet-stream'
      }
      axios
          .get(url, {
            params: {},
            headers: header,
            responseType: type === 'download' ? 'blob' : 'text'
          })
          .then(response => {
            if (type === 'filesystem') {
              this.resultMessage = response.data[0]
              if (this.resultMessage != '') {
                this.showForcePrint()
                loadingInstance.close()
                return;
              }

              let filename = response.data[1]
              Swal.fire({
                title: 'Erfolgreich',
                html: 'gespeichert in ' + filename,
                width: 400
              })
            } else {
              var blobObj = new Blob([response.data])
              blobObj.text().then(text => {
                if (text.indexOf('bereits gedruckt') != -1) {
                  loadingInstance.close()
                  this.resultMessage = 'Das Label wurde bereits gedruckt.'
                  this.showForcePrint()
                  return;
                } else {
                  this.resultMessage = ''
                  const url = window.URL.createObjectURL(blobObj)
                  const link = document.createElement('a')
                  const contentDisposition = response.headers['content-disposition']
                  let fileName = 'shipmentdata.csv'
                  if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                    var matches = filenameRegex.exec(contentDisposition)
                    if (matches != null && matches[1]) {
                      fileName = matches[1].replace(/['"]/g, '')
                    }
                  }
                  link.href = url
                  link.setAttribute('download', fileName)
                  document.body.appendChild(link)
                  link.click()
                }
              })
            }
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
    },
    printInvoice (invoiceId, type, printplace) {

      if (type == 'download' && this.isMobile()){
        type = 'filesystem'
      }

      let loadingInstance = Loading.service({})
      let url = config.routes.printinvoice + '/' + invoiceId + '/' + type + '/' + printplace
      axios
          .get(url, {
            params: {},
            headers: auth.getAuthHeader(),
            responseType: 'text'
          })
          .then(response => {
              if (type === 'filesystem') {
                let filename = response.data[1]
                Swal.fire({
                  title: 'Erfolgreich',
                  html: 'gespeichert in ' + filename,
                  width: 400
                })
              } else {
                window.location.href = response.data;
              }
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
    },
    saveshipmentcomment (shipmentId, comment) {
      let url = config.routes.shipmentcomment
      comment = comment + '<br/>by ' + localStorage.getItem('username')
      if (shipmentId) {
        let loadingInstance = Loading.service({})
        axios
            .post(url, {
                  'shipmentId': shipmentId,
                  'comment': comment
                },
                {headers: auth.getAuthHeader()}
            )
            .then(() => {
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      }
    },
    formatPrice: (value) => {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatLength: (value, decimals) => {
      let val = (value / 1).toFixed(decimals).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    isGBP135: (item) => {
      return (item.base_currency_code == 'GBP') &&
          (item.base_grand_total - item.base_shipping_amount - item.base_shipping_tax_amount > 135);
    },
    dateFormatTimeDE: (value) => {
      return moment.utc(value).local().format('DD.MM.YY HH:mm')
    },
    sortMeasurements (measurements, idx) {
      let sizes = [measurements.length, measurements.width, measurements.height]
      sizes.sort((a, b) => b - a)
      return sizes[idx]
    },
    async showSwalAddTrack(shipment) {
      let shipment_id = shipment.shipment_id
      let order_id = shipment.order_id
      const steps = ['1', '2']
      const swalQueueStep = Swal.mixin({
        input: 'text',
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'weiter &rarr;',
        cancelButtonText: 'Abbrechen',
        reverseButtons: true,
        progressSteps: steps
      })
      async function showDialog(outerthis) {
        const values = []
        let currentStep
        let result
        for (currentStep = 0; currentStep < steps.length;) {
          if (steps[currentStep] == 1) {
            result = await swalQueueStep.fire({
              title: 'Anbieter',
              input: 'select',
              inputOptions: outerthis.carriers,
              currentProgressStep: currentStep,
              preConfirm: (input) => {
                if (!input) {
                  Swal.showValidationMessage('Bitte auswählen')
                  return false
                }
                return input
              }
            })
          } else if (steps[currentStep] == 2) {
            result = await swalQueueStep.fire({
              title: 'Sendungsnummer',
              input: 'text',
              preConfirm: (input) => {
                if (!input) {
                  Swal.showValidationMessage('Bitte Sendungsnummer eingeben')
                  return false
                }
                return input
              }
            })
          } else {
            break
          }

          if (result.value) {
            values[currentStep] = result.value
            currentStep++
          } else if (result.dismiss === 'cancel') {
            break
          } else {
            break
          }

          if (currentStep === steps.length) {
            let comment = 'Trackingnummer hinzugefügt: ' + values[1];
            let promiseExecution = async () => {
              await Promise.all(
                  [
                      outerthis.saveshipmentcomment(shipment_id, comment),
                      outerthis.saveTracknumber(order_id, shipment_id, values[1], values[0])
                  ]
              ).then(function () {
                outerthis.reloadShipment(shipment.increment_id)
              })
            }
            promiseExecution()

          }
        }
      }
      showDialog(this)
    },
    async showSwalAddComment(shipment) {
      const Queue = Swal.mixin({
        input: 'text',
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'weiter &rarr;',
        cancelButtonText: 'Abbrechen',
        reverseButtons: true,
        progressSteps: ['1']
      })
      Queue.fire(
          {
            title: 'Kommentar',
            input: 'textarea',
            preConfirm: (input) => {
              if (!input) {
                Swal.showValidationMessage('Bitte Kommentar eingeben')
                return false
              }
              return input
            }
          }
      ).then((result) => {
        if (result.value) {
          let url = config.routes.commissionshipmentcomment
          let comment = result.value + '<br/>by ' + localStorage.getItem('username')
          if (shipment) {
            let loadingInstance = Loading.service({})
            axios
                .post(url, {
                      'shipmentId': shipment.shipment_id,
                      'comment': comment
                    },
                    {headers: auth.getAuthHeader()}
                )
                .then(() => {
                  this.reloadShipment(shipment.increment_id)
                  loadingInstance.close()
                })
                .catch(e => {
                  loadingInstance.close()
                  if (e.response.status == 401) {
                    auth.logout()
                  }
                })
          }
        }
      })
    },
    showSwalUploadFile () {
      const steps = ['1', '2']
      const swalQueueStep = Swal.mixin({
        input: 'text',
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'weiter &rarr;',
        cancelButtonText: 'Abbrechen',
        reverseButtons: true,
        progressSteps: steps
      })
      async function showDialog(outerthis) {
        const values = []
        let currentStep
        let result
        for (currentStep = 0; currentStep < steps.length;) {
          if (steps[currentStep] == 1) {
            result = await swalQueueStep.fire({
              title: 'Anbieter',
              input: 'select',
              inputOptions: outerthis.carriers,
              currentProgressStep: currentStep,
              preConfirm: (input) => {
                if (!input) {
                  Swal.showValidationMessage('Bitte auswählen')
                  return false
                }
                return input
              }
            })
          } else if (steps[currentStep] == 2) {
            result = await swalQueueStep.fire({
              title: 'Datei',
              input: 'file',
              currentProgressStep: 1,
              preConfirm: (input) => {
                if (!input) {
                  Swal.showValidationMessage('Bitte Datei auswählen')
                  return false
                }
                return input
              }
            })
          } else {
            break
          }

          if (result.value) {
            values[currentStep] = result.value
            currentStep++
          } else if (result.dismiss === 'cancel') {
            break
          } else {
            break
          }

          if (currentStep === steps.length) {

            let headers = auth.getAuthHeader()
            let url = config.routes.importshippingnumbers
            let loadingInstance = Loading.service({})
            getImage(values[1]).then(image => {
              // handle file changes
              outerthis.file = values[1]
              let formData = {
                carrier: values[0],
                file: {
                  type: outerthis.file.type,
                  name: outerthis.file.name,
                  base64_encoded_data: image
                }
              }
              axios
                  .post(url, formData, {headers: headers})
                  .then(response => {
                    let resultText = 'Es wurden keine Sendungsnummern importiert'
                    if (response.data > 0) {
                      resultText = 'Es wurden ' + response.data + ' Sendungsnummern importiert'
                    }
                    loadingInstance.close()
                    Swal.fire({
                      title: 'Verarbeitung abgeschlossen',
                      text: resultText,
                      icon: 'success',
                      customClass: {
                        confirmButton: 'btn btn-success btn-fill',
                        cancelButton: 'btn btn-danger btn-fill'
                      },
                      buttonsStyling: false,
                      reverseButtons: true
                    })
                  })
                  .catch(e => {
                    loadingInstance.close()
                    if (e.response.status == 401) {
                      auth.logout()
                    }
                  })
            })

          }
        }
      }
      showDialog(this)
    },
    loadCarriers() {
      let url = config.routes.carriers
      axios
          .get(url, {
            headers: auth.getAuthHeader()
          })
          .then(response => {
            var items = response.data[0]
            if (response.data.length) {
              localStorage.setItem('carriers', JSON.stringify(items))
              this.carriers = items
            }
          })
          .catch(e => {
            if (e.response.status == 401) {
              auth.logout()
            }
          })
    },
    saveTracknumber(orderId, shipmentId, trackNumber, carrierCode) {
      let loadingInstance = Loading.service({})
      axios
          .post(config.routes.track, {
                'entity': {
                  'order_id': orderId,
                  'parent_id': shipmentId,
                  'track_number': trackNumber,
                  'title': this.carriers[carrierCode],
                  'carrier_code': carrierCode
                }
              },
              {headers: auth.getAuthHeader()}
          )
          .then(() => {
            loadingInstance.close()
          })
          .catch(e => {
            if (e.response.status == 401) {
              auth.logout()
            }
          })
    },
    isMobile() {
      /*if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        console.log('mobile')
        return true
      } else {
        console.log('desktop')
        return false
      }*/
      if( screen.width <= 768 ) {
        return true;
      }
      else {
        return false;
      }
    },
    printShipment: function (shipmentIncrementId, shipmentId, printPlace) {
      let url = config.routes.printlabel
      let loadingInstance = Loading.service({})
      axios
          .post(url, {
                'shipmentIncrementId': shipmentIncrementId,
                'boxDimensions': {},
                'productId': 0,
                'qty': 1,
                'printLocation': printPlace,
                'attributeCode': ''
              },
              {
                headers: auth.getAuthHeader()
              })
          .then(response => {
            if (response.data) {
              Swal.fire({
                title: 'Erfolgreich',
                html: 'Sendung ' + shipmentIncrementId + ' gedruckt',
                width: 400
              })
              this.saveshipmentcomment(shipmentId, 'Sendungslabel wurde gedruckt')
              this.reloadShipment(shipmentIncrementId)
            }
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
    },
  },
  mounted() {
  },
  created() {
    this.keyboard = localStorage.getItem('keyboard')
    if (localStorage.getItem('print_shipments')) {
      this.shipments = JSON.parse(localStorage.getItem('print_shipments'))
      if(this.shipments.length) {
        let products = this.shipments[0].items
        for (let i = 0; i < products.length; i++) {
          products[i].shownumbers = false
        }
        this.products = products
      }
    }
    if (localStorage.getItem('print_place')) {
      this.print_place = JSON.parse(localStorage.getItem('print_place'))
    }
    if (localStorage.getItem('print_status_switch')) {
      this.statusSwitch = JSON.parse(localStorage.getItem('print_status_switch'))
    }
    if (!localStorage.getItem('carriers')) {
      this.loadCarriers()
    }
    if (localStorage.getItem('carriers')) {
      this.carriers = JSON.parse(localStorage.getItem('carriers'))
    }
  },
  computed: {
    config() {
      return config
    }
  },
  destroyed() {
  },
  watch: {
    statusSwitch: function (val) {
      localStorage.setItem('print_status_switch', JSON.stringify(val))
    }
  }
}
</script>

<style>
.select-success.el-select .el-input .el-input__icon {
  height: auto !important;
}

.select-success.el-select .el-input {
  max-width: 180px;
}

.parc_collapse_inner, .parc_collapse_inner .el-collapse-item__content, .parc_collapse_inner .el-collapse-item__header {
  background-color: white !important;
}

.parc_collapse_inner .el-collapse-item__content {
  padding-bottom: 0px !important;
}

.carrier_costs {
  display: flex;
  padding-bottom: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.cost{
  text-align: center;
}

.carrier_costs > div:first-child {
  font-weight: bold;
  color: green;
}

.highprice {
  font-weight: bold;
  color: #EB5E28;
}
</style>
