<template>
  <div>
    <form @submit.prevent="submitRmaSearchForm">
      <div class="input-group">
        <input :inputmode="keyboard" v-model="search" ref="inputfield" v-focus v-on:focus="empty" type="text" class="form-control" placeholder="Sendungs-/Bestellnummer oder PLZ">
        <span class="input-group-addon" @click="submitRmaSearchForm"><i class="fa fa-search"></i></span>
      </div>
    </form>
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 text-left">
        <el-select class="select-success" @change="setPrintplace()"
                   placeholder="Standort"
                   size="mini"
                   v-model="print_place">
          <el-option v-for="(option, index) in config.printplaces"
                     class="select-success"
                     :value="index"
                     :label="option"
                     :key="index">
          </el-option>
        </el-select>
      </div>

      <div class="col-md-12 col-sm-12">
        <vue-tabs class="card-content">
          <v-tab :title="'Bestellungen (' + (this.orders.length ? this.orders.length : 0) + ')'">
            <div class="">
              <div class="row">
                <div class="col-sm-12">
                  <div class="visible-md visible-lg">
                    <el-table :data="this.queriedData"
                              @row-click="gotolink"
                              @sort-change="sortChange"
                              border
                              :default-sort="{prop: 'created_at', order: 'descending'}"
                              style="width: 100%">

                      <el-table-column width="120" align="left" prop="created_at" :formatter="dateFormatDE" label="Bestelldatum" />
                      <el-table-column width="200" align="left" prop="increment_id" label="Bestell#" />
                      <el-table-column align="left" prop="shipping_address" label="Adresse">
                        <template slot-scope="scope">
                          <span class="btn-label"><span v-html="scope.row.shipping_address"></span></span>
                        </template>
                      </el-table-column>
                      <el-table-column width="120" align="center" prop="rma_status_label" label="RMA Status" />
                    </el-table>
                  </div>
                </div>
                <div class="visible-xs visible-sm ">
                  <el-table :data="this.orders"
                            @row-click="gotolink"
                            @sort-change="sortChange"
                            border
                            :default-sort="{prop: 'created_at', order: 'descending'}"
                            style="width: 100%">
                    <el-table-column label="Info">
                      <template slot-scope="scope">
                        <span class="btn-label">Bestellung {{scope.row.order_id}} vom {{dateFormatDE(scope.row, 0, scope.row.created_at)}}</span><br/>
                        <span class="btn-label"><span v-html="scope.row.shipping_address"></span></span><br/>
                        <span class="btn-label">{{scope.row.rma_status_label}}</span><br/>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <div class="col-sm-6 pagination-info text-left">
                  <p class="category">Zeige {{pagination.total ? from + 1 : 0}} bis {{to}} von {{pagination.total}} Einträgen</p>
                </div>
                <div class="col-sm-6">
                  <p-pagination class="pull-right"
                                v-model="pagination.currentPage"
                                @changePage="savePage"
                                :per-page="pagination.perPage"
                                :total="pagination.total">
                  </p-pagination>
                </div>
              </div>
            </div>
          </v-tab>
        </vue-tabs>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import auth from '@/auth.js'
  import config from '@/config.js'
  import {Loading} from 'element-ui'
  import PPagination from "@/components/Pagination.vue";
  import moment from "moment/moment";
  import Swal from "sweetalert2";

  export default {
    components: {
      PPagination
    },
    data () {
      return {
        search: '',
        print_place: '',
        orders: [],
        propsToSearch: ['increment_id', 'shipping_address'],
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
      }
    },
    methods: {
      submitRmaSearchForm () {
        if (!this.print_place) {
          this.$showErrorMessage(this.$notify, 'Bitte erst einen Druckplatz auswählen')
          return
        }
        if (this.search) {
          this.handleScan(this.search)
        }
      },
      handleScan (search) {
        const regex = /[^a-zA-Z0-9]+/gm;
        search = search.replace(regex, ``);

        let url = config.routes.rmasearchorder + '/' + search
        if (search) {
          let loadingInstance = Loading.service({})
          axios
            .get(url, {
              headers: auth.getAuthHeader()
            })
            .then(response => {
              if (response.data.length === 0) {
                this.$showErrorMessage(this.$notify,
                'Es wurden keine Bestellung oder Sendung für "' + search + '" gefunden'
                )
                this.$router.push({name: 'Retouren'})
              } else if (response.data[0].orders && response.data[0].orders.length > 0) {
                this.orders = response.data[0].orders
              } else if (response.data[0].orders && response.data[0].orders.length == 0) {
                this.orders = []
              } else if (response.data[0].order) {
                this.order = response.data[0].order
                this.shipments = response.data[0].shipments
                if (response.data[0].rma.length == 0) {
                  this.showCreateRma(this.order)
                } else {
                  this.rma = response.data[0].rma
                  this.rmaItems = response.data[0].rmaItems
                  for (let id in this.rmaItems) {
                    this.rmaItems[id].ri_qty = 0
                  }
                  localStorage.setItem('rmaOrderId', JSON.stringify(this.order.entity_id))
                  localStorage.setItem('rmaOrder', JSON.stringify(this.order))
                  localStorage.setItem('rma', JSON.stringify(this.rma))
                  localStorage.setItem('rmaItems', JSON.stringify(this.rmaItems))
                  this.$router.push({name: 'Retoure Prüfen'})
                }
              }
              loadingInstance.close()
              this.backlink = search
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
              this.$showErrorMessage(this.$notify,
              'Es wurden keine Sendung für "' + search + '" gefunden'
              )
              console.error(e)
            })
        }
      },
      setPrintplace: function () {
        localStorage.setItem('print_place', JSON.stringify(this.print_place))
        localStorage.setItem('print_place_name', JSON.stringify(config.printplaces[this.print_place]))
      },
      empty() {
        this.search = ''
      },
      gotolink (row) {
        if (!this.print_place) {
          this.$showErrorMessage(this.$notify, 'Bitte erst einen Druckplatz auswählen')
          return
        }
        if (row.rma_status_code == 'accepted'){
          this.$router.push({name: 'Retouren', params: {q: row.increment_id}})
        } else {
          this.showCreateRma(row)
        }
      },
      sortChange (event) {
        this.loadStats({sort: event.prop, direction: event.order})
      },
      dateFormatDE: (row, col, value) => {
        if (value) {
          return moment.utc(value).local().format('DD.MM.YY')
        }
        return ''
      },
      savePage (item) {
        localStorage.setItem('rmaPage', JSON.stringify(item))
      },
      showCreateRma: function (order){
        let orderId = order.increment_id
        let entityId = order.entity_id
        Swal.fire({
          title: 'Es wurden keine Retoure für "' + orderId + '" freigegeben. Möchtest du eine neue Retoure anmelden?',
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: 'Ja',
          cancelButtonText: 'Nein',
          buttonsStyling: false,
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.createRma(entityId)
          }
        })
      },
      createRma: function (orderId) {
        let url = config.routes.rmacreate
        let loadingInstance = Loading.service({})
        axios
          .post(url, {
                'rma': {
                  'rma_order_id': orderId,
                }
              },
              {headers: auth.getAuthHeader()})
          .then(response => {
            if (response.data.length > 0) {
              this.handleScan(response.data[0].order_increment_id)
              loadingInstance.close()
            } else {
              loadingInstance.close()
              Swal.fire({
                title: 'Fehler',
                text: 'Die Retoure wurde nicht angelegt, da es bereits eine Retoure im Status "Entwurf" gibt.',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success btn-fill',
                  cancelButton: 'btn btn-danger btn-fill'
                },
                buttonsStyling: false,
                reverseButtons: true
              })
            }
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
      },
    },
    mounted () {
    },
    created () {
      this.keyboard = localStorage.getItem('keyboard')
      if (localStorage.getItem('rmaOrderId')) {
        localStorage.removeItem('rmaOrderId')
      }
      if (localStorage.getItem('rmaOrder')) {
        localStorage.removeItem('rmaOrder')
      }
      if (localStorage.getItem('rma')) {
        localStorage.removeItem('rma')
      }
      if (localStorage.getItem('rmaItems')) {
        localStorage.removeItem('rmaItems')
      }
      if (localStorage.getItem('print_place')) {
        this.print_place = JSON.parse(localStorage.getItem('print_place'))
      }
      if (this.$route.params.q) {
        this.handleScan(this.$route.params.q)
      }
    },
    computed: {
      config() {
        return config
      },
      queriedData() {
        let result = this.orders
            .filter((row) => {
              let isIncluded = false
              for (let key of this.propsToSearch) {
                if (row[key]) {
                  isIncluded = true
                }
              }
              return isIncluded
            })
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.pagination.total < highBound) {
          highBound = this.pagination.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    destroyed () {}
  }
</script>
