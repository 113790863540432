<template>
  <div>
    <div class="row visible-md visible-lg" v-if="hidePageSelect !== 'hide'">
      <div class="col-sm-12 text-right">
        Anzahl
        <el-select
          class="select-default"
          v-model="pagination.perPage"
          @change="getTableData()"
          placeholder="Per page">
          <el-option
            class="select-default"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select><br/><br/>
      </div>
    </div>
    <el-table :data="tableData" v-bind="$attrs" v-on="listeners" style="width: 100%">
      <slot name="columns">
        <el-table-column
          :sortable="column.sortable ? 'custom' : false"
          v-for="column in columns"
          :key="column.prop"
          v-bind="column"
        >
          <template slot-scope="{row}">
            <slot :name="column.prop || column.type || column.label" :row="row">
              {{row[column.prop]}}
            </slot>
          </template>
        </el-table-column>
      </slot>
    </el-table>
    <slot name="pagination" :page="pagination.currentPage" :total="total">
      <div class="row">
        <div class="col-sm-6 pagination-info text-left"><br/>
          <p class="category">Zeige {{ fromEntry + 1 }} bis {{ toEntry }} von {{ total }} Einträgen</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination.currentPage" @changePage="getTableData"
                        :per-page="pagination.perPage"
                        :total="total">
          </p-pagination>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>

import PPagination from '../Pagination'
import {Table, TableColumn, Select, Option, Loading} from 'element-ui'
import Vue from 'vue'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

export default {
  name: 'DataTable',
  inheritAttrs: false,
  components: {
    PPagination
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    getData: {
      type: Function,
      default: () => Promise.resolve([])
    }
  },
  data () {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50]
      },
      tableData: [],
      total: 10,
      sortParams: [],
      hidePageSelect: ''
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        'sort-change': this.onSortChange
      }
    },
    fromEntry () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    toEntry () {
      let highBound = this.fromEntry + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    }
  },
  methods: {
    async getTableData (page) {
      let loadingInstance = Loading.service({})
      let reqPage = page || this.pagination.currentPage
      try {
        let response = await this.getData({
          page: reqPage,
          perPage: this.pagination.perPage,
          sortParams: this.sortParams
        })
        this.tableData = response.data
        this.total = response.total
      } finally {
        loadingInstance.close()
      }
    },
    onSortChange ({ prop, order }) {
      if (prop !== null) {
        let shortOrder = order === 'ascending' ? 'asc' : 'desc'
        this.sortParams = [`${prop}|${shortOrder}`]
      } else {
        this.sortParams = []
      }
      this.getTableData()
    },
  },
  created () {
    this.getTableData()
  }
}
</script>
