<template>
  <div>
    <form @submit.prevent="submitShippingItemForm" v-if="!checkAllDone()">
      <div class="input-group">
        <input :inputmode="keyboard" v-model="articleid" ref="inputfield" type="text" v-focus class="form-control" tabindex="1" placeholder="Artikel">
        <span class="input-group-addon" @click="submitShippingItemForm"><i class="fa fa-search"></i></span>
      </div>
    </form>
    <div class="row" v-if="items.length">
      <div class="col-xs-6">
        <h4>{{order.increment_id}}</h4>
      </div>
      <div class="col-xs-6 text-right">
        <h4><span v-show="totalQty > 0">GS: {{totalQty}}</span> </h4>
      </div>
    </div>
    <div class="row" v-if="items.length">
      <div class="col-lg-6 col-sm-6" v-for="(item, index) in items" :key="index">
        <shipmentitem-card :class="item.ri_qty >= item.refundable_qty ? 'finished' : 'false'" :linkto="{ name: 'Produkt', params: { productid: item.ri_product_id}}" >
          <div class="text-center" slot="title">
            <span class="pull-left icon-problem" v-if="item.ri_comments">
              <i class="icon-danger ti-alert"></i>
            </span>
            <button :disabled="item.ri_qty == 0 || ! item.ri_qty ? 'disabled' : false" type="button" class="btn btn-sm btn-success btn-fill" @click="remQty(item)">
                <span class="btn-label">
                    <i class="fa fa-minus"></i>
                </span>
            </button>&nbsp;&nbsp;
            <button :disabled="item.possible_qty <= 0 ? 'disabled' : false" type="button" class="btn btn-sm btn-success btn-fill" @click="addQty(item)">
                <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
            </button>
          </div>
          <div class="text-center" :class="`icon-info`" slot="header">
            <img :src="item.image" @click="showSwalImage(item)" @error="item.image = '/img/placeholder.jpg'" />
          </div>
          <div class="numbers" slot="content">
            <button type="button" class="btn btn-sm btn-success btn-fill pick-button">
              <span class="qty-scanned">{{item.ri_qty}}</span> Stück
            </button>
            <span>{{item.ri_name}}</span><br/>
          </div>
          <div class="buttons" slot="button">
            <div class="col-xs-12">
              <button ref="checkbutton" type="button" class="btn btn-lg btn-simple pull-left"
                      @click="toggleItem(item)">
                <span class="btn-label">
                    <i class="ti-more-alt"></i>
                </span>
              </button>
              <button ref="checkbutton" type="button" class="btn btn-md btn-fill btn-middle"
                      @click="showSwalPrint(item)">
                <span class="btn-label">
                    <i class="ti-printer"></i>
                </span>
              </button>
              <button ref="checkbutton" type="button" class="btn btn-md btn-danger btn-fill pull-right"
                      @click="showSwalComment(item)">
                <span class="btn-label">
                  <i class="fa fa-comment-dots fa-xl"></i>
                </span>
              </button>
            </div>
          </div>
          <div slot="footer">
            <br/>
            <div v-if="item.shownumbers">
              <div class="col-xs-12">
                <table class="table table-sm table-striped">
                  <tbody>
                  <tr v-if="item.man_sku">
                    <td>Man. SKU</td>
                    <th>
                      <router-link :to="{ name: 'Produkt', params: { productid: item.ri_product_id}}" target="_blank">
                        {{item.man_sku}}
                      </router-link>
                    </th>
                  </tr>
                  <tr v-if="item.man_ean">
                    <td>Man. EAN</td>
                    <th>{{item.man_ean}}</th>
                  </tr>
                  <tr>
                    <td>SKU</td>
                    <th>{{item.ri_sku}}</th>
                  </tr>
                  <tr v-if="item.ean">
                    <td>EAN</td>
                    <th>{{item.ean}}</th>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </shipmentitem-card>
      </div>
    </div>



    <div class="row">
      <div class="col-xs-12 text-right" v-if="items.length">
        <button :disabled="!totalQty ? 'disabled' : false" ref="checkbutton" type="button" class="btn btn-md btn-success btn-fill" tabindex="2" @click="finish()">
          Abschließen <span class="btn-label">
                    <i class="ti-check"></i>
                </span>
        </button><br/><br/>
        <br/>
        <br/>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-sm-12 text-center">
        <button v-if="items.length" ref="checkbutton" type="button" class="btn btn-md btn-danger btn-fill" tabindex="99" @click="showSwalReset()">
          Reset
        </button>
        <span v-if="items.length == 0">Alle Positionen wurden bereits gutgeschrieben.</span>
        <br/><br/>
      </div>
    </div>
  </div>
</template>
<script>
  import config from '@/config.js'
  import ShipmentitemCard from '@/components/Cards/ShipmentItemCardV2'
  import Swal from 'sweetalert2'
  import {Loading} from 'element-ui'
  import axios from 'axios'
  import auth from '@/auth'
  import {SoundError} from "@/main";

export default {
    components: {
      ShipmentitemCard
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    data () {
      return {
        rma: '',
        articleid: '',
        items: [],
        order: '',
        loading: false
      }
    },
    methods: {
      submitShippingItemForm () {
        if (this.articleid) {
          this.handleScan(this.articleid)
          this.articleid = ''
        }
      },
      handleScan (searchString) {
        if (searchString === config.endSequence) {
          this.cancel()
          return
        }

        let item = this.findItem(searchString)
        if (item !== undefined) {
          this.addQty(item)
        }
      },
      findItem (searchString) {
        let items = this.items
        let f
        let found
        searchString = searchString.toLowerCase()
        found = items.findIndex(function (item, index) {
          f = index
          return item.ri_sku.toLowerCase() === searchString.toLowerCase() ||
              (item.ean && item.ean.toLowerCase() === searchString) ||
              (item.man_sku && item.man_sku.toLowerCase() === searchString) ||
              (item.man_ean && item.man_ean.toLowerCase() === searchString)
        })
        if (found !== -1) {
          return items[f]
        } else {
          SoundError.play()
          this.$showErrorMessage(this.$notify,
              'Falscher Artikel'
          )
        }
      },
      showSwalReset: function () {
        Swal.fire({
          icon: 'question',
          title: 'Willst du sicher alle Mengen der Retoure zurücksetzen?',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: 'Ja',
          cancelButtonText: 'Nein',
          buttonsStyling: false,
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.reset()
          }
        })
      },
      showSwalPrint: function (item) {

        if (!this.print_place) {
          this.$showErrorMessage(this.$notify, 'Bitte erst einen Druckplatz auswählen')
          return
        }

        Swal.fire({
          icon: 'question',
          title: 'Anzahl?',
          input: 'number',
          inputValue: 1,
          inputAttributes: {
            min: "1",
            oninput: "validity.valid||(value='');"
          },
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: 'Drucken',
          cancelButtonText: 'Abbrechen',
          buttonsStyling: false,
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.printLabel(item, result.value)
          }
        })
      },
      sortRmaItems (checkItem) {
        let items = this.items
        let f
        items.findIndex(function (item, index) { f = index; return item.ri_id === checkItem.ri_id })

        let newItem = items[f]
        // item is complete
        if (checkItem.possible_qty == 0) {
          // move to end
          console.log("move to end " + f)
          this.items.splice(f, 1)
          this.items.push(newItem)
        } else {
          // move to beginning
          console.log("move to beginning " + f)
          this.items.splice(f, 1)
          this.items.unshift(newItem)
        }
        localStorage.setItem('rmaItems', JSON.stringify(this.items))

      },
      reset () {
        this.resetrma()
        for (let item of this.items) {
          item.ri_qty = 0
        }
      },
      resetrma () {
        let rma = JSON.parse(localStorage.getItem('rma'))
        if (rma) {
          let url = config.routes.rmareset + '/' + rma.rma_id
          let loadingInstance = Loading.service({})
          axios
            .get(url, {headers: auth.getAuthHeader()})
            .then(() => {
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      cancel () {
        if (localStorage.getItem('rmaOrderId')) {
          localStorage.removeItem('rmaOrderId')
        }
        if (localStorage.getItem('rmaOrder')) {
          localStorage.removeItem('rmaOrder')
        }
        if (localStorage.getItem('rma')) {
          localStorage.removeItem('rma')
        }
        if (localStorage.getItem('rmaItems')) {
          localStorage.removeItem('rmaItems')
        }
        this.$router.push({ name: 'Retouren' }).catch(() => {})
      },
      resetComment(item){
        item.ri_comments = ''
        this.saveItem(item)
      },
      saveItem (item) {
        let url = config.routes.rmasaveitems
        let loadingInstance = Loading.service({})
        let newItem = Object()
        newItem[item.ri_id] = {
          'qty': item.ri_qty,
          'comment': item.ri_comments
        }
        axios
          .post(url, {
            'rma': {
              'rma_id': this.rma.rma_id,
              'items': newItem
            }
          },
            {headers: auth.getAuthHeader()})
          .then(() => {
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
      },
      saveAllItems (items) {
        let url = config.routes.rmasaveitems
        let loadingInstance = Loading.service({})
        let newItems = Object()
        for (let item of items) {
          newItems[item.ri_id] = {
            'qty': item.ri_qty,
            'comment': item.ri_comments
          }
        }
        return axios
          .post(url, {
            'rma': {
              'rma_id': this.rma.rma_id,
              'items': newItems
            }
          },
            {headers: auth.getAuthHeader()})
          .then(() => {
            loadingInstance.close()
            console.log('save rma items finished')
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
      },
      saveRma (rma) {
        let url = config.routes.rma + '/' + rma.rma_id
        let loadingInstance = Loading.service({})
        return axios
            .put(url, {
                  'rma': {
                    'rma_status': 'product_received'
                  }
                },
                {headers: auth.getAuthHeader()})
            .then(() => {
              console.log('save rma finished')
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      },
      checkAllDone () {
        for (let item of this.items) {
          if (item.ri_qty < item.refundable_qty) {
            return false
          }
        }
        return true
      },
      addQty (item) {
        if (item.possible_qty > 0){
          item.possible_qty = item.possible_qty - 1
          item.ri_qty = Number.parseInt(item.ri_qty) + 1
          this.sortRmaItems(item)
        } else {
            SoundError.play()
        }
        this.$refs.inputfield.focus()
      },
      remQty (item) {
        item.possible_qty = item.possible_qty + 1
        item.ri_qty = Number.parseInt(item.ri_qty) - 1
        this.$refs.inputfield.focus()
        this.sortRmaItems(item)
        //this.saveItem(item)
      },
      printLabel (item, qty) {
        let url = config.routes.printlabel
        let loadingInstance = Loading.service({})
        return axios
            .post(url, {
                  'shipmentIncrementId': '',
                  'boxDimensions': {},
                  'productId': item.ri_product_id,
                  'qty': qty,
                  'printLocation': this.print_place,
                  'attributeCode': 'sku'
                },
                {
                  headers: auth.getAuthHeader()
                })
            .then(response => {
              if (response.data) {
                Swal.fire({
                  title: 'Erfolgreich',
                  html: qty + ' Barcode(s) werden auf ' + this.print_place + ' gedruckt.',
                  width: 400
                })
              }
              loadingInstance.close()
              this.$refs.inputfield.focus()
            })
            .catch(e => {
              loadingInstance.close()
              this.$refs.inputfield.focus()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      },
      finish () {

        let promiseExecution = async () => {
          await Promise.all([this.saveAllItems(this.items), this.saveRma(this.rma)]).then(function () {
            console.log('all finished')
          })
          this.$router.push({name: 'Retouren'})
        }
        promiseExecution()
      },
      showSwalImage: function (item) {
        Swal.fire({
          html: '<img src="' + item.image + '" width="100%">',
          width: 600,
          showConfirmButton: false,
          showCloseButton: true,
          customClass: {
            closeButton: 'btn btn-danger btn-fill'
          },
          buttonsStyling: false
        })
      },
      toggleItem: function (item) {
        item.shownumbers = !item.shownumbers
        if (!item.shownumbers) {
          this.$refs.inputfield.focus()
        }
      },
      showSwalComment: function (item) {
        let inputValue = ''
        if (item.ri_comments !== undefined){
          inputValue = item.ri_comments
        }

        Swal.fire({
          title: 'Kommentar',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: 'Speichern',
          cancelButtonText: 'Löschen',
          input: 'textarea',
          inputValue: inputValue,
          buttonsStyling: false,
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            item.ri_comments = result.value
            this.saveItem(item)
          }
          if (result.dismiss === 'cancel'){
            this.resetComment(item)
          }
        })
      }
    },
    computed: {
      totalQty () {
        let totalQty = 0;
        for (let item of this.items) {
          totalQty = totalQty + item.ri_qty
        }
        return totalQty
      }
    },
    created () {
      this.keyboard = localStorage.getItem('keyboard')
      if (localStorage.getItem('rma')) {
        this.rma = JSON.parse(localStorage.getItem('rma'))
        let items = JSON.parse(localStorage.getItem('rmaItems'))
        let newitems = []

        for (let i = 0; i < items.length; i++) {
          if (!items[i].problem) {
            items[i].problem = {problem: ''}
          }
          items[i].shownumbers = false
          if (items[i].qty_scanned === items[i].qty) {
            newitems.push(items[i])
          } else {
            newitems.unshift(items[i])
          }
        }
        this.items = newitems

        this.order = JSON.parse(localStorage.getItem('rmaOrder'))
      } else {
        this.$router.push({name: 'Retouren'})
      }
      if (localStorage.getItem('print_place')) {
        this.print_place = JSON.parse(localStorage.getItem('print_place'))
      }
    },
    mounted () {
    },
    destroyed () {
    }
  }
</script>
