<template>
  <div>
    <div class="row no-gutter">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <button ref="checkbutton" type="button" class="btn btn-xs btn-success btn-fill pick-buttons"
                @click="showSwalGroupPicking()"
                tabindex="2">
          <span class="btn-label">Pickliste erstellen</span>
        </button>
        <button ref="checkbutton" type="button" class="btn btn-xs btn-warning btn-fill pull-right pick-buttons"
                @click="showSwalSorting()"
                tabindex="2">
          <span class="btn-label">Wagen sortieren</span>
        </button>
      </div>
    </div>
    <vue-tabs class="card-content" @tab-change="picklistTab($event)">
      <v-tab :title="'Do (' + numberOfShipmentsToday + ')'">
        <div class="visible-sm visible-md visible-lg">
          <picklist-data-table :tableData="picklistsData" ref="picklistTable" :row-class-name="orangeRowClass">
            <template slot="columns">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div class="expand-wrapper" v-for="(shipment) in props.row.shipments" v-bind:key="shipment.shipment_increment_id">
                    {{ shipment.shipment_increment_id }}
                    <span v-if="shipment.boxes"> -> {{ shipment.boxes }} </span>
                    <i v-if="shipment.is_commissioned" class="ti-check icon-success"></i>
                    <i v-if="!shipment.is_commissioned" class="ti-close icon-danger"></i>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="ID" property="picklist_id">
                <template slot-scope="scope">
                  <button type="button" class="btn btn-fill btn-success" @click="getGroupPicking(scope.row.picklist_id)">
                    {{scope.row.picklist_id}}
                  </button>
                </template>
              </el-table-column>
              <el-table-column align="center" label="Benutzer" property="created_by"></el-table-column>
              <el-table-column align="center" label="Datum" width="120" property="created_at"
                               :formatter="dateFormatTimeDE"></el-table-column>
              <el-table-column align="center" label="Positionen" width="120" property="template"
                               :formatter="templateName"></el-table-column>
              <el-table-column align="center" label="Zeitraum" property="days" :formatter="daysName"></el-table-column>
              <el-table-column align="center" label="Lagerplatzgruppen" property="storageplaces" :formatter="storageplaceName"></el-table-column>
              <el-table-column align="center" label="Sendungen" property="number_shipments"></el-table-column>
              <el-table-column align="center" label="Picker" property="current_picker"></el-table-column>
              <el-table-column align="center" label="Status">
                <template slot-scope="scope1">
                  <i v-if="scope1.row.is_commissioned" class="ti-check icon-success"></i>
                  <i v-if="!scope1.row.is_commissioned" class="ti-close icon-danger"></i>
                </template>
              </el-table-column>
            </template>
          </picklist-data-table>
        </div>
        <div class="visible-xs">
          <picklist-data-table :tableData="picklistsData" ref="picklistTable" :row-class-name="orangeRowClass">
            <template slot="columns">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div class="expand-wrapper" v-for="(shipment) in props.row.shipments" v-bind:key="shipment.shipment_increment_id">
                    {{ shipment.shipment_increment_id }}
                    <span v-if="shipment.boxes"> -> {{ shipment.boxes }} </span>
                    <i v-if="shipment.is_commissioned" class="ti-check icon-success"></i>
                    <i v-if="!shipment.is_commissioned" class="ti-close icon-danger"></i>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Info">
                <template slot-scope="scope">
                  <button type="button" class="btn btn-fill btn-success" @click="getGroupPicking(scope.row.picklist_id)">
                    {{scope.row.picklist_id}}
                  </button><br/>
                  <span class="btn-label">vom {{dateFormatTimeDE(scope.row, scope.$index, scope.row.created_at)}}</span><br/>
                  <span class="btn-label">Benutzer: {{scope.row.created_by}}</span><br/>
                  <span class="btn-label">Positionen: {{templateName(scope.row, scope.$index, scope.row.template)}}</span><br/>
                  <span class="btn-label">Zeitraum: {{daysName(scope.row, scope.$index, scope.row.days)}}</span><br/>
                  <span class="btn-label">Lagerplatzgruppen: {{storageplaceName(scope.row, scope.$index, scope.row.storageplaces)}}</span><br/>
                  <span class="btn-label">Sendungen: {{scope.row.number_shipments}}</span><br/>
                  <span class="btn-label">Picker: {{scope.row.current_picker}}</span><br/>
                  <span class="btn-label">{{scope.row.is_commissioned ? 'vollständig' : 'unvollständig'}} </span>
                  <i v-if="scope.row.is_commissioned" class="ti-check icon-success"></i>
                  <i v-if="!scope.row.is_commissioned" class="ti-close icon-danger"></i>
                </template>
              </el-table-column>
            </template>
          </picklist-data-table>
        </div>
      </v-tab>
      <v-tab :title="'Best.'">
        <div class="row no-gutter">
          <span class="pull-right">
          <el-select class="select-success"
                     size="mini"
                     placeholder="Zeitraum"
                     @change="getPossiblePicklists"
                     v-model="selectedDay">
            <el-option v-for="(label, index) in this.timeframe"
                       class="select-success"
                       :value="index"
                       :label="label"
                       :key="index">
            </el-option>
          </el-select>
          <button ref="checkbutton" type="button" class="btn btn-xs btn-success btn-fill" @click="getPossiblePicklists">
              <span class="btn-label">
                  aktualisieren <i class="ti-reload"></i>
              </span>
          </button>
        </span>
        </div>
        <div v-if="possiblePicklists">
          <el-collapse v-model="activeNames">
            <div v-for="(template, templateName) in possiblePicklists" :key="templateName">
              <el-collapse-item :title="templateName + ' (' + templateSum[templateName] + ')'" :name="templateName">
                <el-collapse>
                  <h5 class="col-lg-12 col-sm-12" style="text-align:left; color:#66615b">
                    <table>
                      <tr v-for="(count, storageplaceGroup) in template" :key="storageplaceGroup" style="line-height:50px">
                        <template v-if="count !== 0">
                          <td>{{ storageplaceName(0, 0, storageplaceGroup) }}</td>
                          <td style="padding-left: 10px; padding-right: 10px"><i class="ti-arrow-right"></i></td>
                          <td>{{ count }}</td>
                          <td style="padding-left:10px" @click="createGroupPicking(templateName, storageplaceGroup, selectedDay)">
                            <i class="fa fa-square-plus fa-xl"></i>
                          </td>
                        </template>
                      </tr>
                    </table>
                  </h5>
                </el-collapse>
              </el-collapse-item>
            </div>
          </el-collapse>
        </div>
      </v-tab>
      <v-tab title="List">
        <div class="visible-sm visible-md visible-lg">
          <button ref="checkbutton" type="button" class="btn btn-xs btn-info btn-fill pick-buttons"
                  @click="toggleFilter">
            <span v-if="showOnlyNonCommissioned">Offene Listen</span>
            <span v-if="!showOnlyNonCommissioned">Alle Listen</span>
          </button>
         <data-table :get-data="getData" ref="table" :row-class-name="redRowClass">
            <template slot="columns">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div class="expand-wrapper" v-for="(shipment) in props.row.shipments" v-bind:key="shipment.shipment_increment_id">
                      {{ shipment.shipment_increment_id }}
                      <span v-if="shipment.boxes"> -> {{ shipment.boxes }} </span>
                      <i v-if="shipment.is_commissioned" class="ti-check icon-success"></i>
                      <i v-if="!shipment.is_commissioned" class="ti-close icon-danger"></i>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="ID" property="picklist_id">
                <template slot-scope="scope">
                  <button type="button" class="btn btn-fill btn-success" @click="getGroupPicking(scope.row.picklist_id)">
                    {{scope.row.picklist_id}}
                  </button>
                </template>
              </el-table-column>
              <el-table-column align="center" label="Benutzer" property="created_by"></el-table-column>
              <el-table-column align="center" label="Datum" width="120" property="created_at"
                               :formatter="dateFormatTimeDE"></el-table-column>
              <el-table-column align="center" label="Positionen" width="120" property="template"
                               :formatter="templateName"></el-table-column>
              <el-table-column align="center" label="Zeitraum" property="days" :formatter="daysName"></el-table-column>
              <el-table-column align="center" label="Lagerplatzgruppen" property="storageplaces" :formatter="storageplaceName"></el-table-column>
              <el-table-column align="center" label="Sendungen" property="number_shipments"></el-table-column>
              <el-table-column align="center" label="Picker" property="current_picker"></el-table-column>
              <el-table-column align="center" label="Status">
                <template slot-scope="scope1">
                  <i v-if="scope1.row.is_commissioned" class="ti-check icon-success"></i>
                  <i v-if="!scope1.row.is_commissioned" class="ti-close icon-danger"></i>
                </template>
              </el-table-column>
            </template>
          </data-table>
        </div>
        <div class="visible-xs">
          <button ref="checkbutton" type="button" class="btn btn-xs btn-info btn-fill pick-buttons" style="margin-bottom: 1em;"
                  @click="toggleFilter">
            <span v-if="showOnlyNonCommissioned">Offene Listen</span>
            <span v-if="!showOnlyNonCommissioned">Alle Listen</span>
          </button>
          <data-table :get-data="getData" ref="table_mobile" :row-class-name="redRowClass">
            <template slot="columns">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div class="expand-wrapper" v-for="(shipment) in props.row.shipments" v-bind:key="shipment.shipment_increment_id">
                      {{ shipment.shipment_increment_id }}
                      <span v-if="shipment.boxes"> -> {{ shipment.boxes }} </span>
                      <i v-if="shipment.is_commissioned" class="ti-check icon-success"></i>
                      <i v-if="!shipment.is_commissioned" class="ti-close icon-danger"></i>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Info">
                <template slot-scope="scope">
                  <button type="button" class="btn btn-fill btn-success" @click="getGroupPicking(scope.row.picklist_id)">
                    {{scope.row.picklist_id}}
                  </button><br/>
                  <span class="btn-label">vom {{dateFormatTimeDE(scope.row, scope.$index, scope.row.created_at)}}</span><br/>
                  <span class="btn-label">Benutzer: {{scope.row.created_by}}</span><br/>
                  <span class="btn-label">Positionen: {{templateName(scope.row, scope.$index, scope.row.template)}}</span><br/>
                  <span class="btn-label">Zeitraum: {{daysName(scope.row, scope.$index, scope.row.days)}}</span><br/>
                  <span class="btn-label">Lagerplatzgruppen: {{storageplaceName(scope.row, scope.$index, scope.row.storageplaces)}}</span><br/>
                  <span class="btn-label">Sendungen: {{scope.row.number_shipments}}</span><br/>
                  <span class="btn-label">Picker: {{scope.row.current_picker}}</span><br/>
                  <span class="btn-label">{{scope.row.is_commissioned ? 'vollständig' : 'unvollständig'}} </span>
                  <i v-if="scope.row.is_commissioned" class="ti-check icon-success"></i>
                  <i v-if="!scope.row.is_commissioned" class="ti-close icon-danger"></i>
                </template>
              </el-table-column>
            </template>
          </data-table>
        </div>
      </v-tab>
      <v-tab :title="'Prob. (' + this.statsData.problems.length + ')'">
        <div class="visible-md visible-lg">
          <paginated-table :table-data="this.statsData.problems">
            <template slot="columns">
              <el-table-column label="Versand bis" property="parc_latest_delivery_date" :formatter="dateFormatDE"></el-table-column>
              <el-table-column label="Datum"  property="starttime" :formatter="dateFormatTimeDE"></el-table-column>
              <el-table-column label="Sendung">
                <template slot-scope="scope">
                  <a href="#" @click="shipmentClick(scope.$index, scope.row)"><span class="btn-label">{{scope.row.shipment_increment_id}}</span></a><br/>
                </template>
              </el-table-column><el-table-column label="Picker"  property="packer"></el-table-column>
              <el-table-column label="Kommentar"  property="comment"></el-table-column>
            </template>
          </paginated-table>
        </div>
        <div class="visible-xs visible-sm">
          <paginated-table :table-data="this.statsData.problems">
            <template slot="columns">
              <el-table-column label="Info">
                <template slot-scope="scope">
                  <a href="#" @click="shipmentClick(scope.$index, scope.row)"><span class="btn-label">Sendung: {{scope.row.shipment_increment_id}}</span></a><br/>
                  <span class="btn-label">Datum: {{dateFormatTimeDE(scope.row, scope.$index, scope.row.starttime)}}</span><br/>
                  <span class="btn-label">Versand bis: {{dateFormatDE(scope.row, scope.$index, scope.row.parc_latest_delivery_date)}}</span><br/>
                  <span class="btn-label">Picker: {{scope.row.packer}}</span><br/>
                  <span class="btn-label">Kommentar: {{scope.row.comment}}</span><br/>
                </template>
              </el-table-column>
            </template>
          </paginated-table>
        </div>
      </v-tab>
    </vue-tabs>
  </div>
</template>
<script>
import DataTable from '@/components/Table/DataTable.vue'
import PicklistDataTable from '@/components/Table/PicklistDataTable.vue'
import PaginatedTable from '@/components/Table/Paginated.vue'
import config from '@/config'
import axios from 'axios'
import auth from '@/auth'
import {Loading} from 'element-ui'
import moment from 'moment'
import Swal from 'sweetalert2'

export default {
  components: {
    DataTable,
    PaginatedTable,
    'picklist-data-table': PicklistDataTable, // Register the renamed component
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data () {
    return {
      resultMessage: '',
      statsData: {
        problems: [],
        shipments: [],
      },
      username: '',
      loggedInUser: '',
      days: '0',
      tab: 0,
      possiblePicklists: [],
      showOnlyNonCommissioned: false,
      picklistsData: [],
      selectedDay: '0',
      activeNames: ['1pos', 'alle']
    }
  },
  created () {
    this.props = []
    this.username = localStorage.getItem('username')
    this.loggedInUser = localStorage.getItem('username')
    // if (!localStorage.getItem('order_stats')) {
    this.loadOrderStats()
    this.getPicklistData().then((data) => {
      this.picklistsData = data.data;
    })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    // }
    if (localStorage.getItem('order_stats')) {
      this.statsData = JSON.parse(localStorage.getItem('order_stats'))
    }
    this.pie_options = {
      height: '250px',
      width: '250px',
      donut: true,
      donutWidth: 60,
      startAngle: 270,
      showLabel: true,
      labelPosition: 'center',
      labelInterpolationFnc: function (value) {
        return value
      }
    }
    this.period_options = {
      showPoint: false,
      lineSmooth: true,
      height: '250px',
      axisX: {
        offset: 50,
        showGrid: true,
        showLabel: true,
        labelOffset: {
          x: -10,
          y: 0
        },
        labelInterpolationFnc: function skipLabels (value, index, labels) {
          let labelScale = Math.round((labels.length) / 7)
          if (index === 0 /* || index === labels.length - 1 */) {
            return value.substring(0, 5)
          } else if (labels.length > 10) {
            return index % labelScale === 0 ? value.substring(0, 5) : null
          } else {
            return value.substring(0, 5)
          }
        }
      },
      axisY: {
        offset: 40,
        showGrid: true
      },
      low: 0,
      /* high: 'auto', */
      classNames: {
        line: 'ct-line'
      }
    }
  },
  computed: {
    chartData () {
      return Object.assign({}, this.statsData)
    },
    storageplace_groups_group_picking: function () {
      let reasons = {
        'unten': 'Unten',
        'unten_oben': 'Unten & Oben',
        'alle' : 'Alle'
      }
      return reasons
    },
    timeframe: function () {
      let reasons = {
        '0': 'Heute',
        '1': 'Morgen',
        '7': '7 Tage',
        '999': 'Zukunft'}
      return reasons
    },
    picklist_templates: function () {
      let reasons = {
        '1pos': '1 Pos.',
        'alle': 'Alle'}
      return reasons
    },
    storageplace_groups: function () {
      let reasons = {
        'a_unten': 'A-Unten',
        'a_oben': 'A-Oben',
        'bc_unten': 'B/C-Unten',
        'bc_oben': 'B/C-Oben',
        'de_unten': 'D/E-Unten',
        'de_oben': 'D/E-Oben',
        'fgi_unten': 'F/G/I-Unten',
        'fgi_oben': 'F/G/I-Oben',
        'hix_unten': 'H/I/X-Unten',
        'hi_oben': 'H/I-Oben',
        'baumbox': 'Baumboxen',
        'ebene_z': 'Ebene-Z',
        'ameise': 'Ameise'
      }
      return reasons
    },
    numberOfShipmentsToday() {
      let total = 0;
      for (const picklist of this.picklistsData) {
        for (const shipment of picklist.shipments) {
          if (!shipment.is_commissioned) {
            total = total + 1;
          }
        }
      }
      return total;
    },
    templateSum() {
      const sum = {};
      for (const templateName in this.possiblePicklists) {
        sum[templateName] = 0;
        const template = this.possiblePicklists[templateName];
        for (const storageplaceGroup in template) {
          sum[templateName] += template[storageplaceGroup];
        }
      }
      return sum;
    }
  },
  methods: {
    loadOrderStats () {
      // if(this.tab === 0) {
        let url = config.routes.commissionshipmentstats
        url = url + '/0/null'
        let loadingInstance = Loading.service({})
        axios
            .get(url, {
              headers: auth.getAuthHeader()
            })
            .then(response => {
              var stats = response.data
              if (stats.length) {
                this.statsData = stats[0]
                localStorage.setItem('order_stats', JSON.stringify(stats[0]))
                this.statsData = JSON.parse(localStorage.getItem('order_stats'))
              }
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      // }
    },
    getData ({ page, perPage }) {
      let url = config.routes.getpicklists + '/' + page + '/' + perPage
      return axios
        .get(url, {
          headers: auth.getAuthHeader()
        })
        .then(response => {
          let data = []
          if (this.showOnlyNonCommissioned) {
            data = response.data[0].data.filter(item => item.is_commissioned === false)
          } else {
            data = response.data[0].data
          }
          return {
            data: data,
            total: response.data[0].total_count
          }
        })
        .catch(e => {
          if (e.response.status == 401) {
            auth.logout()
          }
        })
    },
    getPicklistData () {
      const today = new Date().toJSON().slice(0, 10);
      let url = config.routes.getpicklists + '/' + 1 + '/' + 100
      return axios
          .get(url, {
            headers: auth.getAuthHeader()
          })
          .then(response => {
            let data = []

            data = response.data[0].data
                .filter(item => item.is_commissioned === false)
                .filter((item) => item.created_at.slice(0, 10) === today)
                .sort((a, b) => {
                  // Compare 'picklist_id' values in ascending order
                  return a.picklist_id - b.picklist_id;
                });

            return {
              data: data,
              total: response.data[0].total_count
            }
          })
          .catch(e => {
            if (e.response.status == 401) {
              auth.logout()
            }
          })
    },
    dateFormatDE: (row, col, value) => {
      if (value) {
        return moment.utc(value).local().format('DD.MM.YYYY')
      }
      return ''
    },
    dateFormatTimeDE: (row, col, value) => {
      if (value) {
        return moment.utc(value).local().format('DD.MM.YY HH:mm')
      }
      return ''
    },
    templateName: (row, col, value) => {
      if (value) {
        let picklistTemplates = {
          'alle': 'Alle',
          '1pos': '1 Pos.'
        }
        return picklistTemplates[value]
      }
      return ''
    },
    storageplaceName: (row, col, value) => {
      if (value) {
        let storageplaceName = {
          'a_oben': 'A-Oben',
          'a_unten': 'A-Unten',
          'ameise': 'Ameise',
          'baumbox': 'Baumboxen',
          'bc_oben': 'B/C-Oben',
          'bc_unten': 'B/C-Unten',
          'de_oben': 'D/E-Oben',
          'de_unten': 'D/E-Unten',
          'ebene_z': 'Ebene-Z',
          'fgi_oben': 'F/G/I-Oben',
          'fgi_unten': 'F/G/I-Unten',
          'hi_oben': 'H/I-Oben',
          'hix_unten': 'H/I/X-Unten',
          'unten': 'Unten',
          'unten_oben': 'Unten & Oben',
          'alle': 'Alle'
        }
        return storageplaceName[value]
      }
      return ''
    },
    daysName: (row, col, value) => {
      if (value) {
        let dayName = {
          '0': 'Heute',
          '1': 'Morgen',
          '7': '7 Tage',
          '999': 'Zukunft'
        }
        return dayName[value]
      }
      return ''
    },
    picklistTab (tabIndex) {
      if (tabIndex === 0) {
        this.tab = 0
        this.loadOrderStats()
      } else if (tabIndex === 1) {
        // this.$refs.table.getTableData(1)
        // this.$refs.table_mobile.getTableData(1)
        // this.tab = 1
        this.getPossiblePicklists()
      }
    },
    shipmentClick (index, row) {
      let shipmentId = row.shipment_increment_id
      this.$router.push({name: 'Picken', params: {q: shipmentId}})
    },
    showSwalGroupPicking: async function groupPicking() {
      const steps = ['1', '2', '3']
      const values = ['', '', '']
      let currentStep
      const swalQueue = Swal.mixin({
        progressSteps: steps,
        confirmButtonText: 'Weiter',
        cancelButtonText: 'Zurück',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        reverseButtons: true,
        allowOutsideClick: true,
      })
      for (currentStep = 0; currentStep < steps.length;) {
        let result = ''
        if (currentStep === 0) {
          result = await swalQueue.fire({
            title: 'Positionen',
            showCancelButton: true,
            cancelButtonText: 'Abbrechen',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
              cancelButton: 'btn btn-danger btn-fill',
            },
            input: 'select',
            inputOptions: this.picklist_templates,
            inputAttributes: {
              required: true
            },
            inputValue: values[currentStep],
            reverseButtons: true,
            validationMessage: 'Bitte Positionen auswählen',
            inputPlaceholder: 'Bitte wählen',
            allowOutsideClick: true,
            currentProgressStep: currentStep
          })
        }
        if (currentStep === 1) {
          let options = ''
          if (values[0] == '1pos') {
            options = this.storageplace_groups
          } else {
            options = this.storageplace_groups_group_picking
          }
          result = await swalQueue.fire({
            title: 'Lagerplatzgruppe(n)',
            showCancelButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
              cancelButton: 'btn btn-danger btn-fill',
            },
            input: 'select',
            inputOptions: options,
            inputAttributes: {
              required: true
            },
            inputValue: values[currentStep],
            reverseButtons: true,
            validationMessage: 'Bitte Lagerplatzgruppen auswählen',
            inputPlaceholder: 'Bitte wählen',
            allowOutsideClick: true,
            currentProgressStep: currentStep
          })
        }
        if (currentStep === 2) {
          result = await swalQueue.fire({
            title: 'Zeitraum',
            showCancelButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
              cancelButton: 'btn btn-danger btn-fill',
            },
            input: 'select',
            inputOptions: this.timeframe,
            inputAttributes: {
              required: true
            },
            inputValue: values[currentStep],
            reverseButtons: true,
            validationMessage: 'Bitte Zeitraum auswählen',
            inputPlaceholder: 'Bitte wählen',
            allowOutsideClick: true,
            currentProgressStep: currentStep
          })
        }
        if (result.value) {
          values[currentStep] = result.value
          currentStep++
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          currentStep--
        } else {
          break
        }
      }
      if (currentStep === steps.length) {
        this.createGroupPicking(values[0], values[1], values[2])
      }
    },
    createGroupPicking(template, storageplaceGroup, timeframe) {
        let loadingInstance = Loading.service({})
        axios
            .post(config.routes.groupPicking, {
                  'template': template,
                  'storageplaceGroups': storageplaceGroup,
                  'timeframe': timeframe
                },
                {headers: auth.getAuthHeader()
            })
            .then(response => {
              if (response.data.picklist_id) {
                localStorage.setItem('group_picking', JSON.stringify(response.data))
                this.$router.push({name: 'Sammelkommissionierung'})
              } else {
                this.$showErrorMessage(this.$notify,
                    'Es wurden keine Bestellungen gefunden'
                )
              }
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
    },
    getGroupPicking(picklistId) {
      let loadingInstance = Loading.service({})
      axios
          .get(config.routes.groupPickingByPicklist + '/' + picklistId,
              {headers: auth.getAuthHeader()
              })
          .then(response => {
            if (response.data.picklist_id) {
              localStorage.setItem('group_picking', JSON.stringify(response.data))
              this.$router.push({name: 'Sammelkommissionierung'})
            } else {
              this.$showErrorMessage(this.$notify,
                  'Es wurden keine Bestellungen gefunden'
              )
            }
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
    },
    showSwalSorting: function () {
      Swal.fire({
        title: 'Wagen auswählen',
        showCancelButton: true,
        cancelButtonText: 'Abbrechen',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        input: 'select',
        inputOptions: config.pickWagen,
        inputAttributes: {
          required: true
        },
        reverseButtons: true,
        validationMessage: 'Bitte Wagen auswählen',
        inputPlaceholder: 'Bitte wählen',
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.value) {
          let wagen = result.value
          let url = config.routes.sortTrolley + '/' + wagen
          let loadingInstance = Loading.service({})
          axios
              .get(url, {
                headers: auth.getAuthHeader()
              })
              .then(response => {
                if (response.data[0].products.length > 0) {
                  this.$router.push({name: 'Sortiervorgang', params: {q: response.data[0]}})
                } else {
                  this.$showErrorMessage(this.$notify,
                      'Es wurden keine Artikel zum Sortieren gefunden'
                  )
                }
                loadingInstance.close()
              })
              .catch(e => {
                loadingInstance.close()
                if (e.response.status == 401) {
                  auth.logout()
                }
              })
        }
      })
    },
    redRowClass(scope1) {
      return {
        'row-red': !scope1.row.is_commissioned
      }
    },
    orangeRowClass(scope1) {
      return {
        'row-orange': scope1.row.current_picker
      }
    },
    getPossiblePicklists() {
      let loadingInstance = Loading.service({})
      axios
          .get(config.routes.getpossiblepicklists + '/' + this.selectedDay,
              {headers: auth.getAuthHeader()
              })
          .then(response => {
            console.log(response)
            if (response.data[0])
            this.possiblePicklists = response.data[0]
            localStorage.setItem('possible_picklists', JSON.stringify(this.possiblePicklists))
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
    },
    toggleFilter() {
      this.showOnlyNonCommissioned = !this.showOnlyNonCommissioned;
      this.$refs.table.getTableData(1)
      this.$refs.table_mobile.getTableData(1) // Refresh the table data when the filter is toggled
    },
  }
}

</script>
<style>
/*.ct-label.ct-horizontal.ct-end {
  position: relative;
  justify-content: flex-end;
  text-align: right;
  transform-origin: 100% 0;
  transform: translate(-100%) rotate(-45deg);
  white-space:nowrap;
  margin-top: 14px;
}*/
.card-content .ct-chart {
  text-align: center;
}

.select-success.el-select .el-input .el-input__icon {
  height: auto !important;
}

.el-tag.el-tag--info {
  color: white !important;
}

.expand-wrapper {
  margin-left: 30px;
}
.row-red {
  background-color: #ffcccb !important;
}

.row-orange {
  background-color: #ffff9e !important;
}

.pick-buttons {
  font-size: 14px !important;
  padding: 0.5em !important;
}
</style>
