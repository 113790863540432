<template>
  <div v-if="this.shipments.length">
    <form @submit.prevent="submitShippingItemForm">
      <div class="input-group">
        <input :inputmode="keyboard" v-model="articleid" ref="inputfield" type="text" v-focus class="form-control" tabindex="1" placeholder="Artikel">
        <span class="input-group-addon" @click="submitShippingItemForm"><i class="fa fa-search"></i></span>
      </div>
    </form>
    <h3> {{this.shipments.length}} Sendungen in {{this.commission_box}}</h3>

    <div class="card">
      <div class="card-content">
        <el-collapse>
          <div v-for="(shipment, index) in shipments" :key="index">
            <el-collapse-item>
              <template #title>
                {{ shipment.shipmentIncrementId }}
                <span class="delivery-date"> ({{ monthAndDay(shipment.parcLatestDeliveryDate) }})</span>
              </template>
              <div class="row">
                <div class="col-lg-6 col-sm-6" v-for="(item, index) in shipment.items" :key="index">
                  <shipmentitem-card>
                    <div class="text-center" :class="`icon-info`" slot="header">
                      <img :src="item.image" @click="showSwalImage(item)" @error="item.image = '/img/placeholder.jpg'" />
                    </div>
                    <div class="numbers" slot="content">
                      <button type="button" class="btn btn-sm btn-success btn-fill pick-button">
                        <span class="qty-scanned">{{item.qty}}</span> Stück
                      </button>
                      <span>{{item.name}}</span><br/>
                    </div>
                    <div slot="footer">
                      <br/>
                      <div class="col-xs-12">
                        <table class="table table-sm table-striped">
                          <tbody>
                          <tr v-if="item.man_sku">
                            <td>Man. SKU</td>
                            <th>
                              <router-link :to="{ name: 'Produkt', params: { productid: item.productId}}" target="_blank">
                                {{item.man_sku}}
                              </router-link>
                            </th>
                          </tr>
                          <tr v-if="item.man_ean">
                            <td>Man. EAN</td>
                            <th>{{item.man_ean}}</th>
                          </tr>
                          <tr>
                            <td>SKU</td>
                            <th>{{item.sku}}</th>
                          </tr>
                          <tr v-if="item.ean">
                            <td>EAN</td>
                            <th>{{item.ean}}</th>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </shipmentitem-card>
                </div>
                <div class="col-lg-12 col-sm-12">
                <button ref="checkbutton" type="button" class="btn btn-md btn-danger btn-fill pull-right"
                        @click="packShipment(shipment.shipmentIncrementId)">
                  <i class="fa-regular fa-box fa-xl"></i> Packen
                </button>
                </div>
              </div>

            </el-collapse-item>
          </div>
        </el-collapse>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-sm-12 text-center" >
        <button ref="checkbutton" type="button" class="btn btn-md btn-success btn-fill pull-right" tabindex="99" @click="reload(commission_box)">
                <span class="btn-label">
                    <i class="fa fa-rotate"></i>
                </span>
          neu laden
        </button>
        <br/><br/>
      </div>
    </div>
  </div>
</template>
<script>
  import Swal from "sweetalert2";
  import ShipmentitemCard from '@/components/Cards/ShipmentItemCard'
  import config from "@/config";
  import {SoundError} from "@/main";
  import moment from "moment/moment";
  import {Loading} from "element-ui";
  import axios from "axios";
  import auth from "@/auth";

  export default {
    components: {
      ShipmentitemCard
    },
    data () {
      return {
        loading: false,
        articleid: '',
      }
    },
    methods: {
      submitShippingItemForm () {
        if (this.articleid) {
          this.handleScan(this.articleid)
          this.articleid = ''
        }
      },
      handleScan (searchString) {
        if (searchString === config.endSequence) {
          this.cancel()
          return
        }

        let item = this.findItem(searchString)
        if (item !== undefined) {
          this.checkItem(item)
        }
      },
      findItem (searchString) {
        let shipments = this.shipments
        let nextShipment = ''
        outerLoop: for (let i = 0; i < shipments.length; i++) {
          let items = shipments[i].items;
          for (let f = 0; f < items.length; f++) {
            if (
                items[f].sku.toLowerCase() === searchString.toLowerCase() ||
                (items[f].ean && items[f].ean.toLowerCase() === searchString.toLowerCase()) ||
                (items[f].man_sku && items[f].man_sku.toLowerCase() === searchString.toLowerCase()) ||
                (items[f].man_ean && items[f].man_ean.toLowerCase() === searchString.toLowerCase())
            ) {
              nextShipment = shipments[i].shipmentIncrementId;
              break outerLoop; // Exit both loops
            }
          }
        }
        if (nextShipment) {
          this.packShipment(nextShipment)
        } else {
          SoundError.play()
          this.$showErrorMessage(this.$notify,
              'Artikel nicht gefunden'
          )
        }
      },
      showSwalImage: function (item) {
        Swal.fire({
          html: '<img src="' + item.image + '" width="100%">',
          width: 600,
          showConfirmButton: false,
          showCloseButton: true,
          customClass: {
            closeButton: 'btn btn-danger btn-fill'
          },
          buttonsStyling: false
        })
      },
      packShipment(incrementId) {
        let loadingInstance = Loading.service({})
        axios
            .get(config.routes.shipment, {
              params: {incrementId: incrementId},
              headers: auth.getAuthHeader()
            })
            .then(response => {
              if (response.data['is_commissioned'] === false) {
                this.$showErrorMessage(this.$notify,
                    'Die Sendung "' + incrementId + '" wurde noch nicht vollständig kommissioniert.'
                )
              } else {
                var items = response.data['items']
                var boxes = response.data['boxes']
                let nrPackages = 0
                if (boxes) {
                  for (let box of boxes) {
                    // Umwandeln von int auf bool für Switch/checkbox
                    box.is_package = box.is_package === 1
                    box.falltest = box.falltest === 1
                    if (box.is_package) {
                      nrPackages++
                      box.packagenumber = nrPackages
                    }
                    let lvitem = this.findLVItem(box.sku)
                    box.name = lvitem.name
                  }
                }
                if (items && items.length) {
                  localStorage.setItem('shipmentId', JSON.stringify(incrementId))
                  localStorage.setItem('shipment', JSON.stringify(response.data))
                  localStorage.setItem('scanItems', JSON.stringify(items))
                  localStorage.setItem('boxItems', JSON.stringify(boxes))
                  this.saveshipmentscan()
                  this.saveshipmentcomment('Packen Start')
                  this.$router.push({name: 'Prüfen'})
                } else {
                  this.$showErrorMessage(this.$notify,
                      'Es wurden keine Sendung für "' + incrementId + '" gefunden'
                  )
                }
              }
              loadingInstance.close()
              this.backlink = incrementId
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
              this.$showErrorMessage(this.$notify,
                  'Es wurden keine Sendung für "' + incrementId + '" gefunden'
              )
            })
      },
      monthAndDay: (value) => {
        return moment.utc(value).local().format('DD.MM')
      },
      saveshipmentscan () {
        let url = config.routes.scanshipment
        let shipment = JSON.parse(localStorage.getItem('shipment'))
        if (shipment) {
          let loadingInstance = Loading.service({})
          axios
              .post(url, {
                    'shipmentScan': {
                      'shipmentId': shipment.shipment_id,
                      'shipmentIncrementId': shipment.increment_id,
                      // "duration": 0,
                      'packer': localStorage.getItem('username'),
                      'timetype': 1,
                      'ordervalue': shipment.order_value,
                      'storeId': shipment.store_id
                    }
                  },
                  { headers: auth.getAuthHeader() }
              )
              .then(response => {
                localStorage.setItem('shipmentscan_id_check', JSON.stringify(response.data.shipmentscan_id))
                loadingInstance.close()
              })
              .catch(e => {
                loadingInstance.close()
                if (e.response.status == 401) {
                  auth.logout()
                }
              })
        }
      },
      saveshipmentcomment (comment) {
        let url = config.routes.shipmentcomment
        let shipment = JSON.parse(localStorage.getItem('shipment'))
        comment = comment + '<br/>by ' + localStorage.getItem('username')
        if (shipment) {
          let loadingInstance = Loading.service({})
          axios
              .post(url, {
                    'shipmentId': shipment.shipment_id,
                    'comment': comment
                  },
                  { headers: auth.getAuthHeader() }
              )
              .then(() => {
                loadingInstance.close()
              })
              .catch(e => {
                loadingInstance.close()
                if (e.response.status == 401) {
                  auth.logout()
                }
              })
        }
      },
      findLVItem (sku) {
        let items
        if (localStorage.getItem('lvItems')) {
          items = JSON.parse(localStorage.getItem('lvItems'))
        }
        let f
        let found
        found = items.findIndex(function (item, index) {
          f = index
          return item.sku.toLowerCase() === sku.toLowerCase()
        })
        if (found >= 0) {
          return items[f]
        }
      },
      reload (commissionBox) {
        let loadingInstance = Loading.service({})
        axios
            .get(config.routes.boxesToPack + '/' + commissionBox,
                { headers: auth.getAuthHeader() }
            )
            .then(response => {
              if (response.data){
                if (response.data.length == 0) {
                  this.$showErrorMessage(this.$notify,
                      'Es wurden keine Sendungen für die Box "' + commissionBox + '" gefunden'
                  )
                  this.$router.push({ name: 'Packen' }).catch(() => {})
                } else {
                  localStorage.setItem('commission_boxes_shipment', JSON.stringify(response.data[0]))
                  this.shipments = response.data[0]
                  location.reload()
                }
              }
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      }
    },
    mounted () {
    },
    created () {
      this.keyboard = localStorage.getItem('keyboard')
      if (this.$route.params.q) {
        localStorage.setItem('commission_box', JSON.stringify(this.$route.params.q))
        this.commission_box = this.$route.params.q
      } else {
        this.commission_box = JSON.parse(localStorage.getItem('commission_box'))
      }
      if (this.$route.params.s) {
        localStorage.setItem('commission_boxes_shipment', JSON.stringify(this.$route.params.s[0]))
        this.shipments = this.$route.params.s[0]
      } else {
        this.shipments = JSON.parse(localStorage.getItem('commission_boxes_shipment'))
      }

      localStorage.removeItem('scanItems')
      localStorage.removeItem('shipmentId')
      localStorage.removeItem('shipment')
      localStorage.removeItem('boxItems')
      localStorage.removeItem('shipmentscan_id_check')
      localStorage.removeItem('shipmentscan_id_pack')

    },
    computed: {},
    destroyed () {}
  }
</script>
<style>
.delivery-date {
  margin-left: 1em;
  font-size: 12px;
}
</style>