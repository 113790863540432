<template>
  <div>
    <div class="row" v-if="items.length">
      <div class="col-xs-10">
        <h4>{{shipmentId}}
        <button ref="checkbutton" type="button" class="btn btn-xs btn-fill"
          @click="printShipment(shipmentId)"
          tabindex="2">
          <span class="btn-label">
              <i class="ti-printer"></i>
          </span>
        </button>
        </h4>
      </div>
      <div class="col-xs-2">
        <span v-for="(item, index) in items" :key="index">
          <span class="pull-right icon-problem" v-if="item.commission_scan_comment[2]">
            <i class="icon-danger ti-alert"></i>
          </span>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-sm-6" v-for="(item, index) in items" :key="index">
        <shipmentitem-card :class="item.qty_scanned == item.qty || item.checkedComplete == true ? 'finished' : 'false' " :linkto="{ name: 'Produkt', params: { productid: item.id}}">
          <div class="text-center" :class="`icon-info`" slot="header">
            <table class="storageLocationTable">
              <tr class="label-parc2" v-for="(location, index) in item.parc_storageplace" :key="index">
                <td>{{ location.name }}</td>
                <td><i class="ti-arrow-right"></i></td>
                <td>{{ location.parc_qty }} Stück</td>
              </tr>
            </table>
            <div class="image-container">
              <img :src="item.image" @click="showSwalImage(item)" @error="item.image = '/img/placeholder.jpg'" />
              <span v-if="item.commission_scan_comment[2]" @click="showSwalImage(item)">
                <i class="watermark-icon icon-danger ti-alert"></i>
              </span>
            </div>
          </div>
          <div class="numbers" slot="content">
            <button ref="checkbutton" :disabled="item.qty_scanned == item.qty || item.checkedComplete ? 'disabled' : false" type="button" class="btn btn-sm btn-success btn-fill pick-button" @click="showSwal(item, this)">
              zu picken <span class="qty-scanned">{{item.qty - item.qty_scanned}}</span> Stück
            </button>
            <span>{{item.name}}</span><br/>
          </div>
          <div class="buttons" slot="button">
            <button ref="checkbutton" type="button" class="btn btn-lg btn-simple pull-left"
                    @click="toggleItem(item)">
              <span class="btn-label">
                  <i class="ti-more-alt"></i>
              </span>
            </button>
            <button ref="checkbutton" type="button" class="btn btn-md btn-danger btn-fill pull-right"
                    @click="showSwalComment(item)">
              <span class="btn-label">
                <i class="fa fa-comment-dots fa-xl"></i>
              </span>
            </button>
          </div>
          <div slot="footer">
            <br/>
            <div v-if="item.shownumbers">
              <div class="col-xs-12">
                <table class="table table-sm table-striped">
                  <tbody>
                    <tr>
                      <td>insg. benötigt</td>
                      <th>{{ item.qty }} Stück</th>
                    </tr>
                    <tr v-if="item.manufacturer_sku">
                      <td>Man. SKU</td>
                      <th>
                        <router-link :to="{ name: 'Produkt', params: { productid: item.product_id}}" target="_blank">
                          {{item.manufacturer_sku}}
                        </router-link>
                      </th>
                    </tr>
                    <tr v-if="item.manufacturer_ean">
                      <td>Man. EAN</td>
                      <th>{{item.manufacturer_ean}}</th>
                    </tr>
                    <tr>
                      <td>SKU</td>
                      <th>{{item.sku}}</th>
                    </tr>
                    <tr v-if="item.ean">
                      <td>EAN</td>
                      <th>{{item.ean}}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </shipmentitem-card>
      </div>
    </div>
    <div class="row" v-if="!shipment.is_packed">
      <div class="col-lg-12 col-sm-12 text-center" >
        <button ref="checkbutton" type="button" class="btn btn-md btn-danger btn-fill pull-left" tabindex="99" @click="showSwalReset()">
          Reset
        </button>
        <button ref="checkbutton" type="button" class="btn btn-md btn-success btn-fill pull-right" tabindex="99" @click="reload(shipmentId)">
                <span class="btn-label">
                    <i class="fa fa-rotate"></i>
                </span>
          neu laden
        </button>
        <br/><br/>
      </div>
    </div>

  </div>
</template>
<script>
  import {EventBus, SoundOk, SoundError} from '@/main.js'
  import config from '@/config.js'
  import ShipmentitemCard from '@/components/Cards/ShipmentItemCard'
  import Swal from 'sweetalert2'
  import {Loading} from 'element-ui'
  import axios from 'axios'
  import auth from '@/auth'

export default {
    components: {
      ShipmentitemCard
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    data () {
      return {
        shippingid: '',
        articleid: '',
        locationId: '',
        items: [],
        loading: false,
        shipment: '',
        isFirst: 0
      }
    },
    methods: {
      handleScan (searchString) {
        if (searchString === config.endSequence) {
          this.cancel()
          return
        }
        let item = this.findItem(searchString)
        if (item !== undefined) {
          this.showSwal(item)
        }
      },
      showSwal: async function storein(item) {
        const steps = ['1', '2']
        const values = ['', '']
        let currentStep
        const swalQueue = Swal.mixin({
          progressSteps: steps,
          confirmButtonText: 'Weiter',
          cancelButtonText: 'Zurück',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          reverseButtons: true,
          allowOutsideClick: true,
          backdrop: true
        })
        for (currentStep = 0; currentStep < steps.length;) {
          let result = ''
          if (currentStep === 0) {
            let keyboard = localStorage.getItem('keyboard')
            result = await swalQueue.fire({
              title: `Lagerplatz`,
              inputValue: values[currentStep],
              showCancelButton: currentStep > 0,
              currentProgressStep: currentStep,
              input: 'text',
              returnInputValueOnDeny: false,
              inputAttributes: {
                required: true,
                inputmode: keyboard,
                id: 'LagerplatzInput',
                autocomplete: 'off'
              },
              preConfirm: (location) => {
                return axios
                    .get(config.routes.getlocation, {
                      params: {name: location},
                      headers: auth.getAuthHeader()
                    })
                    .then(response => {
                      if (response.data.length === 1) {
                        return response.data[0]
                      } else {
                        let errorText = "Lagerplatz " + location + " nicht gefunden"
                        document.getElementById('LagerplatzInput').value = ''
                        throw new Error(errorText)
                      }
                    })
                    .then(location => {
                      // abfragen ob product/location schon vergeben ist
                      return axios
                          .get(config.routes.productlocationcheck, {
                            params: {storageplaceId: location.id, productId: item.product_id},
                            headers: auth.getAuthHeader()
                          })
                          .then(response => {
                            if (response.data === false) {
                              let errorText = "Lagerplatz " + location.name + " nicht zugewiesen"
                              document.getElementById('LagerplatzInput').value = ''
                              throw new Error(errorText)
                            } else {
                              return location
                            }
                          })
                          .catch(error => {
                            Swal.showValidationMessage(error)
                          })
                    })
                    .catch(error => {
                      Swal.showValidationMessage(error)
                    })
              },
              allowOutsideClick: true,
              reverseButtons: true,
              validationMessage: 'Bitte Lagerplatz eingeben',
              inputPlaceholder: 'Lagerplatz'
            })
          }
          if (currentStep === 1) {
            let keyboard = localStorage.getItem('keyboard')
            result = await swalQueue.fire({
              title: `Menge`,
              input: 'number',
              inputAttributes: {
                required: true,
                inputmode: keyboard,
                id: 'MengeInput',
                autocomplete: 'off'
              },
              preConfirm: (qty) => {
                let locationId = values[0].id
                let locationName = values[0].name
                  return axios
                      .get(config.routes.productlocationqty, {
                        params: {storageplaceId: locationId, productId: item.product_id},
                        headers: auth.getAuthHeader()
                      })
                      .then(response => {
                        let stockqty = parseInt(response.data)
                        if (stockqty < qty) {
                          let errorText = "Menge " + qty + " zu groß, maximal " + stockqty + " Stück"
                          document.getElementById('MengeInput').value = ''
                          throw new Error(errorText)
                        } else {
                          values[0] = locationName
                          return qty
                        }
                      })
                      .catch(error => {
                        Swal.showValidationMessage(error)
                      })
              },
              inputValidator: (value) => {
                // value = parseInt(value)
                if (!this.isInt(value)) {
                  document.getElementById('MengeInput').value = ''
                  return 'Keine Zahl eingegeben'
                }
                if (value <= 0) {
                  document.getElementById('MengeInput').value = ''
                  return 'Menge zu klein'
                }
                if (parseInt(value) + item.qty_scanned > item.qty) {
                  let errorText = "Menge " + parseInt(value) + " zu groß (max. " + (item.qty - item.qty_scanned) + ")"
                  document.getElementById('MengeInput').value = ''
                  return errorText
                }
              },
              inputValue: values[currentStep],
              showCancelButton: true,
              currentProgressStep: currentStep,
              validationMessage: 'Bitte Menge eingeben',
              inputPlaceholder: 'zu picken ' + (item.qty - item.qty_scanned) + ' Stück'
            })
          }
          if (result.value) {
            values[currentStep] = result.value
            currentStep++
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            currentStep--
          } else {
            break
          }
        }
        if (currentStep === steps.length) {
          this.checkItem(item, values[1], values[0])
        }
      },
      showSwalReset: function () {
        Swal.fire({
          icon: 'question',
          title: 'Willst du ALLE Artikel erneut picken und bereits gepickte Artikel manuell einlagern? Falls du unsicher bist, brich den Vorgang ab und frag lieber!',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: 'Ja',
          cancelButtonText: 'Nein',
          buttonsStyling: false,
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.reset()
          }
        })
      },
      showSwalImage: function (item) {
        Swal.fire({
          html: '<img src="' + item.image + '" width="100%">',
          width: 600,
          showConfirmButton: false,
          showCloseButton: true,
          customClass: {
            closeButton: 'btn btn-danger btn-fill'
          },
          buttonsStyling: false
        })
      },
      saveshipmentscan () {
        let url = config.routes.commissionscanshipment
        let shipment = JSON.parse(localStorage.getItem('commission_shipment'))
        if (shipment) {
          let loadingInstance = Loading.service({})
          axios
            .post(url, {
              'shipmentScan': {
                'shipmentId': shipment.shipment_id,
                'shipmentIncrementId': shipment.increment_id,
                  // "duration": 0,
                'packer': localStorage.getItem('username'),
                'timetype': 1,
                'ordervalue': shipment.order_value,
                'storeId': shipment.store_id
              }
            },
              { headers: auth.getAuthHeader() }
            )
            .then(response => {
              localStorage.setItem('commission_shipmentscan_id', JSON.stringify(response.data.shipmentscan_id))
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      reset () {
        this.resetshipment()
        this.saveshipmentcomment('Kommissionierung zurückgesetzt')
        for (let i = 0; i < this.items.length; i++) {
          this.items[i].qty_scanned = 0
          this.items[i].checkedComplete = false
        }
        localStorage.setItem('commission_scanItems', JSON.stringify(this.items))
        // this.comment = ''
        this.shipment.history = []
        // localStorage.setItem('commission_shipment', JSON.stringify(this.shipment))
        localStorage.removeItem('commission_scanItems')
        localStorage.removeItem('commission_shipment')
        localStorage.removeItem('commission_shipmentId')
        localStorage.removeItem('commission_shipmentscan_id')
        this.$router.push({ name: 'Picken' }).catch(() => {})
      },
      cancel () {
        console.log('cancel')
        // this.saveshipmentcomment('Kommissionierung Abgebrochen')
        localStorage.removeItem('commission_scanItems')
        localStorage.removeItem('commission_shipmentId')
        localStorage.removeItem('commission_shipment')
        this.$router.push({ name: 'Picken' }).catch(() => {})
      },
      savearticlescan (sku, qty, storageplace) {
        let url = config.routes.commissionscanarticle
        let shipment = JSON.parse(localStorage.getItem('commission_shipment'))
        if (shipment) {
          let loadingInstance = Loading.service({})
          axios
            .post(url, {
              'articleScan': {
                'shipmentId': shipment.shipment_id,
                'shipmentIncrementId': shipment.increment_id,
                'packer': localStorage.getItem('username'),
                'sku': sku,
                'qty': qty,
                'storageplace': storageplace
              }
            },
              { headers: auth.getAuthHeader() }
            )
            .then(() => {
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      saveshipmentcomment (comment) {
        let url = config.routes.commissionshipmentcomment
        let shipment = JSON.parse(localStorage.getItem('commission_shipment'))
        comment = comment + '<br/>by ' + localStorage.getItem('username')
        if (shipment) {
          let loadingInstance = Loading.service({})
          axios
            .post(url, {
              'shipmentId': shipment.shipment_id,
              'comment': comment
            },
              {headers: auth.getAuthHeader()}
            )
            .then(() => {
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      resetshipment () {
        let shipment = JSON.parse(localStorage.getItem('commission_shipment'))
        if (shipment) {
          let url = config.routes.commissionshipment + '/' + shipment.shipment_id
          let loadingInstance = Loading.service({})
          axios
            .delete(url, {headers: auth.getAuthHeader()})
            .then(() => {
              this.saveshipmentscan()
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      findItem (searchString) {
        let items = this.items
        let f
        let found
        searchString = searchString.toLowerCase()
        found = items.findIndex(function (item, index) {
          f = index
          return item.sku.toLowerCase() === searchString.toLowerCase() ||
            (item.ean && item.ean.toLowerCase() === searchString) ||
            (item.manufacturer_sku && item.manufacturer_sku.toLowerCase() === searchString) ||
            (item.manufacturer_ean && item.manufacturer_ean.toLowerCase() === searchString)
        })
        if (found !== -1) {
          return items[f]
        } else {
          SoundError.play()
          this.$showErrorMessage(this.$notify,
              'Falscher Artikel'
          )
        }
      },
      checkItem (checkItem, qty, storageplace) {
        let items = this.items
        let f
        items.findIndex(function (item, index) { f = index; return item.id === checkItem.id })
        if (items[f].qty_scanned < items[f].qty) {
          items[f].qty_scanned = parseInt(items[f].qty_scanned) + parseInt(qty)
          this.savearticlescan(items[f].sku, qty, storageplace)
          this.saveshipmentcomment('SKU: ' + items[f].sku +
              '<br/>' + qty + ' Stk. von ' + storageplace + '<br/>' +
              'QTY: ' + items[f].qty_scanned + '/' + items[f].qty)
          this.removeQtyFromStock(items[f], storageplace, qty)
          for (let i = 0; i < items[f].parc_storageplace.length; i++) {
            if (items[f].parc_storageplace[i].name == storageplace) {
              items[f].parc_storageplace[i].parc_qty = items[f].parc_storageplace[i].parc_qty - qty
            }
          }
          this.items = items
          if (items[f].qty_scanned === items[f].qty) {
            let newItem = items[f]
            this.items.splice(f, 1)
            this.items.push(newItem)
          }
          SoundOk.play()
          localStorage.setItem('commission_shipment', JSON.stringify(this.shipment))
          localStorage.setItem('commission_scanItems', JSON.stringify(this.items))
          if (this.checkAllDone()) {
            this.finish()
          }
        } else {
          this.$showErrorMessage(this.$notify,
              'Zu viele Artikel'
          )
        }
      },
      checkAllDone () {
        for (let item of this.items) {
          if (item.qty_scanned !== item.qty && item.checkedComplete !== true) {
            return false
          }
        }
        return true
      },
      endshipment () {
        let shipment = JSON.parse(localStorage.getItem('commission_shipment'))
        let shipmentScanId = JSON.parse(localStorage.getItem('commission_shipmentscan_id'))
        let isFirst = this.isFirst
        if (shipment) {
          let url = config.routes.commissionscanshipment + '/' + shipmentScanId
          let loadingInstance = Loading.service({})
          axios
            .put(url, {
              'shipmentScan': {
                'endtime': new Date().toISOString().replace('T', ' ').substr(0, 10) + ' ' + new Date().toLocaleTimeString(),
                'isFirst': isFirst
              }
            }, { headers: auth.getAuthHeader() })
            .then(() => {
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      checkIsFirstShipment () {
        let shipmentId = JSON.parse(localStorage.getItem('commission_shipmentId'))
        let url = config.routes.commissionscanshipment + '/search/?' +
          'searchCriteria[filter_groups][0][filters][0][field]=shipment_increment_id&' +
          'searchCriteria[filter_groups][0][filters][0][value]=' + shipmentId + '&' +
          'searchCriteria[filter_groups][0][filters][0][condition_type]=eq&' +
          'searchCriteria[filter_groups][1][filters][0][field]=timetype&' +
          'searchCriteria[filter_groups][1][filters][0][value]=1&' +
          'searchCriteria[filter_groups][1][filters][0][condition_type]=eq&' +
          'searchCriteria[filter_groups][2][filters][0][field]=is_first&' +
          'searchCriteria[filter_groups][2][filters][0][value]=1&' +
          'searchCriteria[filter_groups][2][filters][0][condition_type]=eq'
        let loadingInstance = Loading.service({})
        return axios
          .get(url, {
            params: {incrementId: shipmentId},
            headers: auth.getAuthHeader()
          })
          .then(response => {
            loadingInstance.close()
            var result = response.data
            if (result.items.length === 0) {
              this.isFirst = 1
            } else {
              this.isFirst = 0
            }
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
      },
      finish () {
        this.checkIsFirstShipment()
            .then(() => {
              let promiseExecution = async () => {
                await Promise.all([this.deleteBoxInfo(), this.endshipment(), this.deleteAllComments(), this.saveshipmentcomment('Kommissionierung vollständig')]).then(function () {
                  console.log('all finished')
                })
                console.log('local storage remove')
                localStorage.removeItem('commission_shipmentscan_id')
                localStorage.removeItem('commission_scanItems')
                localStorage.removeItem('commission_shipment')
                localStorage.removeItem('commission_shipmentId')
                console.log('redirect')
                this.$router.push({ name: 'Picken' }).catch(() => {})
              }
              promiseExecution()
            })
      },
      isInt(value) {
        var er = /^-?[0-9]+$/
        return er.test(value)
      },
      toggleItem: function (item) {
        item.shownumbers = item.shownumbers !== true
      },
      printShipment: function (shipmentId) {
        Swal.fire({
          title: 'Drucker',
          showCancelButton: true,
          cancelButtonText: 'Abbrechen',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill',
          },
          input: 'select',
          inputOptions: config.printplaces,
          inputAttributes: {
            required: true
          },
          reverseButtons: true,
          validationMessage: 'Bitte Drucker auswählen',
          inputPlaceholder: 'Bitte wählen',
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.value) {
            let printer = result.value
            let url = config.routes.printlabel
            let loadingInstance = Loading.service({})
            axios
                .post(url, {
                      'shipmentIncrementId': shipmentId,
                      'boxDimensions': {},
                      'productId': 0,
                      'qty': 1,
                      'printLocation': printer,
                      'attributeCode': ''
                    },
                    {
                      headers: auth.getAuthHeader()
                    })
                .then(response => {
                  if (response.data) {
                    Swal.fire({
                      title: 'Erfolgreich',
                      html: 'Sendung ' + shipmentId + ' gedruckt',
                      width: 400
                    })
                    this.saveshipmentcomment('Sendungslabel wurde gedruckt')
                  }
                  loadingInstance.close()
                })
                .catch(e => {
                  loadingInstance.close()
                  if (e.response.status == 401) {
                    auth.logout()
                  }
                })
          }
        })
      },
      resetComment(item){
        let shipment = JSON.parse(localStorage.getItem('commission_shipment'))
        let shipmentComment = 'Kommissionierungskommentar zum Artikel mit der SKU ' + item.sku + ' wurde von '
            + localStorage.getItem('username') + ' gelöscht'
        let loadingInstance = Loading.service({})
        let url = config.routes.commissionscancomment + '/' + item.commission_scan_comment[0]
        // delete comment from commission scan comments table
        axios
            .delete(url, {
              headers: auth.getAuthHeader()
            })
            .then(() => {
              loadingInstance.close()
              item.commission_scan_comment = []
              // save comment that comment was deleted in Shipment History (Magento Backend)
              axios
                  .post(config.routes.commissionshipmentcomment, {
                        'shipmentId': shipment.shipment_id,
                        'comment': shipmentComment
                      },
                      {headers: auth.getAuthHeader()}
                  )
                  .then(() => {
                    loadingInstance.close()
                  })
                  .catch(e => {
                    loadingInstance.close()
                    if (e.response.status == 401) {
                      auth.logout()
                    }
                  })
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      },
      saveCommissionScanComment (item) {
        if (item.commission_scan_comment[2] !== undefined) {
          item.commission_scan_comment[3] = 1
          let loadingInstance = Loading.service({})
          // save comment in shipment history
          let shipment = JSON.parse(localStorage.getItem('commission_shipment'))
          let shipmentComment = 'Kommissionierung Problem <br/>' + 'Kommentar: ' + item.commission_scan_comment[2]
              + '<br/>Artikel SKU: ' + item.sku + '<br/>'
              + 'by ' + localStorage.getItem('username')
          axios
              .post(config.routes.commissionshipmentcomment, {
                    'shipmentId': shipment.shipment_id,
                    'comment': shipmentComment
                  },
                  {headers: auth.getAuthHeader()}
              )
              .then(() => {
                loadingInstance.close()
                // save comment in commission comments
                axios
                    .post(config.routes.commissionscancomment, {
                          'scanComment': {
                            'shipmentId': shipment.shipment_id,
                            'shipmentIncrementId': shipment.increment_id,
                            'comment': item.commission_scan_comment[2],
                            'packer': localStorage.getItem('username'),
                            'problem': item.commission_scan_comment[3],
                            'shipmentItemId': item.id
                          }
                        },
                        {headers: auth.getAuthHeader()}
                    )
                    .then(response => {
                      item.commission_scan_comment[0] = response.data.scancomment_id
                      item.commission_scan_comment[1] = localStorage.getItem('username')
                      localStorage.setItem('commission_scanItems', JSON.stringify(this.items))
                      location.reload()
                    })
              })
              .catch(e => {
                loadingInstance.close()
                if (e.response.status == 401) {
                  auth.logout()
                }
              })
        }
      },
      removeQtyFromStock (item, storagePlaceName, qty) {
        let productId = item.product_id
        let loadingInstance = Loading.service({})
        axios
            .post(config.routes.commissionremoveqtyfromstock, {
              'productId': productId,
              'storagePlaceName': storagePlaceName,
              'qty': qty
              },
                {headers: auth.getAuthHeader()
            })
            .then(() => {
                loadingInstance.close()
              })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      },
      showSwalComment: function (item) {
        let inputValue = ''
        let verfasser = ''
        if (item.commission_scan_comment[2] !== undefined){
          inputValue = item.commission_scan_comment[2]
          verfasser = 'von ' + item.commission_scan_comment[1]
        }

        Swal.fire({
          title: 'Problem',
          text: verfasser,
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: 'Speichern',
          cancelButtonText: 'Löschen',
          input: 'textarea',
          inputValue: inputValue,
          inputPlaceholder: 'Kommentar',
          buttonsStyling: false,
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            item.commission_scan_comment[2] = result.value
            this.saveCommissionScanComment(item)
          } else if (result.dismiss === 'cancel') {
            this.resetComment(item)
          }
        })
      },
      reload (shipmentId) {
        let url = config.routes.commissionshipment
        if (shipmentId) {
          localStorage.setItem('commission_shipment', JSON.stringify(this.shipment))
          let loadingInstance = Loading.service({})
          axios
              .get(url, {
                params: {incrementId: shipmentId},
                headers: auth.getAuthHeader()
              })
              .then(response => {
                var items = response.data['items']
                if (items.length) {
                  localStorage.setItem('commission_shipmentId', JSON.stringify(shipmentId))
                  localStorage.setItem('commission_shipment', JSON.stringify(response.data))
                  localStorage.setItem('commission_scanItems', JSON.stringify(items))
                  location.reload()
                }
                loadingInstance.close()
                this.backlink = shipmentId
              })
              .catch(e => {
                loadingInstance.close()
                if (e.response.status == 401) {
                  auth.logout()
                }
                this.$showErrorMessage(this.$notify,
                    'Es wurden keine Sendung für "' + shipmentId + '" gefunden'
                )
                console.error(e)
              })
        }
      },
      deleteAllComments () {
        let items = JSON.parse(localStorage.getItem('commission_scanItems'))
        for (let i = 0; i < items.length; i++) {
          if (items[i].commission_scan_comment[2] !== undefined) {
            this.resetComment(items[i])
          }
        }
      },
      deleteBoxInfo () {
        let shipmentIncrementId = JSON.parse(localStorage.getItem('commission_shipmentId'))
        let loadingInstance = Loading.service({})
        axios
            .post(config.routes.deleteboxinfo,
                { 'shipmentIncrementId': shipmentIncrementId },
                { headers: auth.getAuthHeader() }
            )
            .then(() => {
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
              console.error(e)
            })
      }
    },
    created () {
      if (localStorage.getItem('commission_scanItems')) {
        let items = JSON.parse(localStorage.getItem('commission_scanItems'))
        // this.comment = JSON.parse(localStorage.getItem('commission_shipment')).comment
        this.shipment = JSON.parse(localStorage.getItem('commission_shipment'))
        let newitems = []
        this.items.reverse()
        for (let i = 0; i < items.length; i++) {
          items[i].shownumbers = false
          if (items[i].qty_scanned === items[i].qty) {
            newitems.push(items[i])
          } else {
            newitems.unshift(items[i])
          }
        }
        this.items = newitems
        this.items.reverse()
        if (this.checkAllDone()) {
          this.checkIsFirstShipment()
            .then(() => {
              if (this.isFirst) {
                console.log('end shipment')
                this.endshipment()
              }
            })
        }
        if (this.shipment.commission_possible === false) {
          // this.$showWarningMessage(this.$notify,
          //     'Die Sendung "' + this.shipment.increment_id + '" kann nicht vollständig kommissioniert werden.'
          // )
          Swal.fire({
            icon: 'warning',
            title: 'Die Sendung "' + this.shipment.increment_id + '" kann nicht vollständig kommissioniert werden.',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
              cancelButton: 'btn btn-danger btn-fill'
            },
            confirmButtonText: 'Andere Sendung',
            cancelButtonText: 'Schließen',
            buttonsStyling: false,
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed === true) {
              this.$router.push({name: 'Picken'})
            }
          })
        }
      } else {
        this.$router.push({name: 'Picken'})
      }
      EventBus.$off('scanArticle')
      EventBus.$on('scanArticle', articleId => {
        console.log('input form')
        this.handleScan(articleId)
      })
    },
    mounted () {
    },
    computed: {
      shipmentId () {
        if (localStorage.getItem('commission_shipmentId')) {
          return JSON.parse(localStorage.getItem('commission_shipmentId'))
        } else { return {} }
      }
    },
    destroyed () {
    }
  }
</script>
