<template>
  <div>
    <div class="card">
      <div class="card-content">
        <div class="row">
          <div class="col-lg-12 col-sm-12">
            <h4 class="red">Bitte Auffälligkeiten und Anmerkungen hier eingeben</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <div class="col-sm-12">
              <textarea v-focus v-model="comment" class="form-control" placeholder="" rows="3"
                        tabindex="1"></textarea><br/><br/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6">
            <button ref="checkbutton" type="button" class="btn btn-md btn-warning btn-fill" @click="stepBack()"
                    tabindex="3">
                <span class="btn-label">
                    <i class="ti-angle-left"></i>
                </span>
              Zurück
            </button>

          </div>
          <div class="col-xs-6 text-right">

            <button ref="checkbutton" type="button" class="btn btn-md btn-success btn-fill" @click="save()"
                    tabindex="2">
              Speichern
              <span class="btn-label">
                    <i class="ti-check"></i>
                </span>
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from '@/config'
import {Loading} from 'element-ui'
import axios from 'axios'
import auth from '@/auth'

export default {
  data () {
    return {
      comment: ''
    }
  },
  methods: {
    stepBack () {
      this.$router.go(-1)
    },
    save () {
      let rma = JSON.parse(localStorage.getItem('rma'))
      rma.rma_private_comments = this.comment
      localStorage.setItem('rma', JSON.stringify(rma))
      this.saveRmaComment(this.comment)
      this.stepBack()
    },
    saveRmaComment (comment) {
      let rma = JSON.parse(localStorage.getItem('rma'))
      if (rma) {
        let url = config.routes.rma + '/' + rma.rma_id
        let loadingInstance = Loading.service({})
        axios
          .put(url, {
            'rma': {
              'rma_private_comments': comment
            }
          },
            {headers: auth.getAuthHeader()}
          )
          .then(() => {
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
      }
    }
  },
  mounted () {
    if (localStorage.getItem('rma')) {
      this.comment = JSON.parse(localStorage.getItem('rma')).rma_private_comments
    }
  }
}
</script>
