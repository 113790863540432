<template>
  <div>
    <nav class="navbar navbar-transparent navbar-absolute">
      <div class="container">
        <div class="navbar-header">
          <router-link class="navbar-brand" to="/">Revolution APP</router-link>
        </div>
      </div>
    </nav>

    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page" data-color=""
           data-image="<%= BASE_URL %>/img/background.jpg">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                <form @submit.prevent="submitForm">
                  <div class="card" data-background="color" data-color="blue">
                    <div class="card-header">
                      <h3 class="card-title">Login</h3>
                    </div>
                    <div class="card-content">
                      <div class="form-group">
                        <label>Benutzername</label>
                        <input v-model="loginForm.user" type="username" placeholder="Benutzername"
                               class="form-control input-no-border">
                      </div>
                      <div class="form-group">
                        <label>Passwort</label>
                        <input v-model="loginForm.pass" type="password" placeholder="Passwort"
                               class="form-control input-no-border">
                      </div>
                    </div>
                    <div class="card-footer text-center">
                      <button class="btn btn-fill btn-wd ">Anmelden</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <footer class="footer footer-transparent">
          <div class="container">
            <div class="copyright">
              &copy; Coded with
              <i class="fa fa-heart heart"></i>
            </div>
          </div>
        </footer>
        <div class="full-page-background" style="background-image: url(/img/background.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>

import auth from '@/auth.js'

  export default {
    data () {
      return {
        loginForm: {}
      }
    },
    methods: {
      submitForm () {
        auth
          .login(this.loginForm.user, this.loginForm.pass)
          .catch(() => {
            this.$showErrorMessage(this.$notify, 'Login nicht erfolgreich')
          })
    },
  },
}
</script>
<style>
</style>
