let config = {
  api_base_url: process.env.VUE_APP_ROOT_API,
  use_local_json: true
}

config.prio = {
  key: {
    high: '1',
    medium: '2',
    low: '3'
  },
  text: {
    high: 'hoch',
    medium: 'mittel',
    low: 'niedrig'
  }
}

config.endSequence = '0000000'
// config.endSequence = '99',
config.printplaces =  {
  '': 'nicht drucken',
    'drucker1': 'Drucker 1',
    'drucker2': 'Drucker 2',
    'drucker3': 'Drucker 3',
    'drucker4': 'Drucker 4',
    'drucker5': 'Drucker 5',
    // 'drucker6': 'Drucker 6',
    // 'drucker7': 'Drucker 7',
}
config.pickWagen =  {
  'WAGEN1': 'Wagen 1',
  'WAGEN2': 'Wagen 2',
  'WAGEN3': 'Wagen 3',
  'WAGEN4': 'Wagen 4',
  'WAGEN5': 'Wagen 5',
  'WAGEN6': 'Wagen 6',
  'WAGEN7': 'Wagen 7',
  'WAGEN8': 'Wagen 8',
  'WAGEN9': 'Wagen 9',
  'WAGEN10': 'Wagen 10',
}

if (config.use_local_json) {
  config.routes = {
    login: config.api_base_url + 'integration/admin/token',
    loaduserdata: config.api_base_url + 'parc-storageapp/loaduserdata',
    loadpermissions: config.api_base_url + 'parc-storageapp/loadpermissions',
    freelocations: config.api_base_url + 'parc-storageapp/storageplace/additional',
    product: config.api_base_url + 'parc-storageapp/product',
    manufacturer_product: config.api_base_url + 'parc-storageapp/manufacturer_product',
    search: config.api_base_url + 'parc-storageapp/search',
    getlocation: config.api_base_url + 'parc-storageapp/storageplace/additional',
    addproduct: config.api_base_url + 'parc-storageapp/addtostorage',
    updateproduct: config.api_base_url + 'parc-storageapp/setstorage',
    removeproduct: config.api_base_url + 'parc-storageapp/removefromstorage',
    changeproduct: config.api_base_url + 'parc-storageapp/changestorage',
    updatestock: config.api_base_url + 'parc-storageapp/updatestock',
    productcheck: config.api_base_url + 'parc-storageapp/storageplaceproductcheck',
    productlocationcheck: config.api_base_url + 'parc-storageapp/storageplaceproduct/check',
    productlocationqty: config.api_base_url + 'parc-storageapp/storageplaceproduct/qty',
    productstocheck: config.api_base_url + 'parc-storageapp/productstocheck',
    getnotes: config.api_base_url + 'parc-storageapp/note/get',
    createnote: config.api_base_url + 'parc-storageapp/note/create',
    deletenote: config.api_base_url + 'parc-storageapp/note/delete',
    updatenote: config.api_base_url + 'parc-storageapp/note/update',
    resolvenote: config.api_base_url + 'parc-storageapp/note/resolve',
    unresolvenote: config.api_base_url + 'parc-storageapp/note/unresolve',
    addimage: config.api_base_url + 'parc-storageapp/note/addimage',
    deleteimage: config.api_base_url + 'parc-storageapp/note/deleteimage',
    editwarenpostattribute : config.api_base_url + 'parc-storageapp/editwarenpostattribute',
    editshippingnote: config.api_base_url + 'parc-storageapp/editshippingnote',
    // Pack Urls
    shipment: config.api_base_url + 'parc-storageapp/shipment',
    scanshipment: config.api_base_url + 'parc-storageapp/shipmentscan',
    shipmentcomment: config.api_base_url + 'parc-storageapp/shipmentcomment',
    scancomment: config.api_base_url + 'parc-storageapp/scancomment',
    scanarticle: config.api_base_url + 'parc-storageapp/articlescan',
    lvitems: config.api_base_url + 'parc-storageapp/boxproducts',
    boxes: config.api_base_url + 'parc-storageapp/box',
    shipmentstats: config.api_base_url + 'parc-storageapp/shipmentstats',
    shipmentstatsusers: config.api_base_url + 'parc-storageapp/shipmentstatsusers',
    shipmentStatus: config.api_base_url + 'parc-storageapp/shipmentstatus',
    eucountry: config.api_base_url + 'parc-storageapp/eucountry',
    // Pick
    commissionshipment: config.api_base_url + 'parc-storageapp/commissionshipment',
    commissionscanshipment: config.api_base_url + 'parc-storageapp/commissionshipmentscan',
    commissionshipmentcomment: config.api_base_url + 'parc-storageapp/commissionshipmentcomment',
    commissionscancomment: config.api_base_url + 'parc-storageapp/commissionscancomment',
    commissionscanarticle: config.api_base_url + 'parc-storageapp/commissionarticlescan',
    commissionshipmentstats: config.api_base_url + 'parc-storageapp/commissionshipmentstats',
    commissionshipmentstatsusers: config.api_base_url + 'parc-storageapp/commissionshipmentstatsusers',
    commissionremoveqtyfromstock: config.api_base_url + 'parc-storageapp/commissionremoveqtyfromstock',
    getpicklists: config.api_base_url + 'parc-storageapp/picklists',
    getBox: config.api_base_url + 'parc-storageapp/commission/box',
    boxesToPack: config.api_base_url + 'parc-storageapp/commissionedboxes',
    groupPicking: config.api_base_url + 'parc-storageapp/grouppicking',
    groupPickingByPicklist: config.api_base_url + 'parc-storageapp/grouppickingbypicklist',
    sortTrolley: config.api_base_url + 'parc-storageapp/sorttrolley',
    sortProductInBox : config.api_base_url + 'parc-storageapp/sortproductinbox',
    othershipmentsinbox : config.api_base_url + 'parc-storageapp/othershipmentsinbox',
    currentpicker: config.api_base_url + 'parc-storageapp/currentpicker',
    getpossiblepicklists : config.api_base_url + 'parc-storageapp/getpossiblepicklists',
    deleteboxinfo: config.api_base_url + 'parc-storageapp/deleteboxinfo',
    // Print
    processlabel: config.api_base_url + 'parc-storageapp/processlabel',
    processshipment: config.api_base_url + 'parc-storageapp/processshipment',
    printinvoice: config.api_base_url + 'parc-storageapp/printinvoice',
    createinvoice: config.api_base_url + 'parc-storageapp/createinvoice',
    printshipmentstats: config.api_base_url + 'parc-storageapp/printshipmentstats',
    track: config.api_base_url + 'shipment/track',
    carriers: config.api_base_url + 'parc-storageapp/carriers',
    importshippingnumbers: config.api_base_url + 'parc-storageapp/uploadshippingnumbers',
    // Returns
    rmasearchorder: config.api_base_url + 'parc-storageapp/rma/search',
    rmasaveitems: config.api_base_url + 'parc-storageapp/rma/saveitems',
    rmareset: config.api_base_url + 'parc-storageapp/rma/reset',
    rmastats: config.api_base_url + 'parc-storageapp/rma/stats',
    rmacreate: config.api_base_url + 'parc-storageapp/rma/create',
    rma: config.api_base_url + 'parc-storageapp/rma',
    // Donbot
    donbot: config.api_base_url + 'parc-storageapp/donbot/calculate',
    countries: config.api_base_url + 'directory/countries',
    // all
    printlabel: config.api_base_url + 'parc-storageapp/printlabel'

  }
} else {
  config.routes = {

  }
}

export default config
