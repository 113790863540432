<template>
  <div>
    <div class="row" v-if="items.length">
      <div class="col-xs-10">
        <h4>Pickliste {{ picklistId }}</h4>
        <h5>{{ shipmentCount }} Sendungen</h5>
      </div>
      <div class="col-xs-2">
        <span v-for="(item, index) in items" :key="index">
          <span class="pull-right icon-problem" v-if="item.commission_scan_comment[2]">
            <i class="icon-danger ti-alert"></i>
          </span>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 text-left">
        <el-select class="select-success" @change="setWagen()"
                   placeholder="Wagen"
                   size="mini"
                   v-model="pickWagen">
          <el-option v-for="(option, index) in config.pickWagen"
                     class="select-success"
                     :value="index"
                     :label="option"
                     :key="index">
          </el-option>
        </el-select>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-6 col-sm-6" v-for="(item, index) in items" :key="index">
        <shipmentitem-card :class="item.qty_scanned == item.qty || item.checkedComplete == true ? 'finished' : 'false' " :linkto="{ name: 'Produkt', params: { productid: item.product_id}}">
          <div class="text-center" :class="`icon-info`" slot="header">
            <table class="storageLocationTable">
              <tr class="label-parc2" v-for="(location, index) in item.parc_storageplace" :key="index">
                <td>{{ location.name }}</td>
                <td><i class="ti-arrow-right"></i></td>
                <td>{{ location.parc_qty }} Stück</td>
              </tr>
            </table>
            <div class="image-container">
              <img :src="item.image" @click="showSwalImage(item)" @error="item.image = '/img/placeholder.jpg'" />
              <span v-if="item.commission_scan_comment[2]" @click="showSwalImage(item)">
                <i class="watermark-icon icon-danger ti-alert"></i>
              </span>
            </div>
          </div>
          <div class="numbers" slot="content">
            <button ref="checkbutton" :disabled="item.qty_scanned == item.qty || item.checkedComplete ? 'disabled' : false" type="button" class="btn btn-sm btn-success btn-fill pick-button" @click="showSwal(item)">
              zu picken <span class="qty-scanned">{{item.qty - item.qty_scanned}}</span> Stück
            </button>
            <div v-if="hasMoreProblems(item)" style="color:red">ACHTUNG! Zu dieser Sendung existieren Probleme!</div><br/>
            <span>{{item.name}}</span><br/>
          </div>
          <div class="buttons" slot="button">
            <button ref="checkbutton" type="button" class="btn btn-lg btn-simple pull-left"
                    @click="toggleItem(item)">
              <span class="btn-label">
                  <i class="ti-more-alt"></i>
              </span>
            </button>
            <button ref="checkbutton" type="button" class="btn btn-md btn-fill print-button"
                    @click="printShipment(item.increment_id)">
              <span class="btn-label">
                  <i class="ti-printer"></i>
              </span>
            </button>
            <button ref="checkbutton" type="button" class="btn btn-md btn-danger btn-fill pull-right"
                    @click="showSwalComment(item)">
              <span class="btn-label">
                <i class="fa fa-comment-dots fa-xl"></i>
              </span>
            </button>
          </div>
          <div slot="footer">
            <br/>
            <div v-if="item.shownumbers">
              <div class="col-xs-12">
                <table class="table table-sm table-striped">
                  <tbody>
                  <tr>
                    <td>Sendung</td>
                    <th>
                      {{ item.increment_id }}
                    </th>
                  </tr>
                  <tr>
                    <td>insg. benötigt</td>
                    <th>{{ item.qty }} Stück</th>
                  </tr>
                  <tr v-if="item.manufacturer_sku">
                    <td>Man. SKU</td>
                    <th>
                      <router-link :to="{ name: 'Produkt', params: { productid: item.product_id}}" target="_blank">
                        {{item.manufacturer_sku}}
                      </router-link>
                    </th>
                  </tr>
                  <tr v-if="item.manufacturer_ean">
                    <td>Man. EAN</td>
                    <th>{{item.manufacturer_ean}}</th>
                  </tr>
                  <tr>
                    <td>SKU</td>
                    <th>{{item.sku}}</th>
                  </tr>
                  <tr v-if="item.ean">
                    <td>EAN</td>
                    <th>{{item.ean}}</th>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </shipmentitem-card>
      </div>
    </div>
    <div class="row" v-if="!shipment.is_packed">
      <div class="col-lg-12 col-sm-12 text-center" >
<!--        <button ref="checkbutton" type="button" class="btn btn-md btn-danger btn-fill pull-left" tabindex="99" @click="showSwalReset(shipmentId)">-->
<!--          &lt;!&ndash;                <span class="btn-label">&ndash;&gt;-->
<!--          &lt;!&ndash;                    <i class="ti-back-left"></i>&ndash;&gt;-->
<!--          &lt;!&ndash;                </span>&ndash;&gt;-->
<!--          Reset-->
<!--        </button>-->
        <button ref="checkbutton" type="button" class="btn btn-md btn-success btn-fill pull-right" tabindex="99" @click="getGroupPicking(picklistId)">
                <span class="btn-label">
                    <i class="fa fa-rotate"></i>
                </span>
          neu laden
        </button>
        <br/><br/>
      </div>
    </div>

  </div>
</template>
<script>
import {SoundOk} from '@/main.js'
import config from '@/config.js'
import ShipmentitemCard from '@/components/Cards/ShipmentItemCard'
import Swal from 'sweetalert2'
import {Loading} from 'element-ui'
import axios from 'axios'
import auth from '@/auth'

export default {
  components: {
    ShipmentitemCard
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data () {
    return {
      shippingid: '',
      articleid: '',
      locationId: '',
      items: [],
      loading: false,
      shipment: '',
      isFirst: 0,
      pickWagen: '',
      pickWagenObject: ''
    }
  },
  methods: {
    showSwal: async function storein(item) {
      if (!localStorage.getItem('pick_wagen')) {
        this.$showErrorMessage(this.$notify,
            'kein Pick Wagen ausgewählt'
        )
      } else {
        const steps = ['1', '2']
        const values = ['', '']
        let currentStep
        const swalQueue = Swal.mixin({
          progressSteps: steps,
          confirmButtonText: 'Weiter',
          cancelButtonText: 'Zurück',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          reverseButtons: true,
          allowOutsideClick: true,
          backdrop: true
        })
        for (currentStep = 0; currentStep < steps.length;) {
          let result = ''
          if (currentStep === 0) {
            let keyboard = localStorage.getItem('keyboard')
            result = await swalQueue.fire({
              title: `Lagerplatz`,
              inputValue: values[currentStep],
              showCancelButton: currentStep > 0,
              currentProgressStep: currentStep,
              input: 'text',
              returnInputValueOnDeny: false,
              inputAttributes: {
                required: true,
                inputmode: keyboard,
                id: 'LagerplatzInput',
                autocomplete: 'off'
              },
              preConfirm: (location) => {
                return axios
                    .get(config.routes.getlocation, {
                      params: {name: location},
                      headers: auth.getAuthHeader()
                    })
                    .then(response => {
                      if (response.data.length === 1) {
                        return response.data[0]
                      } else {
                        let errorText = "Lagerplatz " + location + " nicht gefunden"
                        document.getElementById('LagerplatzInput').value = ''
                        throw new Error(errorText)
                      }
                    })
                    .then(location => {
                      // abfragen ob product/location schon vergeben ist
                      return axios
                          .get(config.routes.productlocationcheck, {
                            params: {storageplaceId: location.id, productId: item.product_id},
                            headers: auth.getAuthHeader()
                          })
                          .then(response => {
                            if (response.data === false) {
                              let errorText = "Lagerplatz " + location.name + " nicht zugewiesen"
                              document.getElementById('LagerplatzInput').value = ''
                              throw new Error(errorText)
                            } else {
                              return location
                            }
                          })
                          .catch(error => {
                            Swal.showValidationMessage(error)
                          })
                    })
                    .catch(error => {
                      Swal.showValidationMessage(error)
                    })
              },
              allowOutsideClick: true,
              reverseButtons: true,
              validationMessage: 'Bitte Lagerplatz eingeben',
              inputPlaceholder: 'Lagerplatz'
            })
          }
          if (currentStep === 1) {
            let keyboard = localStorage.getItem('keyboard')
            result = await swalQueue.fire({
              title: `Menge`,
              input: 'number',
              inputAttributes: {
                required: true,
                inputmode: keyboard,
                id: 'MengeInput',
                autocomplete: 'off'
              },
              preConfirm: (qty) => {
                let locationId = values[0].id
                let locationName = values[0].name
                return axios
                    .get(config.routes.productlocationqty, {
                      params: {storageplaceId: locationId, productId: item.product_id},
                      headers: auth.getAuthHeader()
                    })
                    .then(response => {
                      let stockqty = parseInt(response.data)
                      if (stockqty < qty) {
                        let errorText = "Menge " + qty + " zu groß, maximal " + stockqty + " Stück"
                        document.getElementById('MengeInput').value = ''
                        throw new Error(errorText)
                      } else {
                        values[0] = locationName
                        return qty
                      }
                    })
                    .catch(error => {
                      Swal.showValidationMessage(error)
                    })
              },
              inputValidator: (value) => {
                // value = parseInt(value)
                if (!this.isInt(value)) {
                  document.getElementById('MengeInput').value = ''
                  return 'Keine Zahl eingegeben'
                }
                if (value <= 0) {
                  document.getElementById('MengeInput').value = ''
                  return 'Menge zu klein'
                }
                if (parseInt(value) + item.qty_scanned > item.qty) {
                  let errorText = "Menge " + parseInt(value) + " zu groß (max. " + (item.qty - item.qty_scanned) + ")"
                  document.getElementById('MengeInput').value = ''
                  return errorText
                }
              },
              inputValue: values[currentStep],
              showCancelButton: true,
              currentProgressStep: currentStep,
              validationMessage: 'Bitte Menge eingeben',
              inputPlaceholder: 'zu picken ' + (item.qty - item.qty_scanned) + ' Stück'
            })
          }
          if (result.value) {
            values[currentStep] = result.value
            currentStep++
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            currentStep--
          } else {
            break
          }
        }
        if (currentStep === steps.length) {
          this.checkItem(item, values[1], values[0], this.pickWagenObject)
        }
      }
    },
    showSwalImage: function (item) {
      Swal.fire({
        html: '<img src="' + item.image + '" width="100%">',
        width: 600,
        showConfirmButton: false,
        showCloseButton: true,
        customClass: {
          closeButton: 'btn btn-danger btn-fill'
        },
        buttonsStyling: false
      })
    },
    savearticlescan (item, qty, storageplace, boxId) {
      let url = config.routes.commissionscanarticle
      let loadingInstance = Loading.service({})
      axios
          .post(url, {
                'articleScan': {
                  'shipmentId': item.shipment_id,
                  'shipmentIncrementId': item.increment_id,
                  'packer': localStorage.getItem('username'),
                  'sku': item.sku,
                  'qty': qty,
                  'storageplace': storageplace,
                  'boxId': boxId
                }
              },
              { headers: auth.getAuthHeader() }
          )
          .then(() => {
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
    },
    saveshipmentcomment (item, comment) {
      let url = config.routes.commissionshipmentcomment
      comment = comment + '<br/>by ' + localStorage.getItem('username')
      if (item) {
        let loadingInstance = Loading.service({})
        axios
            .post(url, {
                  'shipmentId': item.shipment_id,
                  'comment': comment
                },
                {headers: auth.getAuthHeader()}
            )
            .then(() => {
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      }
    },
    checkItem (checkItem, qty, storageplace, box) {
      let items = this.items
      let f
      items.findIndex(function (item, index) { f = index; return item.shipment_item_id === checkItem.shipment_item_id })
      if (items[f].qty_scanned < items[f].qty) {
        items[f].qty_scanned = parseInt(items[f].qty_scanned) + parseInt(qty)
        this.savearticlescan(items[f], qty, storageplace, box['box_id'])
        this.saveshipmentcomment(items[f],'SKU: ' + items[f].sku +
            '<br/>' + qty + ' Stk. von ' + storageplace + '<br/>' +
            'in ' + box['box_name'] + '<br/>' +
            'QTY: ' + items[f].qty_scanned + '/' + items[f].qty)
        this.removeQtyFromStock(items[f], storageplace, qty)
        for (let i = 0; i < items[f].parc_storageplace.length; i++) {
          if (items[f].parc_storageplace[i].name == storageplace) {
            items[f].parc_storageplace[i].parc_qty = items[f].parc_storageplace[i].parc_qty - qty
          }
        }
        this.items = items
        if (items[f].qty_scanned === items[f].qty) {
          let newItem = items[f]
          this.items.splice(f, 1)
          this.items.push(newItem)
        }
        let groupPicking = {}
        groupPicking.items = this.items
        groupPicking.picklist_id = this.picklistId

        let groupPickingBoxes = {}
        if (localStorage.getItem('group_picking_boxes')) {
          groupPickingBoxes = JSON.parse(localStorage.getItem('group_picking_boxes'))
          let newShipmentId = checkItem.shipment_id
          let newBox = box['box_name']
          if (!groupPickingBoxes[newShipmentId]) {
            groupPickingBoxes[newShipmentId] = { shipment_id: newShipmentId, boxes: [] };
          }
          if (!groupPickingBoxes[newShipmentId].boxes.includes(newBox)) {
            // Add the new box to the boxes array of the new shipment_id
            groupPickingBoxes[newShipmentId].boxes.push(newBox);
          }
        } else {
          groupPickingBoxes[checkItem.shipment_id] = { shipment_id: checkItem.shipment_id, boxes: [] };
          groupPickingBoxes[checkItem.shipment_id].boxes.push(box);
        }
        localStorage.setItem('group_picking_boxes',  JSON.stringify(groupPickingBoxes))
        console.log(groupPickingBoxes)


        SoundOk.play()
        localStorage.setItem('group_picking', JSON.stringify(groupPicking))
        localStorage.setItem('commission_scanItems', JSON.stringify(this.items))
        if (this.checkAllDone()) {
          this.finish()
        }
      } else {
        this.$showErrorMessage(this.$notify,
            'Zu viele Artikel'
        )
      }
    },
    checkAllDone () {
      for (let item of this.items) {
        if (item.qty_scanned !== item.qty && item.checkedComplete !== true) {
          return false
        }
      }
      return true
    },
    finish () {
      let promiseExecution = async () => {
        await Promise.all([this.deleteAllComments(), this.saveshipmentcomment('Kommissionierung vollständig')]).then(function () {
          // this.removeFromPicklistShipmentList()
          console.log('all finished')
        })
        console.log('local storage remove')
        localStorage.removeItem('group_picking')
        localStorage.removeItem('commission_scanItems')
        localStorage.removeItem('group_picking_boxes')
        console.log('redirect')
        this.$router.push({ name: 'Picken' }).catch(() => {})
      }
      promiseExecution()
    },
    isInt(value) {
      var er = /^-?[0-9]+$/
      return er.test(value)
    },
    toggleItem: function (item) {
      item.shownumbers = item.shownumbers !== true
    },
    printShipment: function (shipmentId) {
      Swal.fire({
        title: 'Drucker',
        showCancelButton: true,
        cancelButtonText: 'Abbrechen',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        input: 'select',
        inputOptions: config.printplaces,
        inputAttributes: {
          required: true
        },
        reverseButtons: true,
        validationMessage: 'Bitte Drucker auswählen',
        inputPlaceholder: 'Bitte wählen',
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.value) {
          let printer = result.value
          let url = config.routes.printlabel
          let loadingInstance = Loading.service({})
          axios
              .post(url, {
                    'shipmentIncrementId': shipmentId,
                    'boxDimensions': {},
                    'productId': 0,
                    'qty': 1,
                    'printLocation': printer,
                    'attributeCode': ''
                  },
                  {
                    headers: auth.getAuthHeader()
                  })
              .then(response => {
                if (response.data) {
                  Swal.fire({
                    title: 'Erfolgreich',
                    html: 'Sendung ' + shipmentId + ' gedruckt',
                    width: 400
                  })
                  this.saveshipmentcomment('Sendungslabel wurde gedruckt')
                }
                loadingInstance.close()
              })
              .catch(e => {
                loadingInstance.close()
                if (e.response.status == 401) {
                  auth.logout()
                }
              })
        }
      })
    },
    resetComment(item){
      let shipmentComment = 'Kommissionierungskommentar zum Artikel mit der SKU ' + item.sku + ' wurde von '
          + localStorage.getItem('username') + ' gelöscht'
      let loadingInstance = Loading.service({})
      let url = config.routes.commissionscancomment + '/' + item.commission_scan_comment[0]
      // delete comment from commission scan comments table
      axios
          .delete(url, {
            headers: auth.getAuthHeader()
          })
          .then(() => {
            loadingInstance.close()
            item.commission_scan_comment = []
            // save comment that comment was deleted in Shipment History (Magento Backend)
            axios
                .post(config.routes.commissionshipmentcomment, {
                      'shipmentId': item.shipment_id,
                      'comment': shipmentComment
                    },
                    {headers: auth.getAuthHeader()}
                )
                .then(() => {
                  loadingInstance.close()
                })
                .catch(e => {
                  loadingInstance.close()
                  if (e.response.status == 401) {
                    auth.logout()
                  }
                })
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
    },
    saveCommissionScanComment (item) {
      if (item.commission_scan_comment[2] !== undefined) {
        item.commission_scan_comment[3] = 1
        let loadingInstance = Loading.service({})
        // save comment in shipment history
        let shipmentComment = 'Kommissionierung Problem <br/>' + 'Kommentar: ' + item.commission_scan_comment[2]
            + '<br/>Artikel SKU: ' + item.sku + '<br/>'
            + 'by ' + localStorage.getItem('username')
        axios
            .post(config.routes.commissionshipmentcomment, {
                  'shipmentId': item.shipment_id,
                  'comment': shipmentComment
                },
                {headers: auth.getAuthHeader()}
            )
            .then(() => {
              loadingInstance.close()
              // save comment in commission comments
              axios
                  .post(config.routes.commissionscancomment, {
                        'scanComment': {
                          'shipmentId': item.shipment_id,
                          'shipmentIncrementId': item.increment_id,
                          'comment': item.commission_scan_comment[2],
                          'packer': localStorage.getItem('username'),
                          'problem': item.commission_scan_comment[3],
                          'shipmentItemId': item.shipment_item_id
                        }
                      },
                      {headers: auth.getAuthHeader()}
                  )
                  .then(response => {
                    item.commission_scan_comment[0] = response.data.scancomment_id
                    item.commission_scan_comment[1] = localStorage.getItem('username')
                    localStorage.setItem('commission_scanItems', JSON.stringify(this.items))
                    let groupPicking = {}
                    groupPicking.items = this.items
                    groupPicking.picklist_id = this.picklistId
                    localStorage.setItem('group_picking', JSON.stringify(groupPicking))
                    location.reload()
                  })
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      }
    },
    removeQtyFromStock (item, storagePlaceName, qty) {
      let productId = item.product_id
      let loadingInstance = Loading.service({})
      axios
          .post(config.routes.commissionremoveqtyfromstock, {
                'productId': productId,
                'storagePlaceName': storagePlaceName,
                'qty': qty
              },
              {headers: auth.getAuthHeader()
              })
          .then(() => {
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
    },
    showSwalComment: function (item) {
      let inputValue = ''
      let verfasser = ''
      if (item.commission_scan_comment[2] !== undefined){
        inputValue = item.commission_scan_comment[2]
        verfasser = 'von ' + item.commission_scan_comment[1]
      }

      Swal.fire({
        title: 'Problem',
        text: verfasser,
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Speichern',
        cancelButtonText: 'Löschen',
        input: 'textarea',
        inputValue: inputValue,
        inputPlaceholder: 'Kommentar',
        buttonsStyling: false,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          item.commission_scan_comment[2] = result.value
          this.saveCommissionScanComment(item)
        } else if (result.dismiss === 'cancel') {
          this.resetComment(item)
        }
      })
    },
    deleteAllComments () {
      let items = JSON.parse(localStorage.getItem('commission_scanItems'))
      for (let i = 0; i < items.length; i++) {
        if (items[i].commission_scan_comment[2] !== undefined) {
          this.resetComment(items[i])
        }
      }
    },
    getGroupPicking(picklistId) {
      let loadingInstance = Loading.service({})
      axios
          .get(config.routes.groupPickingByPicklist + '/' + picklistId,
              {headers: auth.getAuthHeader()
              })
          .then(response => {
            if (response.data.picklist_id) {
              localStorage.setItem('group_picking', JSON.stringify(response.data))
              location.reload()
            } else {
              this.$showErrorMessage(this.$notify,
                  'Es wurden keine Artikel für Pickliste "' + picklistId + '" gefunden'
              )
            }
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
    },
    setWagen: function () {
      let url = config.routes.getBox  + '/' + this.pickWagen
      let loadingInstance = Loading.service({})
      axios
          .get(url,
              { headers: auth.getAuthHeader() }
          )
          .then(response => {
            if (response.data) {
              this.pickWagenObject = response.data
              localStorage.setItem('pick_wagen', JSON.stringify(this.pickWagen))
            } else {
              this.$showErrorMessage(this.$notify,
                  "Auswahl " + this.pickWagen + " nicht gefunden")
            }
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
    },
    hasMoreProblems(item) {
      const shipmentId = item.shipment_id
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].shipment_id === shipmentId && this.items[i] !== item) {
          if (this.items[i].commission_scan_comment.length > 0) {
            return true
          }
        }
      }
      return false
    },
  },
  created () {
    localStorage.removeItem('pick_wagen')
    if (localStorage.getItem('group_picking')) {
      let groupPicking = JSON.parse(localStorage.getItem('group_picking'))
      this.picklistId = groupPicking.picklist_id
      let items = groupPicking.items
      localStorage.setItem('commission_scanItems', JSON.stringify(items))
      let user = localStorage.getItem('username')
      if (groupPicking.current_picker && groupPicking.current_picker !== user){
          Swal.fire({
            title: '<span style="color:red">GESPERRT!</span>',
            html: '<h4>Die Pickliste ist durch ' + groupPicking.current_picker + ' gesperrt!</h4>' +
                'Klicke nur auf "Ignorieren", wenn du sicher bist, dass die Pickliste von niemand anderem gerade bearbeitet wird!',
            confirmButtonText: 'Ignorieren',
            cancelButtonText: 'Zurück',
            buttonsStyling: false,
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
              cancelButton: 'btn btn-danger btn-fill'
            },
            reverseButtons: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isDismissed) {
              this.$router.push({name: 'Picken'})
            } else if (result.isConfirmed) {
              let url = config.routes.currentpicker
              let loadingInstance = Loading.service({})
              axios
                  .post(url, {
                        'picklistId': this.picklistId,
                        'picker': user
                      },
                      { headers: auth.getAuthHeader() }
                  )
                  .then(() => {
                    loadingInstance.close()
                  })
                  .catch(e => {
                    loadingInstance.close()
                    if (e.response.status == 401) {
                      auth.logout()
                    }
                  })
            }
          })
      } else if (! groupPicking.current_picker) {
        let url = config.routes.currentpicker
        let loadingInstance = Loading.service({})
        axios
            .post(url, {
                  'picklistId': this.picklistId,
                  'picker': user
                },
                { headers: auth.getAuthHeader() }
            )
            .then(() => {
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      }

      let groupPickingBoxes = {}
      let newShipmentId = ''
      let newBox = ''
      for (let i = 0; i < items.length; i++) {
        if (items[i].commission_boxes.length > 0) {
          newShipmentId = items[i].shipment_id
          if (!groupPickingBoxes[newShipmentId]) {
            groupPickingBoxes[newShipmentId] = {shipment_id: newShipmentId, boxes: []};
          }
          for (let f = 0; f < items[i].commission_boxes.length; f++) {
            newBox = items[i].commission_boxes[f]
            if (!groupPickingBoxes[newShipmentId].boxes.includes(newBox)) {
              // Add the new box to the boxes array of the new shipment_id
              groupPickingBoxes[newShipmentId].boxes.push(newBox);
            }
          }
        }
      }
      localStorage.setItem('group_picking_boxes',  JSON.stringify(groupPickingBoxes))

      let finishedItems = []
      let openItems = []
      for (let i = 0; i < items.length; i++) {
        items[i].shownumbers = false
        if (items[i].qty_scanned === items[i].qty) {
          finishedItems.push(items[i])
        } else {
          openItems.push(items[i])
        }
      }
      this.items = openItems.concat(finishedItems);
    } else {
      this.$router.push({name: 'Picken'})
    }
  },
  mounted () {
  },
  computed: {
    config() {
      return config
    },
    shipmentCount() {
      // Create an empty Set to store unique shipment_ids
      const uniqueIds = new Set();

      // Iterate through the items and add each shipment_id to the Set
      this.items.forEach(item => {
        uniqueIds.add(item.shipment_id);
      });

      // Return the size of the Set, which is the count of unique shipment_ids
      return uniqueIds.size;
    }
  },
  destroyed () {
  }
}
</script>
<style>
@media (max-width: 400px) {
  .print-button {
    margin-left: 10%;
  }
}
@media (min-width: 401px) {
  .print-button {
    margin-left: 35%;
  }
}
</style>