<template>
  <div>
    <div class="row">
      <div class="col-md-12 buttons">
        <button type="button" class="btn btn-fill btn-success" @click="showSwalIn()">
          <span class="btn-label">
              <i class="ti-arrow-down"></i> Ein
          </span>
        </button>

        <button type="button" class="btn btn-fill btn-danger" @click="showSwalOut(product)">
          <span class="btn-label">
              <i class="ti-arrow-up"></i> Aus
          </span>
        </button>

        <button ref="notebutton" type="button" class="btn btn-warning btn-fill" @click="showCreateNote()">
                <span class="btn-label">
                    <i class="ti-calendar"></i> Notiz
                </span>
        </button>
        <br/><br/>
      </div>
    </div>

    <div class="row" v-if="product">
      <div class="col-md-12">
        <div class="card">
          <div class="numbers">
            <table class="storageLocationTable">
              <tr class="label-parc2" v-for="(location, index) in product.locations" :key="index">
                <td>{{ location.name }}</td>
                <td><i class="ti-arrow-right"></i></td>
                <td>{{ location.parc_qty }} Stück</td>
              </tr>
            </table>
          </div>
          <div class="card-header-product">
            <h4 class="card-title">{{product.name}}</h4>
          </div>
          <vue-tabs class="card-content">
            <v-tab title="Lieferant">
              <div class="card card-fullwidth">
                <div class="card-content">
                  <div class="table-tasks">
                    <table class="table table-sm table-striped">
                      <tbody>
                      <tr>
                        <th scope="row">SKU</th>
                        <td align="left">{{product.parc_dist_prod_sku}}</td>
                      </tr>
                      <tr>
                        <th scope="row">EAN</th>
                        <td align="left">{{product.parc_dist_prod_ean}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Lieferant</th>
                        <td align="left">{{product.manufacturer_name}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Automatik</th>
                        <td align="left">{{product.skip_availability_check === '1' ? 'nein' : 'ja' }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Letzte Änderung</th>
                        <td align="left"><span v-if="product.manufacturer_history[0]">
                          {{ this.dateFormatTimeDE(product.manufacturer_history[0].created_at) }}</span></td>
                      </tr>
                      <tr>
                        <th scope="row">Auslauf</th>
                        <td align="left">{{product.discontinued_line === '1' ? 'ja' : 'nein' }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Name</th>
                        <td align="left"><a href="#" @click="showHistory('manufacturer_name', product.parc_dist_prod_name)">{{product.parc_dist_prod_name}}</a></td>
                      </tr>
                      <tr>
                        <th scope="row">EK</th>
                        <td align="left"><a href="#" @click="showHistory('cost', formatPrice(product.cost))">{{this.formatPrice(product.cost)}} EUR</a></td>
                      </tr>
                      <tr>
                        <th scope="row">Bestand</th>
                        <td align="left"><a href="#" @click="showHistory('qty', product.parc_manufacturerstock)">{{product.parc_manufacturerstock}}</a></td>
                      </tr>
                      <tr v-if="1 ||product.parc_available_date && 1 || this.isAfterToday(product.parc_available_date)">
                        <th scope="row">WE</th>
                        <td align="left"><a href="#" @click="showHistory('availability_date', dateFormatDE(product.parc_available_date))">{{ this.dateFormatDE(product.parc_available_date) }}</a></td>
                      </tr>
                      <tr>
                        <th scope="row">VPE</th>
                        <td align="left"><a href="#" @click="showHistory('vpe', product.vpe)">{{product.vpe}}</a></td>
                      </tr>
                      <tr>
                        <th scope="row">Umkarton</th>
                        <td align="left"><a href="#" @click="showHistory('umkarton', product.umkarton)">{{product.umkarton}}</a></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer">

                </div>

              </div>
            </v-tab>
            <v-tab title="Intern">
              <div class="card card-fullwidth">
                <div class="card-content">
                  <div class="table-tasks">
                    <table class="table table-sm table-striped">
                      <tbody>
                      <tr>
                        <th scope="row">SKU</th>
                        <td align="left"><a target="_blank" :href="product.url">{{product.sku}}</a>
                          <button type="button" class="btn btn-xs btn-neutral btn-fill ml-10"
                                  @click="showPrintBarcode(product, 'sku')"
                                  v-show="product.sku">
                            <span class="btn-label">
                                <i class="ti-printer"></i>
                            </span>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Offen (St.)</th>
                        <td align="left">{{product.postponed_qty !== false ? product.postponed_qty : '' }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Bestellt</th>
                        <td align="left">
                          <span v-for="(estimated_delivery_qty, index) in product.estimated_delivery_qtys" :key="index">
                            {{estimated_delivery_qty.qty}} St. / {{dateFormatDE(estimated_delivery_qty.eta)}}<br/>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Magento Menge</th>
                        <td align="left">{{product.qty}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Letzter Kauf</th>
                        <td align="left">{{ this.dateFormatDE(product.last_order_date) }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Auf Lager</th>
                        <td align="left">{{product.in_stock === true ? 'ja' : 'nein' }}</td>
                      </tr>
                      <tr>
                        <th scope="row">EAN</th>
                        <td align="left">{{product.parc_cus_ean}}
                          <button type="button" class="btn btn-xs btn-neutral btn-fill ml-10"
                                  @click="showPrintBarcode(product, 'ean')"
                                  v-show="product.parc_cus_ean">
                            <span class="btn-label">
                                <i class="ti-printer"></i>
                            </span>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">FNSKU</th>
                        <td align="left">{{product.parc_fnsku}}
                          <button type="button" class="btn btn-xs btn-neutral btn-fill ml-10"
                                  @click="showPrintBarcode(product, 'parc_fnsku')"
                                  v-show="product.parc_fnsku">
                            <span class="btn-label">
                                <i class="ti-printer"></i>
                            </span>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">ASIN</th>
                        <td align="left">{{product.parc_asin}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Preis (ab)</th>
                        <td align="left">{{this.formatPrice(product.price)}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Erstellt am</th>
                        <td align="left">{{this.dateFormatDE(product.created_at)}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Status</th>
                        <td align="left">{{product.status}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Sichtbarkeit</th>
                        <td align="left">{{product.visibility}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Serienname</th>
                        <td align="left">{{product.parc_product_name}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Marke</th>
                        <td align="left">{{product.parc_brand}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Gewicht (kg)</th>
                        <td align="left">{{this.formatPrice(product.weight)}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Warenpost</th>
                        <td align="left">{{ product.warenpost }}
                          <button type="button" class="btn btn-xs btn-neutral btn-fill ml-10"
                                  @click="editWarenpostAttribute(product)">
                            <span class="btn-label">
                                <i class="ti-pencil"></i>
                            </span>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Verpackungshinweis</th>
                        <td align="left">{{ product.shipping_note }}
                          <button type="button" class="btn btn-xs btn-neutral btn-fill ml-10"
                                  @click="editShippingNote(product, product.shipping_note)">
                            <span class="btn-label">
                                <i class="ti-pencil"></i>
                            </span>
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer">

                </div>

              </div>
            </v-tab>
            <v-tab :title="'Notiz'" v-if="product.notes.length > 0">
              <div class="card card-fullwidth">
                <div class="card-content">

                  <el-table class="table-striped" align="center" :data="product.notes">

                    <el-table-column label="Datum" property="type" sortable>
                      <template slot-scope="props">
                        <router-link :to="{ name: 'Notiz', params: { noteid: props.row.note_id}}">{{dateFormatDE(props.row.created_at)}} <i class="ti-new-window"></i><br/></router-link>
                      </template>

                    </el-table-column>

                    <el-table-column align="center" sortable label="Typ" prop="type" key="props.row.type">
                      <template slot-scope="props">
                        {{props.row.type}}
                      </template>
                    </el-table-column>

                    <el-table-column align="center" label="Kommentar" prop="type" key="props.row.comment">
                      <template slot-scope="props">
                        {{props.row.comment}}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </v-tab>
            <v-tab title="Hist." v-if="product.history.length > 0">

              <div class="card card-fullwidth">
                <div class="card-content">
                  <el-table class="table-striped" :data="product.history" align="center">
                    <el-table-column min-width="60" align="center" label="Datum" prop="created_at" key="props.row.created_at">
                      <template slot-scope="props">{{dateFormatTimeDE(props.row.created_at)}}</template>
                    </el-table-column>
                    <el-table-column label="Benutzer" property="created_by"></el-table-column>
                    <el-table-column min-width="" label="Aktion" property="comment"></el-table-column>
                  </el-table>
                </div>
              </div>

            </v-tab>
          </vue-tabs>

          <div class="card">
            <div class="card-content text-center">
              <img class="product-image" :src="product.product_image" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import config from '@/config.js'
import auth from '@/auth.js'
import moment from 'moment'
import VueTabs from 'vue-nav-tabs'
import Vue from 'vue'
import {Loading} from 'element-ui'
import {Table,TableColumn} from 'element-ui';

Vue.use(VueTabs)
Vue.use(Table);
Vue.use(TableColumn);

export default {
  name: 'product-view',
  data () {
    return {
      product: null,
      location: null,
      location2: null,
      isOpen: false
    }
  },
  methods: {
    showHistory (attributeName, currentValue) {
      let html = ''
      let prevValue = ''
      let history = [...this.product.manufacturer_history]
      history.reverse()
      for (let row of history) {
        if (row[attributeName] !== null) {
          let value = row[attributeName]
          if (value === prevValue) {
            continue
          } else {
            prevValue = value
          }
          if (attributeName === 'availability_date') {
            value = this.dateFormatDE(value)
          }
          if (attributeName === 'cost') {
            value = this.formatPrice(value)
          }
          html = '<tr><td align="left">' + this.dateFormatTimeDE(row['created_at']) + '</td><td align="left">' + value + '</td></tr>' + html
        }
      }
      html = '<div class="history"><table class="table table-sm table-striped"><tbody><tr><th>Datum</th><th>Wert</th></tr>' +
        '<tr><td align="left">jetzt</td><td align="left">' + currentValue + '</td></tr>' + html + '</tbody></table></div>'
      Swal.fire({
        html: html,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
          closeButton: 'btn btn-danger btn-fill'
        },
        focusConfirm: true,
        confirmButtonText: 'OK',
        allowOutsideClick: () => !Swal.isLoading()
      })
    },
    loadProduct () {
      let loadingInstance = Loading.service({})
      axios
        .get(config.routes.product, {
          params: { productId: this.$route.params.productid },
          headers: auth.getAuthHeader()
        })
        .then(response => {
          this.product = response.data[0]
          loadingInstance.close()
        })
        .catch(e => {
          loadingInstance.close()
          if (e.response.status == 401) {
            auth.logout()
          }
        })
    },
    dateFormatDE: (value) => {
      if (value) {
        return moment.utc(value).local().format('DD.MM.YYYY')
      } else {
        return ''
      }
    },
    formatPrice: (value) => {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    dateFormatTimeDE: (value) => {
      return moment.utc(value).local().format('DD.MM.YY HH:mm')
    },
    async showCreateNote () {
      const steps = ['1', '2']
      const values = []
      let currentStep
      const swalQueue = Swal.mixin({
        progressSteps: steps,
        confirmButtonText: 'Weiter',
        cancelButtonText: 'Zurück',
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        reverseButtons: true,
        allowOutsideClick: () => !Swal.isLoading()
      })
      for (currentStep = 0; currentStep < steps.length;) {
        let result = ''
        if (currentStep === 0) {
          result = await swalQueue.fire({
            title: 'Typ?',
            input: 'select',
            inputOptions: {
              '': '- bitte auswählen -',
              'Wareneingang': 'Wareneingang',
              'Rücksendung': 'Rücksendung',
              'Bestellung': 'Bestellung',
              'Storno': 'Storno',
              'Beschädigung': 'Beschädigung',
              'Fehlbestand': 'Fehlbestand',
              'Alternative': 'Alternative',
              'Produktion': 'Produktion',
              'sonstiges': 'sonstiges'
            },
            preConfirm: (input) => {
              if (!input) {
                Swal.showValidationMessage('Bitte auswählen')
                return false
              }
              return input
            }
          })
        }

        if (currentStep === 1) {
          result = await swalQueue.fire({
              title: 'Kommentar',
              input: 'textarea',
              cancelButtonText: 'Zurück',
              preConfirm: (input) => {
                return input
              }
            }
          )
        }

        if (result.value) {
          values[currentStep] = result.value
          currentStep++
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          currentStep--
        } else {
          break
        }
      }

      if (currentStep === steps.length) {

        let loadingInstance = Loading.service({})
        axios
            .post(config.routes.createnote, {
                  'productId': this.product.entity_id,
                  'prio': 0,
                  'type': values[0],
                  'comment': values[1]
                },
                {headers: auth.getAuthHeader()}
            )
            .then(response => {
              this.$refs.notebutton.disabled = false
              this.lastNoteId = response.data
              this.loadProduct()
            })
            .catch(e => {
              if (e.response.status == 401) {
                auth.logout()
              }
              loadingInstance.close()
            })
      }
    },
    showSwalIn: async function storein () {
      const steps = ['1', '2', '3']
      const values = []
      let currentStep
      const swalQueue = Swal.mixin({
        progressSteps: steps,
        confirmButtonText: 'Weiter',
        cancelButtonText: 'Zurück',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        reverseButtons: true,
        allowOutsideClick: () => !Swal.isLoading()
      })
      for (currentStep = 0; currentStep < steps.length;) {
        let result = ''
        if (currentStep === 0) {
          result = await swalQueue.fire({
            title: `Grund`,
            inputValue: values[currentStep],
            showCancelButton: true,
            cancelButtonText: 'Abbrechen',
            currentProgressStep: currentStep,
            input: 'select',
            inputOptions: this.storeInReasons,
            inputAttributes: {
              required: true
            },
            reverseButtons: true,
            validationMessage: 'Bitte Grund auswählen',
            inputPlaceholder: 'Bitte wählen'
          })
        }
        if (currentStep === 1) {
          let keyboard = localStorage.getItem('keyboard')
          result = await swalQueue.fire({
            title: `Lagerplatz`,
            inputValue: values[currentStep],
            showCancelButton: currentStep > 0,
            currentProgressStep: currentStep,
            input: 'text',
            inputAttributes: {
              required: true,
              inputmode: keyboard,
              id: 'LagerplatzInput',
              autocomplete: 'off'
            },
            preConfirm: (location) => {
              return axios
                  .get(config.routes.getlocation, {
                    params: {name: location},
                    headers: auth.getAuthHeader()
                  })
                  .then(response => {
                    if (response.data.length === 1) {
                      return response.data[0]
                    } else {
                      let errorText = "Lagerplatz " + location + " nicht gefunden"
                      document.getElementById('LagerplatzInput').value = ''
                      throw new Error(errorText)
                    }
                  })
                  .catch(error => {
                    Swal.showValidationMessage(error)
                  })
            },
            allowOutsideClick: () => !Swal.isLoading(),
            reverseButtons: true,
            validationMessage: 'Bitte Lagerplatz eingeben',
            inputPlaceholder: 'Lagerplatz'
          })
        }
        if (currentStep === 2) {
          let keyboard = localStorage.getItem('keyboard')
          result = await swalQueue.fire({
            title: `Menge`,
            input: 'number',
            inputAttributes: {
              required: true,
              inputmode: keyboard,
              id: 'MengeInput',
              autocomplete: 'off'
            },
            inputValidator: (value) => {
              // value = parseInt(value)
              if (!this.isInt(value)) {
                document.getElementById('MengeInput').value = ''
                return 'Keine Zahl eingegeben'
              }
              if (value <= 0) {
                document.getElementById('MengeInput').value = ''
                return 'Menge zu klein'
              }
              if (value > 99999999) {
                document.getElementById('MengeInput').value = ''
                return 'Menge zu groß'
              }
              if (!isNaN(values[0])) {
                for (let po of this.product.estimated_delivery_qtys) {
                  if (values[0] === po.po_id) {
                    if (value > po.qty_to_receive) {
                      document.getElementById('MengeInput').value = ''
                      return `Menge zu groß (max. ${po.qty_to_receive}), bitte mit nächstem Artikel fortfahren und am Ende mit Einkauf klären!`
                    }
                  }
                }
              }
            },
            inputValue: values[currentStep],
            showCancelButton: currentStep > 0,
            currentProgressStep: currentStep,
            validationMessage: 'Bitte Menge eingeben',
            inputPlaceholder: 'Menge'
          })
        }
        if (result.value) {
          values[currentStep] = result.value
          currentStep++
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          currentStep--
        } else {
          break
        }
      }
      if (currentStep === steps.length) {
        if (!isNaN(values[0])) {
          let type = 'reception'
          let url = config.routes.updatestock + '/' + this.product.entity_id +
            '/' + type + '/' + values[2] + '/' + values[0] + '//' + values[1].id + '/'
          axios.get(url, {headers: auth.getAuthHeader()})
            .then(() => {
              this.loadProduct()
            })
            .catch(error => {
              Swal.showValidationMessage(error)
            })
        } else {
          let type = 'add'
          let url = config.routes.updatestock + '/' + this.product.entity_id +
            '/' + type + '/' + values[2] + '/' + values[0] + '//' + values[1].id + '/'
          axios.get(url, {headers: auth.getAuthHeader()})
            .then(() => {
              this.loadProduct()
            })
            .catch(error => {
              Swal.showValidationMessage(error)
            })
        }
      }
    },
    showSwalOut: async function storein (product) {
      const steps = ['1', '2', '3']
      const values = []
      let currentStep
      const swalQueue = Swal.mixin({
        progressSteps: steps,
        confirmButtonText: 'Weiter',
        cancelButtonText: 'Zurück',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        reverseButtons: true,
        allowOutsideClick: () => !Swal.isLoading()
      })
      for (currentStep = 0; currentStep < steps.length;) {
        let result = ''
        if (currentStep === 0) {
          result = await swalQueue.fire({
            title: `Grund`,
            inputValue: values[currentStep],
            showCancelButton: true,
            cancelButtonText: 'Abbrechen',
            currentProgressStep: currentStep,
            input: 'select',
            inputOptions: this.storeOutReasons,
            inputAttributes: {
              required: true
            },
            reverseButtons: true,
            validationMessage: 'Bitte Grund auswählen',
            inputPlaceholder: 'Bitte wählen'
          })
        }
        if (currentStep === 1) {
          let keyboard = localStorage.getItem('keyboard')
          result = await swalQueue.fire({
            title: `Lagerplatz`,
            inputValue: values[currentStep],
            showCancelButton: currentStep > 0,
            currentProgressStep: currentStep,
            input: 'text',
            inputAttributes: {
              required: true,
              inputmode: keyboard,
              id: 'LagerplatzInput',
              autocomplete: 'off'
            },
            preConfirm: (location) => {
              return axios
                  .get(config.routes.getlocation, {
                    params: { name: location },
                    headers: auth.getAuthHeader()
                  })
                  .then(response => {
                    if (response.data.length === 1) {
                      return response.data[0]
                    } else {
                      let errorText = "Lagerplatz " + location + " nicht gefunden"
                      document.getElementById('LagerplatzInput').value = ''
                      throw new Error(errorText)
                    }
                  })
                  .then(location => {
                    // abfragen ob product/location schon vergeben ist
                    return axios
                        .get(config.routes.productlocationcheck, {params: { storageplaceId: location.id, productId: this.product.entity_id },
                          headers: auth.getAuthHeader()})
                        .then(response => {
                          if (response.data === false) {
                            let errorText = "Lagerplatz " + location.name + " nicht zugewiesen"
                            document.getElementById('LagerplatzInput').value = ''
                            throw new Error(errorText)
                          } else {
                            this.location = location
                            return location
                          }
                        })
                        .catch(error => {
                          Swal.showValidationMessage(error)
                        })
                  })
                  .catch(error => {
                    Swal.showValidationMessage(error)
                  })
            },
            allowOutsideClick: () => !Swal.isLoading(),
            reverseButtons: true,
            validationMessage: 'Bitte Lagerplatz eingeben',
            inputPlaceholder: 'Lagerplatz'
          })
        }
        if (currentStep === 2) {
          let keyboard = localStorage.getItem('keyboard')
          result = await swalQueue.fire({
            title: `Menge`,
            input: 'number',
            inputAttributes: {
              required: true,
              inputmode: keyboard,
              id: 'MengeInput',
              autocomplete: 'off'
            },
            preConfirm: (qty) => {
              let locationId = values[1].id
              // abfragen ob die menge vorhanden ist
              return axios
                  .get(config.routes.productlocationqty, {
                    params: {storageplaceId: locationId, productId: product.entity_id},
                    headers: auth.getAuthHeader()
                  })
                  .then(response => {
                    let stockqty = parseInt(response.data)
                    if (stockqty < qty) {
                      let errorText = "Menge " + qty + " zu groß, maximal " + stockqty + " Stück"
                      document.getElementById('MengeInput').value = ''
                      throw new Error(errorText)
                    } else {
                      return qty
                    }
                  })
                  .catch(error => {
                    Swal.showValidationMessage(error)
                  })
            },
            inputValidator: (value) => {
              if (!this.isInt(value)) {
                document.getElementById('MengeInput').value = ''
                return 'Keine Zahl eingegeben'
              }
              if (value <= 0) {
                document.getElementById('MengeInput').value = ''
                return 'Menge zu klein'
              }
              if (value > 99999999) {
                document.getElementById('MengeInput').value = ''
                return 'Menge zu groß'
              }
            },
            inputValue: values[currentStep],
            showCancelButton: currentStep > 0,
            currentProgressStep: currentStep,
            validationMessage: 'Bitte Menge eingeben',
            inputPlaceholder: 'Menge'
          })
        }
        if (result.value) {
          values[currentStep] = result.value
          currentStep++
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          currentStep--
        } else {
          break
        }
      }
      if (currentStep === steps.length) {
        if (!isNaN(values[0])) {
          console.log('wareneingang speichern')
        } else {
          let type = 'remove'
          let url = config.routes.updatestock + '/' + this.product.entity_id +
            '/' + type + '/' + values[2] + '/' + values[0] + '//' + values[1].id + '/'
          axios.get(url, {headers: auth.getAuthHeader()})
            .then(() => {
              this.loadProduct()
            })
            .catch(error => {
              Swal.showValidationMessage(error)
            })
        }
      }
    },
    showPrintBarcode: async function printbarcode (product, attributeCode) {
      const steps = ['1', '2']
      const values = []
      let currentStep
      const swalQueue = Swal.mixin({
        progressSteps: steps,
        confirmButtonText: 'Weiter',
        cancelButtonText: 'Zurück',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        reverseButtons: true,
        allowOutsideClick: () => !Swal.isLoading()
      })
      for (currentStep = 0; currentStep < steps.length;) {
        let result = ''
        if (currentStep === 0) {
          result = await swalQueue.fire({
            title: `Drucker`,
            inputValue: values[currentStep],
            showCancelButton: true,
            cancelButtonText: 'Abbrechen',
            currentProgressStep: currentStep,
            input: 'select',
            inputOptions: this.printerOptions,
            inputAttributes: {
              required: true
            },
            reverseButtons: true,
            validationMessage: 'Bitte Drucker auswählen',
            inputPlaceholder: 'Bitte wählen'
          })
        }
        if (currentStep === 1) {
          let keyboard = localStorage.getItem('keyboard')
          result = await swalQueue.fire({
            title: `Menge`,
            input: 'number',
            inputAttributes: {
              required: true,
              inputmode: keyboard,
              id: 'MengeInput',
              autocomplete: 'off'
            },
            inputValidator: (value) => {
              // value = parseInt(value)
              if (!this.isInt(value)) {
                document.getElementById('MengeInput').value = ''
                return 'Keine Zahl eingegeben'
              }
              if (value <= 0) {
                document.getElementById('MengeInput').value = ''
                return 'Menge zu klein'
              }
              if (value > 100) {
                document.getElementById('MengeInput').value = ''
                return 'Menge zu groß'
              }
            },
            inputValue: '1',
            showCancelButton: currentStep > 0,
            currentProgressStep: currentStep,
            validationMessage: 'Bitte Menge eingeben',
            inputPlaceholder: 'Menge'
          })
        }
        if (result.value) {
          values[currentStep] = result.value
          currentStep++
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          currentStep--
        } else {
          break
        }
      }
      if (currentStep === steps.length) {
        let url = config.routes.printlabel
        let loadingInstance = Loading.service({})
        axios
            .post(url, {
                  'shipmentIncrementId': '',
                  'boxDimensions': {},
                  'productId': this.product.entity_id,
                  'qty': values[1],
                  'printLocation': values[0],
                  'attributeCode': attributeCode
                },
                {
                  headers: auth.getAuthHeader()
                })
            .then(response => {
              if (response.data) {
                Swal.fire({
                  title: 'Erfolgreich',
                  html: values[1] + ' Barcode(s) werden auf ' + this.printerOptions[values[0]] + ' gedruckt.',
                  width: 400
                })
              }
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      }
    },
    dateformat (cellValue) {
      if (cellValue) {
        return moment(new Date(cellValue))
          .format('DD.MM.YY')
      }
    },
    isAfterToday (comparedate) {
      return moment(new Date()).isBefore(moment(new Date(comparedate)))
    },
    isInt (value) {
      var er = /^-?[0-9]+$/
      return er.test(value)
    },
    editWarenpostAttribute(product) {
      Swal.fire({
        title: 'Warenpost deaktivieren?',
        html: 'Willst du den Warenpost Hinweis beim Packen für die Zukunft wirklich deaktivieren?',
        width: 400,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        cancelButtonText: 'Abbrechen',
        confirmButtonText: 'Ja',
        reverseButtons: true
      }).then((result) => {
        if (result.value === true) {
          let url = config.routes.editwarenpostattribute
          let loadingInstance = Loading.service({})
          axios
              .post(url, {
                    'productId': product.entity_id,
                    'action': 'keine Warenpost'
                  },
                  {
                    headers: auth.getAuthHeader()
                  })
              .then(() => {
                location.reload()
                loadingInstance.close()
              })
              .catch(e => {
                loadingInstance.close()
                if (e.response.status == 401) {
                  auth.logout()
                }
              })
        }
      })
    },
    editShippingNote(product, shippingnote) {
      Swal.fire({
        title: 'Verpackungshinweis',
        width: 400,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        cancelButtonText: 'Abbrechen',
        confirmButtonText: 'Speichern',
        reverseButtons: true,
        input: 'textarea',
        inputValue: shippingnote,
        inputPlaceholder: 'Verpackungshinweis hinzufügen',
      }).then((result) => {
        if (result.isConfirmed === true) {
          let url = config.routes.editshippingnote
          let loadingInstance = Loading.service({})
          axios
              .post(url, {
                    'productId': product.entity_id,
                    'shippingNote': result.value
                  },
                  {
                    headers: auth.getAuthHeader()
                  })
              .then(() => {
                location.reload()
                loadingInstance.close()
              })
              .catch(e => {
                loadingInstance.close()
                if (e.response.status == 401) {
                  auth.logout()
                }
              })
        }
      })
    }
  },
  computed: {
    storeInReasons: function () {
      let reasons = {
        'Rücksendung': 'Rücksendung',
        'Storno': 'Storno',
        'Fehlbestand': 'Fehlbestand',
        'Alternative': 'Alternative',
        'Umlagern' : 'Umlagern',
        'Produktion': 'Produktion',
        'Fotoshooting': 'Fotoshooting',
        'sonstiges': 'sonstiges'}
      for (let po of this.product.estimated_delivery_qtys) {
        reasons[po.po_id] = 'WE: ' + po.qty_to_receive + ' St. / ' + this.dateFormatDE(po.eta)
      }
      return reasons
    },
    storeOutReasons: function () {
      let reasons = {
        'Fehlbestand': 'Fehlbestand',
        'Beschädigung': 'Beschädigung',
        'Storno': 'Storno',
        'Alternative': 'Alternative',
        'Umlagern' : 'Umlagern',
        'Produktion': 'Produktion',
        'Verkauf': 'Verkauf',
        'Fotoshooting': 'Fotoshooting',
        'sonstiges': 'sonstiges'}
      return reasons
    },
    printerOptions: function () {
      let printerOptions = config.printplaces
      delete printerOptions[""]
      return printerOptions;
    }
  },

  created () {
    this.loadProduct()
  }
}
</script>
<style scoped>

.card-footer{
  text-align: center;
}

.buttons{
  text-align: center;
}

.card-fullwidth .card-content{
  padding: 0px;
}

.card-header-product h4{
  text-align: center;
  padding:10px;
  font-size: 1.3em;
}
.card-title{
  text-align: left;
}

.product-image{
  max-height: 300px !important;
  width: auto !important;
}

.card .numbers{
  text-align: center;
  font-size: 16px;
  padding-top: 1em;
}

.card-content img{
  padding: 5px;
}

.history{
  max-height: 90vh;
  overflow: scroll;
}

.ml-10{
  margin-left: 10px;
}

</style>
