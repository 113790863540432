<template>
  <div>
    <form @submit.prevent="submitForm" role="search">
      <div class="input-group">
        <input id="searchField" v-model="input2" type="text" class="form-control" v-focus placeholder="SKU / Lagerplatz / Sendung / Bestellung">
        <span class="input-group-addon" @click="submitForm"><i class="fa fa-search"></i></span>
      </div>
    </form>
    <div class="row" v-if="products.length">
      <div class="col-lg-6 col-sm-6">
        <h3>{{products.length}} Suchergebnisse</h3>
      </div>
    </div>
    <div class="row" v-if="products.length">
      <div class="col-lg-3 col-sm-6" v-for="(item, index) in products" :key="index">
        <product-card>
          <div class="text-center" :class="`icon-info`" slot="header">
            <table class="storageLocationTable">
              <tr class="label-parc2" v-for="(location, index) in item.locations" :key="index">
                <td>{{ location.name }}</td>
                <td><i class="ti-arrow-right"></i></td>
                <td>{{ location.parc_qty }} Stück</td>
              </tr>
            </table>
            <router-link :to="{ name: 'Produkt', params: { productid: item.entity_id, q: backlink}}">
              <img class="dashboard-img" :src="item.product_image" @error="item.product_image = '/img/placeholder.jpg'" />
            </router-link>
          </div>
          <div class="numbers" slot="content">
            <router-link class="product-link-info" :to="{ name: 'Produkt', params: { productid: item.entity_id, q: backlink}}">
              <span>{{item.name}}</span>
            </router-link>
          </div>
          <div slot="footer">
            <button ref="checkbutton" type="button" class="btn btn-lg btn-simple"
                    @click="toggleItem(item)">
                  <span class="btn-label">
                      <i class="ti-more-alt"></i>
                  </span>
            </button>
            <div v-if="item.shownumbers">
              <div class="col-xs-12">
                <table class="table table-sm table-striped">
                  <tbody>
                  <tr v-if="item.manufacturer_sku">
                    <td>Man. SKU</td>
                    <th>{{item.manufacturer_sku}}</th>
                  </tr>
                  <tr v-if="item.manufacturer_ean">
                    <td>Man. EAN</td>
                    <th>{{item.manufacturer_ean}}</th>
                  </tr>
                  <tr>
                    <td>SKU</td>
                    <th>{{item.sku}}</th>
                  </tr>
                  <tr v-if="item.ean">
                    <td>EAN</td>
                    <th>{{item.ean}}</th>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </product-card>
      </div>
    </div>
    <div class="row" v-if="manufacturerProducts.length">
      <div class="col-lg-6 col-sm-6">
        <h3>{{manufacturerProducts.length}} Suchergebnisse</h3>
      </div>
    </div>
    <div class="row" v-if="manufacturerProducts.length">
      <div class="col-lg-3 col-sm-6" v-for="(item, index) in manufacturerProducts" :key="index">
        <product-card>
          <div class="numbers" slot="content">
            <router-link :to="{ name: 'Lieferanten Produkt', params: { manufacturer_sku: item.manufacturer_sku, q: backlink}}">
              <span>{{item.manufacturer_name}}</span>
            </router-link>
          </div>
          <div slot="footer">
            <button ref="checkbutton" type="button" class="btn btn-md btn-simple"
                    @click="toggleItem(item)">
                  <span class="btn-label">
                      <i class="ti-more-alt"></i>
                  </span>
            </button>
            <div v-if="item.shownumbers">
              <div class="col-xs-12">
                <span v-if="item.manufacturer_sku">Man. SKU {{item.manufacturer_sku}}</span><br/>
                <span v-if="item.manufacturer_ean">Man. EAN {{item.manufacturer_ean}}</span><br/><br/>
              </div>
            </div>
          </div>
        </product-card>
      </div>
    </div>

    <div class="row" v-if="historyData.length">
      <div class="col-lg-3 col-sm-6">
        <h3>Historie</h3>
      </div>
    </div>

    <div class="row" v-if="historyData.length">
      <div class="col-lg-3 col-sm-6">
        <ul v-for="(item, index) in historyData" :key="index">
          <li><a :href="'/dashboard/' + item">{{item}}</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  import ProductCard from '@/components/Cards/ProductCard.vue'
  import axios from 'axios'
  import auth from '@/auth.js'
  import config from '@/config.js'
  import { Loading } from 'element-ui'

export default {
    components: {
      ProductCard
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    data () {
      return {
        products: {},
        manufacturerProducts: {},
        resultheadline: '',
        backlink: '',
        input2: '',
        historyData: []
      }
    },
    methods: {
      submitForm () {
        document.getElementById("searchField").blur()
        var trimmedInput = this.input2.trim()
        this.handleScan(trimmedInput)
      },
      toggleItem: function (item) {
        item.shownumbers = item.shownumbers !== true
      },
      handleScan (input1) {
        if (input1) {
          this.addToScanHistory(input1)
          let loadingInstance = Loading.service({})
          axios
            .get(config.routes.search, {
              params: { q: input1 },
              headers: auth.getAuthHeader()
            })
            .then(response => {
              var products = response.data[0]['products']
              var storageplace = response.data[0]['storageplace']
              var manufacturerProducts = response.data[0]['manufacturer_products']

              if (products.length) {
                for (let i = 0; i < products.length; i++) {
                  products[i].shownumbers = false
                }
                this.products = products
                localStorage.setItem('dashboard_scanItems', JSON.stringify(products))

                if (this.products.length === 1) {
                  this.resultheadline = 'Produkt gefunden:'
                  // this.$router.push({ name: 'Produkt', params: { productid: this.products[0].entity_id } })
                } else {
                  this.resultheadline =
                    'Mehrere Treffer gefunden, bitte auswählen:'
                }
              } else if (storageplace.length) {
                for (let i = 0; i < storageplace.length; i++) {
                  storageplace[i].shownumbers = false
                }
                this.products = storageplace
                this.resultheadline = 'Lagerplatz ' + input1 + ' gescannt:'
              } else if (manufacturerProducts.length) {
                for (let i = 0; i < manufacturerProducts.length; i++) {
                  manufacturerProducts[i].shownumbers = false
                }
                this.manufacturerProducts = manufacturerProducts
                if (this.manufacturerProducts.length === 1) {
                  this.resultheadline = 'Produkt gefunden:'
                  // this.$router.push({ name: 'Produkt', params: { productid: this.products[0].entity_id } })
                } else {
                  this.resultheadline =
                    'Mehrere Treffer gefunden, bitte auswählen:'
                }
              } else {
                this.$showErrorMessage(this.$notify,
                'Es wurden keine Produkte oder Lagerplätze für "' +
                  input1 +
                  '" gefunden'
                )
              }
              loadingInstance.close()
              this.backlink = input1
              this.input2 = ''
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      addToScanHistory (input) {
        var maxItems = 10
        var history = []

        if (localStorage.getItem('scanHistory')) {
          history = JSON.parse(localStorage.getItem('scanHistory'))
        }
        history.push(input)
        if (history.length > maxItems) {
          history = history.slice(history.length - maxItems)
        }
        localStorage.setItem('scanHistory', JSON.stringify(history))
        this.historyData = JSON.parse(localStorage.getItem('scanHistory')).reverse()
      }
    },
    created () {
      if (this.$route.params.q) {
        this.handleScan(this.$route.params.q)
      }

      if (localStorage.getItem('scanHistory')) {
        this.historyData = JSON.parse(localStorage.getItem('scanHistory')).reverse()
      }
      if (localStorage.getItem('dashboard_scanItems')) {
        let items = JSON.parse(localStorage.getItem('dashboard_scanItems'))
        for (let i = 0; i < items.length; i++) {
          items[i].shownumbers = false
        }
      }
    }
  }

</script>
<style scoped>
  .card img{
    max-height: 200px;
  }
</style>
