<template>
  <li :class="{'active': isActive}">
    <router-link
        :to="link.path ? link.path : '/'"
        :class="{active: isActive}"
        :target="link.target"
        :href="link.path"
        :onclick="toggleSidebar()"><i :class="link.icon"></i><p>{{link.name}}</p></router-link>
  </li>
</template>
<script>
  import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'

  export default {
    components: {
      [CollapseTransition.name]: CollapseTransition
    },
    props: {
      menu: {
        type: Boolean,
        default: false
      },
      link: {
        type: Object,
        default: () => {
          return {
            name: '',
            path: '',
            children: []
          }
        }
      }
    },
    provide () {
      return {
        addLink: this.addChild,
        removeLink: this.removeChild
      }
    },
    inject: {
      addLink: {default: null},
      removeLink: {default: null}
    },
    data () {
      return {
        children: [],
        collapsed: true
      }
    },
    computed: {
      isActive () {
        let isActive = this.$route.path == this.link.path
        return isActive
      }
    },
    methods: {
      addChild (item) {
        const index = this.$slots.default.indexOf(item.$vnode)
        this.children.splice(index, 0, item)
      },
      removeChild (item) {
        const tabs = this.children
        const index = tabs.indexOf(item)
        tabs.splice(index, 1)
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(false)
      }
    },
    mounted () {
      if (this.addLink) {
        this.addLink(this)
      }
      if (this.link.collapsed !== undefined) {
        this.collapsed = this.link.collapsed
      }
    },
    destroyed () {
      if (this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el)
      }
      if (this.removeLink) {
        this.removeLink(this)
      }
    }
  }
</script>
