<template>
  <div class="user">
    <div class="photo">
      <img v-if="userimage" :src="userimage"/>
      <img v-else src="/img/avatar.png"/>
    </div>
    <div class="info">
      <a data-toggle="collapse" @click="toggleMenu" href="javascript:void(0)">
           <span>
             {{user}}
             <b class="caret"></b>
          </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <el-collapse-transition>
          <ul class="nav" v-show="!isClosed">
            <li>
              <a href="javascript:void(0)">
                <span class="sidebar-mini"> <i class="ti-shift-left"></i></span>
                <span class="sidebar-normal" @click="logout">Logout</span>
              </a>
            </li>
          </ul>
        </el-collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
  import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
  import auth from '@/auth.js'

  export default {
    components: {
      [CollapseTransition.name]: CollapseTransition
    },
    data () {
      return {
        isClosed: true
      }
    },
    methods: {
      toggleMenu () {
        this.isClosed = !this.isClosed
      },
      logout () {
        auth.logout()
      }
    },
    computed: {
      user: () => {
        return auth.getUsername()
      },
      userimage: () => {
        let userdata = JSON.parse(localStorage.getItem('userdata'))
        if (userdata && userdata.image) {
          return userdata.image
        }
        return ''
      }
    }
  }
</script>
<style>
  .collapsed {
    transition: opacity 1s;
  }
</style>
