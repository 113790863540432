<template>
  <div class="card">
    <div class="card-header" v-if="$slots.header">
      <slot name="header"></slot>
    </div>
    <div class="card-content">
      <slot name="title-content">
        <div class="row">
          <div class="col-xs-7">
            <div class="numbers pull-left">
              <slot name="title"></slot>
            </div>
          </div>
          <div class="col-xs-5">
            <div class="pull-right">
              <slot name="title-label"></slot>
            </div>
          </div>
        </div>
      </slot>
      <slot name="subtitle"></slot>
        <chartist
            ratio="ct-chart"
            :type="chartType"
            :data="chartData"
            :options="chartOptions"
        />
    </div>

    <div class="card-footer">
      <slot name="footer">
        <hr>
        <div class="footer-title">
          <slot name="footer-title"></slot>
        </div>
        <div class="pull-right">
          <slot name="footer-right"></slot>
        </div>
      </slot>
    </div>
  </div>
</template>
<script>

export default {
  name: 'chart-card',
  props: {
    footerText: {
      type: String,
      default: ''
    },
    headerTitle: {
      type: String,
      default: 'Chart title'
    },
    chartType: {
      type: String,
      default: 'Bar' // Line | Pie | Bar
    },
    chartOptions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    chartData: {
      type: Object,
      default: () => {
        return {
          labels: [],
          series: []
        }
      }
    }
  }
}
</script>
<style>
</style>
