<template>
  <div :class="sidebarClasses"
       :data-background-color="backgroundColor"
       :data-active-color="activeColor">
    <div class="logo">
      <a class="simple-text logo-mini"
         href="/">
        <div class="logo-img">
          <img :src="logo" alt="">
        </div>
      </a>
      <a class="simple-text logo-normal"
         href="/">
        {{ title }}
      </a>
    </div>
    <div class="sidebar-wrapper">
      <slot>

      </slot>
      <ul :class="navClasses">
        <slot name="links">
          <sidebar-item v-for="(link, index) in sidebarLinks"
                        :key="link.name + index"
                        :link="link">
          </sidebar-item>
        </slot>

      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Revolution APP' + (process.env.NODE_ENV !== 'production' ? ' ' + process.env.NODE_ENV : '')
      // default: 'Revolution APP' + ' ' + process.env.NODE_ENV
    },
    type: {
      type: String,
      default: 'sidebar',
      validator: (value) => {
        let acceptedValues = ['sidebar', 'navbar']
        return acceptedValues.indexOf(value) !== -1
      }
    },
    backgroundColor: {
      type: String,
      default: 'black',
      validator: (value) => {
        let acceptedValues = ['white', 'brown', 'black']
        return acceptedValues.indexOf(value) !== -1
      }
    },
    activeColor: {
      type: String,
      default: 'success',
      validator: (value) => {
        let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
        return acceptedValues.indexOf(value) !== -1
      }
    },
    logo: {
      type: String,
      default: '/img/eisbaer.png'
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    sidebarClasses () {
      if (this.type === 'sidebar') {
        return 'sidebar'
      } else {
        return 'collapse navbar-collapse off-canvas-sidebar'
      }
    },
    navClasses () {
      if (this.type === 'sidebar') {
        return 'nav'
      } else {
        return 'nav navbar-nav'
      }
    }
  },
  beforeDestroy () {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false
    }
  }
}

</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile{
    display: none;
  }
}
</style>
