<template>
  <nav class="navbar navbar-default">
    <div class="container-fluid">
      <div class="navbar-minimize">
        <button class="btn btn-fill btn-icon" @click="minimizeSidebar">
          <i :class="$sidebar.isMinimized ? 'ti-menu-alt' : 'ti-more-alt'"></i>
        </button>
      </div>
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" :class="{toggled: $sidebar.showSidebar}" @click="toggleSidebar">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar bar1"></span>
          <span class="icon-bar bar2"></span>
          <span class="icon-bar bar3"></span>
        </button>
        <a class="navbar-brand" :href="this.toplinks[this.$route.name]">
          {{ this.headlines[this.$route.name] ? this.headlines[this.$route.name] : this.$route.name }}
        </a>
      </div>
      <div class="collapse navbar-collapse navbar-scanform"></div>
    </div>
  </nav>
</template>
<script>

  export default {
    data () {
      return {
        activeNotifications: false,
        headlines: {
          Prüfen: 'Packen',
          Kartons: 'Packen',
          PickBoxes: 'Packen',
          Kommissionieren: 'Picken',
          Pickkommentar: 'Kommentar',
          Produkt: 'Info',
          Dashboard: 'Info',
          Sammelkommissionierung : 'Picken',
          Sortiervorgang: 'Picken'
        },
        toplinks: {
          Produkt: '/dashboard',
          'Lieferanten Produkt': '/dashboard',
          Notiz: '/note',
          Kommissionieren: '/pick',
          Prüfen: '/pack',
          Kartons: '/pack',
          PickBoxes: '/pack',
          Print: '/print',
          Retouren: '/returns',
          Donbot: '/donbot',
          Sammelkommissionierung: '/pick',
          Sortiervorgang: '/pick'
        }
      }
    },
    methods: {
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar () {
        this.$sidebar.toggleMinimize()
      }
    },
    created () {
    },
    mounted () {
    },
    destroyed () {
    }
  }

</script>
<style>
.navbar button{
  margin: 16px 0;
}
</style>
