<template>
  <div>
    <div class="card-content row">
      <div class="col-xs-12 column-right">
        <button type="button" class="btn btn-xs btn-fill btn-info" @click="showEditSwal()">
            <span class="btn-label">
              <i class="fa fa-pencil"></i>
          </span>
        </button>
        <button type="button" class="btn btn-xs btn-fill btn-danger" @click="showDeleteSwal()">
        <span class="btn-label">
              <i class="fa fa-trash"></i>
          </span>
        </button><br/><br/>
      </div>
    </div>
    <div class="row" v-if="note">
      <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">{{ this.dateFormatDE(note.created_at) }} | ID: {{note.note_id}} | {{note.type}} </h4>
           </div>
            <div class="card-content">
              <div>
                <router-link :to="{ name: 'Produkt', params: { productid: note.product_id}}">
                  <p class="category">Man. SKU {{note.manufacturer_sku}} | {{note.product_name}} <i class="ti-new-window" /></p>
                </router-link>
                <p>{{note.comment}}</p>
                <div class="note-image-container">
                  <div class="note-image-wrapper">
                    <div v-for="(image, imageid) in note.images" :key="imageid" class="note-image-item">
                    <img :src="image" @click="showSwalImage(image)" /><br/>
                      <button type="button" class="btn btn-xs btn-fill btn-delete" @click="showSwal(imageid)">
                      <span class="btn-label">
                          <i class="fa fa-trash"></i>
                      </span>
                        Löschen
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <form novalidate v-if="isInitial || isSaving">
                <h3>Bilder hochladen</h3>
                <div class="dropbox">
                  <input type="hidden" name="id" :value="note.note_id" />
                  <input type="file" :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length" accept="image/*" class="input-file">
                  <p v-if="isInitial">
                    Datei hierher ziehen<br> oder Klicken zum Auswählen/ Foto machen
                  </p>
                  <p v-if="isSaving">
                    Lade {{ fileCount }} Datei hoch.
                  </p>
                </div>
              </form>

              <!--SUCCESS-->
              <div v-if="isSuccess">
                <h2>Upload erfolgreich.</h2>
                <p>
                  <a href="javascript:void(0)" @click="reset()">weitere Datei hochladen</a>
                </p>
                <ul class="list-unstyled">
                  <li v-for="(item, index) in uploadedFiles" :key="index">
                    <img style="max-width: 300px;" :src="item.url" class="img-responsive img-thumbnail" :alt="item.originalName">
                  </li>
                </ul>
              </div>
              <!--FAILED-->
              <div v-if="isFailed">
                <h2>Upload fehlgeschlagen.</h2>
                <p>
                  <a href="javascript:void(0)" @click="reset()">Nochmal versuchen</a>
                </p>
                <pre>{{ uploadError }}</pre>
              </div>

            </div>
            <div class="card-footer"></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import axios from 'axios'
  import config from '../../config.js'
  import auth from '../../auth.js'
  import Swal from 'sweetalert2'
  import {Loading} from 'element-ui'

  import moment from 'moment'
  import { upload, getImage } from '../../file-upload.service'

  const STATUS_INITIAL = 0
  const STATUS_SAVING = 1
  const STATUS_SUCCESS = 2
  const STATUS_FAILED = false


  export default {
    name: 'note-view',
    data () {
      return {
        statusSwitch: null,
        note: null,
        uploadedFiles: [],
        uploadError: null,
        currentStatus: null,
        uploadFieldName: 'image'
      }
    },
    watch: {
      statusSwitch: function () {
        if (this.statusSwitch && this.note.status === '0') {
          this.updateStatus()
        } else if (!this.statusSwitch && this.note.status === '1') {
          this.updateStatus()
        }
      }
    },
    computed: {
      isInitial () {
        return this.currentStatus === STATUS_INITIAL
      },
      isSaving () {
        return this.currentStatus === STATUS_SAVING
      },
      isSuccess () {
        return this.currentStatus === STATUS_SUCCESS
      },
      isFailed () {
        return this.currentStatus === STATUS_FAILED
      }
    },
    methods: {
      updateStatus () {
        var route
        if (this.statusSwitch) {
          route = config.routes.resolvenote
        } else {
          route = config.routes.unresolvenote
        }
        axios
          .get(route, { params: { noteId: this.$route.params.noteid },
            headers: auth.getAuthHeader() })
          .then(() => {
            this.loadNote()
          })
          .catch(e => {
            console.log(e.message)
            if (e.response.status == 401) {
              auth.logout()
            }
          })
      },
      showSwal: function (imageid) {
        Swal.fire({
          title: 'Wirklich löschen?',
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: 'Ja',
          cancelButtonText: 'Nein',
          buttonsStyling: false
        }).then((result) => {
          if (result.value) {
            axios
              .get(config.routes.deleteimage, {
                params: { imageId: imageid },
                headers: auth.getAuthHeader()
              })
              .then(() => {
                this.loadNote()
              })
              .catch(e => {
                if (e.response.status == 401) {
                  auth.logout()
                }
              })
          }
        })
      },
      showDeleteSwal: function () {
        Swal.fire({
          title: 'Wirklich löschen?',
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: 'Ja',
          cancelButtonText: 'Nein',
          buttonsStyling: false
        }).then((result) => {
          if (result.value) {
            axios
              .get(config.routes.deletenote, {
                params: { noteId: this.note.note_id },
                headers: auth.getAuthHeader()
              })
              .then(() => {
                this.$router.push('Dashboard')
              })
              .catch(e => {
                if (e.response.status == 401) {
                  auth.logout()
                }
              })
          }
        })
      },
      async showEditSwal () {
        const steps = ['1', '2']
        const values = []
        let currentStep
        const swalQueue = Swal.mixin({
          progressSteps: steps,
          confirmButtonText: 'Weiter',
          cancelButtonText: 'Zurück',
          buttonsStyling: false,
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          reverseButtons: true,
          allowOutsideClick: () => !Swal.isLoading()
        })

        for (currentStep = 0; currentStep < steps.length;) {
          let result = ''
          if (currentStep === 0) {
            result = await swalQueue.fire({
              title: 'Typ?',
              input: 'select',
              inputOptions: {
                '': '- bitte auswählen -',
                'Wareneingang': 'Wareneingang',
                'Rücksendung': 'Rücksendung',
                'Bestellung': 'Bestellung',
                'Storno': 'Storno',
                'Beschädigung': 'Beschädigung',
                'Fehlbestand': 'Fehlbestand',
                'Alternative': 'Alternative',
                'Produktion': 'Produktion',
                'sonstiges': 'sonstiges'
              },
              inputValue: this.note.type,
              preConfirm: (input) => {
                if (!input) {
                  Swal.showValidationMessage('Bitte auswählen')
                  return false
                }
                return input
              }
            })
          }

          if (currentStep === 1) {
            result = await swalQueue.fire({
                  title: 'Kommentar',
                  input: 'textarea',
                  inputValue: this.note.comment,
                  preConfirm: (input) => {
                    return input
                  }
                }
            )
          }

          if (result.value) {
            values[currentStep] = result.value
            currentStep++
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            currentStep--
          } else {
            break
          }
        }

        if (currentStep === steps.length) {

          let loadingInstance = Loading.service({})
          axios
              .put(config.routes.updatenote, {
                    'noteId': this.note.note_id,
                    'prio': 1,
                    'type': values[0],
                    'comment': values[1]
                  },
                  {headers: auth.getAuthHeader()}
              )
              .then(() => {
                this.loadNote()
              })
              .catch(e => {
                loadingInstance.close()
                if (e.response.status == 401) {
                  auth.logout()
                }
              })
        }
      },
      loadNote () {
        let loadingInstance = Loading.service({})
        axios
          .get(config.routes.getnotes, { params: { noteId: this.$route.params.noteid },
            headers: auth.getAuthHeader() })
          .then(response => {
            this.note = response.data[0]
            loadingInstance.close()
            this.statusSwitch = this.note.status === '1'
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
      },
      reset () {
        // reset form to initial state
        this.currentStatus = STATUS_INITIAL
        this.uploadedFiles = []
        this.uploadError = null
      },
      save (formData) {
        // upload data to the server
        this.currentStatus = STATUS_SAVING

        upload(formData)
          .then(() => {
            this.loadNote()
            this.currentStatus = STATUS_SUCCESS
          })
          .catch(err => {
            this.uploadError = err.response
            this.currentStatus = STATUS_FAILED
          })
      },
      filesChange (fieldName, fileList) {
        if (!fileList.length) return
        let outerthis = this
        getImage(fileList[0]).then(image => {
          // handle file changes
          let formData = {
            noteId: outerthis.note.note_id,
            image: {
              type: fileList[0].type,
              name: fileList[0].name,
              base64_encoded_data: image
            }
          }
          outerthis.save(formData)
        }
        )
      },
      showSwalImage: function (image) {
        Swal.fire({
          html: '<img src="' + image + '" width="100%">',
          width: 600,
          showConfirmButton: false,
          showCloseButton: true,
          customClass: {
            closeButton: 'btn btn-danger btn-fill'
          },
          buttonsStyling: false
        })
      },
      prioFormat: (value) => {
        if (value === config.prio.key.high) return config.prio.text.high
        if (value === config.prio.key.medium) return config.prio.text.medium
        if (value === config.prio.key.low) return config.prio.text.low
      },
      dateFormatDE: (value) => {
        if (value) {
          return moment.utc(value).local().format('DD.MM.YYYY')
        } else {
          return ''
        }
      },
      dateFormatTimeDE: (value) => {
        return moment.utc(value).local().format('DD.MM.YY HH:mm')
      }
    },
    mounted () {
      this.reset()
    },
    created () {
      this.loadNote()
    }
  }
</script>
<style scoped>
  .btn-delete{
    margin-top: 5px;
  }

  .dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: #efefef;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }

  .column-right{
    text-align: right;
  }

  .bootstrap-switch{
    margin-bottom: 0px;
  }

  .card .card-content a .category{
    color: #68B3C8;
  }

  .note-image-container {
    max-width:100%;
    margin:auto;;
  }

  .note-image-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right:-5px;
    margin-top:-5px;
  }

  .note-image-item {
    flex: 0 0 auto;
    padding: 5px;
    margin:5px 5px  0  0;
  }

  .note-image-item img{
    max-width: 180px;
  }

</style>
