<template>
  <div>
    <div class="card">
      <div class="card-content">
        <div class="row">
          <div class="col-lg-12 col-sm-12">
            <h4 class="red">Bitte Auffälligkeiten und Anmerkungen hier eingeben</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            Problem:
            <p-switch class="" v-model="problemSwitch">
              <i class="fa fa-check" slot="on"></i>
              <i class="fa fa-times" slot="off"></i>
            </p-switch>
            <br/>
            <br/>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <div class="col-sm-12">
              <textarea v-focus v-model="comment" class="form-control" placeholder="" rows="3"
                        tabindex="1"></textarea><br/><br/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6">
            <button ref="checkbutton" type="button" class="btn btn-md btn-warning btn-fill" @click="stepBack()"
                    tabindex="3">
                <span class="btn-label">
                    <i class="ti-angle-left"></i>
                </span>
              Zurück
            </button>

          </div>
          <div class="col-xs-6 text-right">

            <button ref="checkbutton" type="button" class="btn btn-md btn-success btn-fill" @click="save()"
                    tabindex="2">
              Speichern
              <span class="btn-label">
                    <i class="ti-check"></i>
                </span>
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from '@/config'
import {Loading} from 'element-ui'
import axios from 'axios'
import auth from '@/auth'
import PSwitch from '@/components/Switch.vue'

export default {
  components: {
    PSwitch
  },
  data () {
    return {
      items: [],
      comment: '',
      problemSwitch: null
    }
  },
  methods: {
    stepBack () {
      this.$router.go(-1)
    },
    save () {
      let comment = ''
      let problem = this.problemSwitch ? 1 : 0
      let problemtext = problem ? 'ja' : 'nein'
      let shipment = JSON.parse(localStorage.getItem('commission_shipment'))
      if (shipment.comment !== this.comment || shipment.problem !== problem) {
        this.savescancomment(this.comment, this.commentId)
        comment = 'Kommissionierung Kommentar <br/>' + 'Kommentar: ' + this.comment + '<br/>Problem: ' + problemtext + '<br/>'
        this.saveshipmentcomment(comment)
      }
      this.stepBack()
    },
    saveshipmentcomment (comment) {
      let url = config.routes.commissionshipmentcomment
      let shipment = JSON.parse(localStorage.getItem('commission_shipment'))
      comment = comment + 'by ' + localStorage.getItem('username')
      if (shipment) {
        let loadingInstance = Loading.service({})
        axios
          .post(url, {
            'shipmentId': shipment.shipment_id,
            'comment': comment
          },
            {headers: auth.getAuthHeader()}
          )
          .then(() => {
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
      }
    },
    savescancomment (comment) {
      let shipment = JSON.parse(localStorage.getItem('commission_shipment'))
      shipment.comment = comment
      shipment.problem = this.problemSwitch ? 1 : 0
      localStorage.setItem('commission_shipment', JSON.stringify(shipment))
      if (shipment) {
        let loadingInstance = Loading.service({})

        let url = config.routes.commissionscancomment
        axios
          .post(url, {
            'scanComment': {
              'shipmentId': shipment.shipment_id,
              'shipmentIncrementId': shipment.increment_id,
              'comment': comment,
              'packer': localStorage.getItem('username'),
              'problem': shipment.problem
            }
          },
            {headers: auth.getAuthHeader()}
          )
          .then(() => {
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
      }
    }
  },
  created () {
    if (localStorage.getItem('commission_shipment')) {
      this.comment = JSON.parse(localStorage.getItem('commission_shipment')).comment
      this.problemSwitch = JSON.parse(localStorage.getItem('commission_shipment')).problem
    }
  },
  computed: {}
}
</script>
