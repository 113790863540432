<template>
  <div>
    <form @submit.prevent="submitShippingItemForm">
      <div class="input-group">
        <input :inputmode="keyboard" v-model="articleid" ref="inputfield" type="text" v-focus class="form-control" tabindex="1" placeholder="Artikel">
        <span class="input-group-addon" @click="submitShippingItemForm"><i class="fa fa-search"></i></span>
      </div>
    </form>
    <div class="row" v-if="products.length">
      <div class="col-xs-10">
        <h4>Sortierung {{ trolleyName }}</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-sm-6" v-for="(product, index) in products" :key="index">
        <shipmentitem-card :class="product.disabled == true ? 'finished' : 'false' " :linkto="{ name: 'Produkt', params: { productid: product.product_id}}">
          <div class="text-center" :class="`icon-info`" slot="header">
            <div class="image-container">
              <img :src="product.image" @click="showSwalImage(product)" @error="product.image = '/img/placeholder.jpg'" />
            </div>
          </div>
          <div class="numbers" slot="content">
            <button ref="checkbutton" :disabled="product.disabled"  type="button" class="btn btn-sm btn-success btn-fill pick-button" @click="showSwalSorting(product)">
              zu sortieren <span class="qty-scanned">{{product.qty}}</span> Stück
            </button>
            <div v-if="product.problem" style="color:red">ACHTUNG! Zu dieser Sendung existieren Probleme!</div><br/>
            <span>{{product.name}}</span><br/>
          </div>
          <div class="buttons" slot="button">
            <button ref="checkbutton" type="button" class="btn btn-lg btn-simple pull-left"
                    @click="toggleItem(product)">
              <span class="btn-label">
                  <i class="ti-more-alt"></i>
              </span>
            </button>
            <button ref="checkbutton" type="button" class="btn btn-md btn-fill print-button"
                    @click="printShipment(product.shipment_increment_id)">
              <span class="btn-label">
                  <i class="ti-printer"></i>
              </span>
            </button>
          </div>
          <div slot="footer">
            <br/>
            <div v-if="product.shownumbers">
              <div class="col-xs-12">
                <table class="table table-sm table-striped">
                  <tbody>
                  <tr>
                    <td>Sendung</td>
                    <th>{{ product.shipment_increment_id }}</th>
                  </tr>
                  <tr v-if="product.manufacturer_sku">
                    <td>Man. SKU</td>
                    <th>
                      <router-link :to="{ name: 'Produkt', params: { productid: product.product_id}}" target="_blank">
                        {{product.manufacturer_sku}}
                      </router-link>
                    </th>
                  </tr>
                  <tr v-if="product.manufacturer_ean">
                    <td>Man. EAN</td>
                    <th>{{product.manufacturer_ean}}</th>
                  </tr>
                  <tr>
                    <td>SKU</td>
                    <th>{{product.sku}}</th>
                  </tr>
                  <tr v-if="product.ean">
                    <td>EAN</td>
                    <th>{{product.ean}}</th>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </shipmentitem-card>
      </div>
    </div>
  </div>
</template>
<script>
import config from '@/config.js'
import ShipmentitemCard from '@/components/Cards/ShipmentItemCard'
import Swal from "sweetalert2";
import {SoundError, SoundOk} from "@/main";
import {Loading} from 'element-ui'
import axios from 'axios'
import auth from '@/auth'

export default {
  components: {
    ShipmentitemCard
  },
  data () {
    return {
      articleid : '',
      products: []
    }
  },
  methods: {
    submitShippingItemForm () {
      if (this.articleid) {
        this.handleScan(this.articleid)
        this.articleid = ''
      }
    },
    handleScan (searchString) {
      if (searchString === config.endSequence) {
        this.cancel()
        return
      }

      let item = this.findItem(searchString)
      if (item !== undefined) {
        this.checkItem(item)
      }
    },
    findItem (searchString) {
      let products = this.products
      console.log(products)
      let nextProduct = ''
      for (let i = 0; i < products.length; i++) {
        if (products[i].disabled === false) {
          if (products[i].sku.toLowerCase() === searchString.toLowerCase()) {
            nextProduct = products[i]
          } else if (products[i].ean && products[i].ean.toLowerCase() === searchString.toLowerCase()) {
            nextProduct = products[i]
          } else if (products[i].manufacturer_sku && products[i].manufacturer_sku.toLowerCase() === searchString.toLowerCase()) {
            nextProduct = products[i]
          } else if (products[i].manufacturer_ean && products[i].manufacturer_ean.toLowerCase() === searchString.toLowerCase()) {
            nextProduct = products[i]
          }
        }
      }
      if (nextProduct) {
        this.showSwalSorting(nextProduct)
      } else {
        SoundError.play()
        this.$showErrorMessage(this.$notify,
            'Artikel nicht gefunden oder bereits sortiert'
        )
      }
    },
    showSwalSorting(product) {
      // hier muss geschaut werden, ob Artikel zu dieser Sendung schon in einer Box liegen
      // -> wenn ja, dann darf dieser Artikel auch nur in diese Box gelegt werden
      let text = ''
      if (product.otherBoxes !== '') {
        text = 'Artikel zu dieser Sendung liegen bereits in <span style="font-size: 2em;">' + product.otherBoxes + '</span>'
      }
      Swal.fire({
        title: '<span style="color:red">' + product.qty + ` Stück </span> in Box`,
        html: text,
        input: 'text',
        returnInputValueOnDeny: false,
        inputAttributes: {
          required: true,
          inputmode: this.keyboard,
          id: 'BoxInput',
          autocomplete: 'off'
        },
        cancelButtonText: 'Abbrechen',
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        preConfirm: (box) => {
            // Check if concatenatedBoxes is not empty and does not contain box
            if (product.otherBoxes !== '' && product.otherBoxes.toLowerCase() !== box.toLowerCase()) {
              let errorText = "Ein Teil der Sendung liegt bereits in einer anderen Box!";
              document.getElementById('BoxInput').value = '';
              Swal.showValidationMessage(errorText);
            } else {
              return axios.get(config.routes.getBox + '/' + box, {
                headers: auth.getAuthHeader()
              })
                  .then(response => {
                    if (response.data) {
                      let boxData = response.data
                      return axios.get(config.routes.othershipmentsinbox + '/' + product.shipment_id + '/' + boxData.box_id, {
                        headers: auth.getAuthHeader()
                      }).then(response => {
                        if (response.data === false) {
                          return boxData
                        } else {
                          let errorText = "Es liegen bereits Sendungen in " + box;
                          document.getElementById('BoxInput').value = '';
                          Swal.showValidationMessage(errorText);
                        }
                      }).catch(error => {
                        Swal.showValidationMessage(error);
                      })
                    } else {
                      let errorText = "Box " + box + " nicht gefunden";
                      document.getElementById('BoxInput').value = '';
                      Swal.showValidationMessage(errorText);
                    }
                  })
                  .catch(error => {
                    Swal.showValidationMessage(error);
                  });
            }
        },

        allowOutsideClick: true,
        reverseButtons: true,
        validationMessage: 'Bitte Box einscannen',
        inputPlaceholder: 'Box'
      }).then((result) => {
        if (result.value) {
          if (product.qty > 1) {
            Swal.fire({
              html: '<h5>Hast du wirklich' +
                  '<b><div style="font-size:2.5em; color:red; line-height: 1.5">' + product.qty + ' Stück </div></b>' +
                  '<b><div style="font-size:1.5em; margin-bottom: 5px">(' + result.value.box_name + ')</div></b> sortiert?</h5>',
              buttonsStyling: false,
              confirmButtonText: 'JA',
              customClass: {
                confirmButton: 'btn btn-danger btn-fill',
              },
              allowOutsideClick: false,
              imageUrl: product.image,
              imageHeight: 150,
            })
          }

          let loadingInstance = Loading.service({})
          axios
              .post(config.routes.sortProductInBox, {
                    'articleScanId': product.article_scan_id,
                    'boxId': result.value.box_id
                  },
                  {headers: auth.getAuthHeader()}
              )
              .then(() => {
                SoundOk.play()
                product.disabled = true
                product.qty = 0
                let shipmentId = product.shipment_id
                let products = this.products
                for (let i = 0; i < products.length; i++) {
                  if (products[i].shipment_id === shipmentId) {
                    products[i].otherBoxes = result.value.box_name
                  }
                }
                this.products = products
                const index = this.products.indexOf(product)
                this.products.splice(index, 1)
                this.products.push(product)

                localStorage.setItem('trolley_sort', JSON.stringify(this.trolleySort))
                this.saveshipmentcomment('SKU: ' + product.sku + '<br/>' +
                      'wurde aus ' + this.trolleyName + '<br/>' +
                      'in ' + result.value.box_name + ' sortiert'
                      , product)
                loadingInstance.close()
              })
              .catch(e => {
                loadingInstance.close()
                if (e.response.status == 401) {
                  auth.logout()
                }
              })
        }
      })
    },
    saveshipmentcomment (comment, product) {
      let url = config.routes.commissionshipmentcomment
      let shipment = product.shipment_id
      comment = comment + '<br/>by ' + localStorage.getItem('username')
      if (shipment) {
        let loadingInstance = Loading.service({})
        axios
            .post(url, {
                  'shipmentId': shipment,
                  'comment': comment
                },
                {headers: auth.getAuthHeader()}
            )
            .then(() => {
              if (this.checkAllDone()) {
                this.finish()
              }
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      }
    },
    showSwalImage: function (product) {
      Swal.fire({
        html: '<img src="' + product.image + '" width="100%">',
        width: 600,
        showConfirmButton: false,
        showCloseButton: true,
        customClass: {
          closeButton: 'btn btn-danger btn-fill'
        },
        buttonsStyling: false
      })
    },
    toggleItem: function (product) {
      product.shownumbers = product.shownumbers !== true
    },
    printShipment: function (shipmentId) {
      Swal.fire({
        title: 'Drucker',
        showCancelButton: true,
        cancelButtonText: 'Abbrechen',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        input: 'select',
        inputOptions: config.printplaces,
        inputAttributes: {
          required: true
        },
        reverseButtons: true,
        validationMessage: 'Bitte Drucker auswählen',
        inputPlaceholder: 'Bitte wählen',
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.value) {
          let printer = result.value
          let url = config.routes.printlabel
          let loadingInstance = Loading.service({})
          axios
              .post(url, {
                    'shipmentIncrementId': shipmentId,
                    'boxDimensions': {},
                    'productId': 0,
                    'qty': 1,
                    'printLocation': printer,
                    'attributeCode': ''
                  },
                  {
                    headers: auth.getAuthHeader()
                  })
              .then(response => {
                if (response.data) {
                  Swal.fire({
                    title: 'Erfolgreich',
                    html: 'Sendung ' + shipmentId + ' gedruckt',
                    width: 400
                  })
                  this.saveshipmentcomment('Sendungslabel wurde gedruckt')
                }
                loadingInstance.close()
              })
              .catch(e => {
                loadingInstance.close()
                if (e.response.status == 401) {
                  auth.logout()
                }
              })
        }
      })
    },
    checkAllDone () {
      for (let product of this.products) {
        if (product.disabled !== true) {
          return false
        }
      }
      return true
    },
    finish () {
      localStorage.removeItem('trolley_sort')
      this.$router.push({ name: 'Picken' }).catch(() => {})
    }
  },
  created () {
    this.keyboard = localStorage.getItem('keyboard')
    if (this.$route.params.q) {
      localStorage.setItem('trolley_sort', JSON.stringify(this.$route.params.q))
      this.trolleySort = this.$route.params.q
    } else {
      this.trolleySort = JSON.parse(localStorage.getItem('trolley_sort'))
    }
    this.trolleyName = this.trolleySort.trolleyName
    this.trolleyId = this.trolleySort.trolleyId
    let products = this.trolleySort.products
    for (let i = 0; i < products.length; i++) {
      products[i].shownumbers = false
    }
    this.products = products
  },
  mounted () {
  },
  computed: {
    config() {
      return config
    }
  },
  destroyed () {
  }
}
</script>
