<template>
  <div>
    <div class="row" v-if="product">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header-product">
            <h4 class="card-title">{{product.manufacturer_name}}</h4>
          </div>
          <div class="numbers">
            <h4>{{product.qty}}</h4>
          </div>
          <vue-tabs class="card-content">
            <v-tab title="Lieferant">
              <div class="card card-fullwidth">
                <div class="card-content">
                  <div class="table-tasks">
                    <table class="table table-sm table-striped">
                      <tbody>
                      <tr>
                        <th scope="row">SKU</th>
                        <td align="left">{{product.manufacturer_sku}}</td>
                      </tr>
                      <tr>
                        <th scope="row">EAN</th>
                        <td align="left">{{product.manufacturer_ean}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Lieferant</th>
                        <td align="left">{{product.manufacturer}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Letzte Änderung</th>
                        <td align="left"><span v-if="product.created_at">
                          {{ this.dateFormatTimeDE(product.created_at) }}</span></td>
                      </tr>
                      <tr>
                        <th scope="row">Name</th>
                        <td align="left"><a href="#" @click="showHistory('manufacturer_name', product.manufacturer_name)">{{product.manufacturer_name}}</a></td>
                      </tr>
                      <tr>
                        <th scope="row">EK</th>
                        <td align="left"><a href="#" @click="showHistory('cost', formatPrice(product.cost))">{{this.formatPrice(product.cost)}} EUR</a></td>
                      </tr>
                      <tr>
                        <th scope="row">Bestand</th>
                        <td align="left"><a href="#" @click="showHistory('qty', product.qty)">{{product.qty}}</a></td>
                      </tr>
                      <tr v-if="1 ||product.availability_date && 1 || this.isAfterToday(product.availability_date)">
                        <th scope="row">WE</th>
                        <td align="left"><a href="#" @click="showHistory('availability_date', dateFormatDE(product.availability_date))">{{ this.dateFormatDE(product.availability_date) }}</a></td>
                      </tr>
                      <tr>
                        <th scope="row">VPE</th>
                        <td align="left"><a href="#" @click="showHistory('vpe', product.vpe)">{{product.vpe}}</a></td>
                      </tr>
                      <tr>
                        <th scope="row">Umkarton</th>
                        <td align="left"><a href="#" @click="showHistory('umkarton', product.umkarton)">{{product.umkarton}}</a></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </v-tab>
          </vue-tabs>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import config from '@/config.js'
import auth from '@/auth.js'
import moment from 'moment'
import VueTabs from 'vue-nav-tabs'
import Vue from 'vue'
import {Loading} from 'element-ui'

Vue.use(VueTabs)

export default {
  data () {
    return {
      product: null,
      history: null,
      location: null,
      location2: null
    }
  },
  methods: {
    showHistory (attributeName) {
      let html = ''
      let prevValue = ''
      let history = [...this.history]
      history.reverse()
      for (let row of history) {
        if (row[attributeName] !== null) {
          let value = row[attributeName]
          if (value === prevValue) {
            continue
          } else {
            prevValue = value
          }
          if (attributeName === 'availability_date') {
            value = this.dateFormatDE(value)
          }
          if (attributeName === 'cost') {
            value = this.formatPrice(value)
          }
          html = '<tr><td align="left">' + this.dateFormatTimeDE(row['created_at']) + '</td><td align="left">' + value + '</td></tr>' + html
        }
      }
      html = '<div class="history"><table class="table table-sm table-striped"><tbody><tr><th>Datum</th><th>Wert</th></tr>' + html + '</tbody></table></div>'
      Swal.fire({
        html: html,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        focusConfirm: true,
        confirmButtonText: 'OK',
        allowOutsideClick: () => !Swal.isLoading()
      })
    },
    loadProduct () {
      let loadingInstance = Loading.service({})
      axios
        .get(config.routes.manufacturer_product, {
          params: { manufacturer_sku: this.$route.params.manufacturer_sku },
          headers: auth.getAuthHeader()
        })
        .then(response => {
          this.product = response.data[0]
          this.history = response.data
          loadingInstance.close()
        })
        .catch(e => {
          loadingInstance.close()
          if (e.response.status == 401) {
            auth.logout()
          }
        })
    },
    dateFormatDE: (value) => {
      if (value) {
        return moment.utc(value).local().format('DD.MM.YYYY')
      } else {
        return ''
      }
    },
    formatPrice: (value) => {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    dateFormatTimeDE: (value) => {
      return moment.utc(value).local().format('DD.MM.YY HH:mm')
    },
    dateformat (cellValue) {
      if (cellValue) {
        return moment(new Date(cellValue))
          .format('DD.MM.YY')
      }
    },
    isAfterToday (comparedate) {
      return moment(new Date()).isBefore(moment(new Date(comparedate)))
    }
  },
  computed: {},

  created () {
    this.loadProduct()
    /* EventBus.$on('scan', input1 => {
      this.$router.push({ name: 'Dashboard', params: { q: input1 } })
    }) */
  }
}
</script>
<style scoped>

.card-fullwidth .card-content{
  padding: 0px;
}

.card-header-product h4{
  text-align: center;
  padding:10px;
  font-size: 1.3em;
}

.el-table .cell{
  word-break: keep-all;
}

.card-title{
  text-align: left;
}

.card .numbers{
  text-align: center;
  font-size: 16px;
}

.card-content img{
  padding: 5px;
}

.history{
  max-height: 90vh;
  overflow: scroll;
}

</style>
