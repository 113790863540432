import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Side bar
import SidebarLinks from './sidebarLinks'
import SideBar from './components/SidebarPlugin'

// library imports
import '../node_modules/slick-carousel/slick/slick.scss'
import '../node_modules/slick-carousel/slick/slick-theme.scss'
import './assets/sass/paper-dashboard.scss'
import './assets/sass/element_variables.scss'
import './assets/sass/demo.scss'

import VueNotify from 'vue-notifyjs'

// Element ui
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

// Global event bus
export const EventBus = new Vue()

// global sound objects
export const SoundOk = new Audio(`${process.env.BASE_URL}sound/correct.mp3`)
export const SoundError = new Audio(`${process.env.BASE_URL}sound/wrong.mp3`)

// vee validate
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate'
import de from 'vee-validate/dist/locale/de.json'
import * as rules from 'vee-validate/dist/rules'
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver);
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
localize('de', de)

// vue chartist
Vue.use(require('vue-chartist'))

Vue.config.productionTip = false

Vue.use(SideBar, {sidebarLinks: SidebarLinks.getLinks()})

Vue.use(VueNotify)


const messages = {
  install() {
    Vue.prototype.$showErrorMessage = (notify, errorMessage) => {
      notify(
        {
          message: errorMessage,
          icon: 'ti-alert',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger',
          timeout: 1800
        })
    }
  }
}

Vue.use(messages)


locale.use(lang)

// Register a global custom directive called `v-focus`
Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
