import router from './router/index.js'
import config from './config.js'
import axios from 'axios'
import moment from 'moment'

export default {
  user: {
    authenticated: false
  },

  login (username, password) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.routes.login,
          { username: username, password: password },
          { crossdomain: true }
        )
        .then(response => {
          if (response.data.login_token !== '') {
            localStorage.setItem('login_token', response.data)
            localStorage.setItem('username', username)
            localStorage.setItem('keyboard', 'none')
            localStorage.removeItem('scanHistory')
            localStorage.removeItem('shipmentstats')
            localStorage.setItem(
              'login_valid',
              moment(new Date())
                .add(10, 'h')
                .toDate()
            )
            this.user.authenticated = true

            axios
              .get(config.routes.loaduserdata, {headers: this.getAuthHeader()})
              .then(response => {
                if (response.data !== '') {
                  localStorage.setItem('userdata', JSON.stringify(response.data[0]))
                  resolve(response.data.message)
                } else {
                  reject(new Error(response.data.message))
                }
              })
              .catch(e => {
                console.log('could not load userdata')
                reject(new Error(e.message))
              })
            axios
              .get(config.routes.loadpermissions, {headers: this.getAuthHeader()})
              .then(response => {
                if (response.data !== '') {
                  localStorage.setItem('permissions', JSON.stringify(response.data))

                  router.push({ name: 'Overview' })
                  resolve(response.data.message)
                  location.reload()
                } else {
                  reject(new Error(response.data.message))
                }
              })
              .catch(e => {
                console.log('could not load permissions')
                reject(new Error(e.message))
              })
            resolve(response.data.message)
          } else {
            reject(new Error(response.data.message))
          }
        })
        .catch(e => {
          console.log('username/password wrong')
          reject(new Error(e.message))
        })
    })
  },

  logout () {
    localStorage.clear()
    this.user.authenticated = false
    router.push({ name: 'Login' })
    location.reload()
  },

  checkAuth () {
    let loginToken = localStorage.getItem('login_token')
    if (loginToken) {
      // check login duration
      var validuntilDate = new Date(localStorage.getItem('login_valid'))
      var currentDate = new Date()

      if (currentDate > validuntilDate) {
        this.logout()
      }

      this.user.authenticated = true
      /* localStorage.setItem(
        'login_valid',
        moment(new Date())
          .add(3, 'h')
          .toDate()
      ) */
    } else {
      this.user.authenticated = false
      this.user.username = ''
    }
    return this.user.authenticated
  },

  getData () {
    return localStorage.getItem('login_token')
  },

  getUsername () {
    return localStorage.getItem('username')
  },

  // The object to be passed as a header for authenticated requests
  getAuthHeader () {
    let token = localStorage.getItem('login_token')
    // console.log(token)
    return { Authorization: 'Bearer ' + token }
  }
}
