<template>
  <div class="row">
    <div class="col-md-12 card">
      <div class="card-content row">
        <div class="col-sm-6">
        </div>
        <div class="col-sm-6">
          <input type="search" v-on:focus="empty" class="form-control input-sm searchtable" placeholder="suchen" v-model="searchQuery" aria-controls="datatables"><br/>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">

          <div class="visible-md visible-lg">
            <el-table :data="queriedData"
                    @row-click="gotolink"
                    @sort-change="sortChange"
                    border
                    :default-sort="{prop: 'created_at', order: 'descending'}"
                    style="width: 100%">

            <el-table-column min-width="50" align="left" prop="id" sortable="custom" label="ID" key="props.row.note_id">
              <template slot-scope="props">
                {{props.row.note_id}}
              </template>
            </el-table-column>

            <el-table-column min-width="80" align="left" prop="created_at" sortable="custom" label="Datum" key="props.row.created_at">
              <template slot-scope="props">
                {{dateformat(props.row.created_at)}}
              </template>
            </el-table-column>

            <el-table-column min-width="100" align="left" prop="type" sortable="custom" label="Typ" key="props.row.type">
            <template slot-scope="props">
              {{props.row.type}}
            </template>
          </el-table-column>

          <el-table-column min-width="100" align="left" prop="comment" label="Kommentar" key="props.row.comment">
            <template slot-scope="props">
              {{props.row.comment}}
            </template>
          </el-table-column>

          </el-table>
          </div>

          <div class="visible-xs visible-sm">
            <el-table :data="queriedData"
                    @row-click="gotolink"
                    @sort-change="sortChange"
                    border
                    :default-sort="{prop: 'created_at', order: 'descending'}"
                    style="width: 100%">

            <el-table-column min-width="50" align="left" prop="id" sortable="custom" label="ID" key="props.row.note_id">
              <template slot-scope="props">
                {{props.row.note_id}}
              </template>
            </el-table-column>

            <el-table-column min-width="80" align="left" prop="created_at" sortable="custom" label="Datum" key="props.row.created_at">
              <template slot-scope="props">
                {{dateformat(props.row.created_at)}}
              </template>
            </el-table-column>

            <el-table-column min-width="100" align="left" prop="type" sortable="custom" label="Typ" key="props.row.type">
            <template slot-scope="props">
              {{props.row.type}}
            </template>
          </el-table-column>

          </el-table>
          </div>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Zeige {{from + 1}} bis {{to}} von {{pagination.total}} Einträgen</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import axios from 'axios'
import config from '../../config.js'
import auth from '../../auth.js'

  import Vue from 'vue'
  import {Table, TableColumn, Select, Option, Loading} from 'element-ui'
  import PPagination from '@/components/Pagination.vue'
  import moment from 'moment'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)

export default {
    name: 'notes-view',
    data () {
      return {
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['id', 'sku', 'locations', 'type'],
        tableData: [],
        statusSwitch: false
      }
    },
    components: {
      PPagination
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              if (row[key]) {
                let rowValue = row[key].toString().toLowerCase()
                if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
                  isIncluded = true
                }
              }
            }

            let rowStatus = ''
            if (row.status === '1') {
              rowStatus = true
            } else { rowStatus = false }

            if (isIncluded && this.statusSwitch === rowStatus) {
              isIncluded = true
            } else {
              isIncluded = false
            }

            return isIncluded
          })
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.pagination.total < highBound) {
          highBound = this.pagination.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    methods: {
      onFiltered (filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      empty () {
        this.searchQuery = ''
      },
      gotolink (row) {
        this.$router.push({ name: 'Notiz', params: { noteid: row.note_id } })
      },
      loadData (params) {
        let loadingInstance = Loading.service({})
        axios
          .get(config.routes.getnotes, { params: params, headers: auth.getAuthHeader() })
          .then(response => {
            this.tableData = response.data
            loadingInstance.close()
          })
          .catch(e => {
            loadingInstance.close()
            if (e.response.status == 401) {
              auth.logout()
            }
          })
      },
      sortChange (event) {
        this.loadData({sort: event.prop, direction: event.order})
      },
      dateformat (cellValue) {
        if (cellValue) {
            return moment.utc(cellValue).local().format('DD.MM.YYYY')
        }
      },
      prioFormat (cellValue) {
        if (cellValue === config.prio.key.high) return config.prio.text.high
        if (cellValue === config.prio.key.medium) return config.prio.text.medium
        if (cellValue === config.prio.key.low) return config.prio.text.low

        return cellValue
      },
    },
    created () {
      this.loadData({ sort: 'created_at', direction: 'descending' })
    }
  }
</script>
<style scoped>
  .searchtable{
    width: 100%;
  }

  .el-table i{
    font-size: 22px;
  }

</style>
<style>
  @media (max-width: 424px) {

  }

</style>
