<template>
  <div>
    <div class="row visible-md visible-lg" v-if="hidePageSelect !== 'hide'">
      <div class="col-sm-12 text-right">
        Anzahl
        <el-select
          class="select-default"
          v-model="pagination.perPage"
          placeholder="Per page">
          <el-option
            class="select-default"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select><br/><br/>
      </div>
    </div>
    <el-table :data="filterData()" border style="width: 100%" @sort-change="onSortChange" :class="tableClassName"
              :default-sort="defaultSort" :row-class-name="rowClassName">
      <slot name="columns"></slot>
    </el-table>
    <div class="row">
      <div class="col-sm-6 pagination-info text-left"><br/>
        <p class="category">Zeige {{ this.from() + 1 }} bis {{ this.to() }} von {{ this.tableData.length }} Einträgen</p>
      </div>
      <div class="col-sm-6">
        <p-pagination class="pull-right"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="this.tableData.length">
        </p-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import PPagination from '../Pagination'
import {Table, TableColumn, Select, Option} from 'element-ui'
import Vue from 'vue'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

export default {
  name: 'paged-table',
  components: {
    PPagination
  },
  data () {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50]
      },
      sort: null
    }
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    defaultSort: {},
    // eslint-disable-next-line vue/require-prop-type-constructor
    rowClassName: '',
    // eslint-disable-next-line vue/require-prop-type-constructor
    tableClassName: '',
    // eslint-disable-next-line vue/require-prop-type-constructor
    hidePageSelect: ''
  },
  computed: {
  },
  methods: {
    filterData () {
      let sorted = this.tableData.slice(0)
      if (this.sort) {
        const direction = this.sort.order === 'ascending' ? '' : '-'
        sorted.sort(this.dynamicSort(`${direction}${this.sort.prop}`))
      }

      let filtered = sorted
      return filtered.slice(this.from(), this.to())
      // return this.tableData.slice(this.from(), this.to())
    },
    dynamicSort (property) {
      var sortOrder = 1
      if (property[0] === '-') {
        sortOrder = -1
        property = property.substr(1)
      }
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
      }
    },
    onSortChange (column) {
      if (column.prop) { this.sort = {prop: column.prop, order: column.order} } else { this.sort = null }
    },
    sortNumeric: function (a, b) {
      return a.value - b.value
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    to () {
      let highBound = this.from() + this.pagination.perPage
      if (this.tableData.length < highBound) {
        highBound = this.tableData.length
      }
      return highBound
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  async mounted () {

  }
}

</script>
<style>
.el-table .warning-row {
  background: lightpink;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
