<template>
  <div>
    <form @submit.prevent="submitShippingForm">
      <div class="input-group">
        <input :inputmode="keyboard" v-model="shipmentid" ref="inputfield" v-focus type="text" class="form-control" placeholder="Sendungsnummer / Box">
        <span class="input-group-addon" @click="submitShippingForm"><i class="fa fa-search"></i></span>
      </div>
    </form>
    <stats></stats>
  </div>
</template>
<script>
  import axios from 'axios'
  import auth from '@/auth.js'
  import config from '@/config.js'
  import {Loading} from 'element-ui'

  import Stats from './StatsView.vue'

  export default {
    components: {
      Stats
    },
    data () {
      return {
        shipmentid: '',
        items: {},
        lvItems: [],
      }
    },
    methods: {
      submitShippingForm () {
        if (this.shipmentid) {
          this.handleScan(this.shipmentid)
          this.shipmentid = ''
        }
      },
      handleScan (shipmentId) {
        if (shipmentId) {
          let boxName = shipmentId
          let loadingInstance = Loading.service({})
          axios
            .get(config.routes.boxesToPack + '/' + boxName,
                { headers: auth.getAuthHeader() }
            )
            .then(response => {
              if (response.data){
                if (response.data.length == 0) {
                  this.$showErrorMessage(this.$notify,
                      'Es wurden keine Sendungen für die Box "' + boxName + '" gefunden'
                  )
                } else if (response.data[0].length == 1) {
                  let incrementId = response.data[0][0].shipmentIncrementId
                  this.$router.push({name: 'Packen', params: {q: incrementId}})
                } else {
                  this.$router.push({name: 'PickBoxes', params: {q: boxName, s: response.data}})
                }
              } else {
                let loadingInstance = Loading.service({})
                axios
                  .get(config.routes.shipment, {
                    params: {incrementId: shipmentId},
                    headers: auth.getAuthHeader()
                  })
                  .then(response => {
                    if (response.data['is_commissioned'] === false) {
                      this.$showErrorMessage(this.$notify,
                          'Die Sendung "' + shipmentId + '" wurde noch nicht vollständig kommissioniert.'
                      )
                      this.$router.push({ name: 'Packen' })
                    } else {
                      var items = response.data['items']
                      var boxes = response.data['boxes']
                      let nrPackages = 0
                      if (boxes) {
                        for (let box of boxes) {
                          // Umwandeln von int auf bool für Switch/checkbox
                          box.is_package = box.is_package === 1
                          box.falltest = box.falltest === 1
                          if (box.is_package) {
                            nrPackages++
                            box.packagenumber = nrPackages
                          }
                          let lvitem = this.findLVItem(box.sku)
                          box.name = lvitem.name
                        }
                      }
                      if (items && items.length) {
                        localStorage.setItem('shipmentId', JSON.stringify(shipmentId))
                        localStorage.setItem('shipment', JSON.stringify(response.data))
                        localStorage.setItem('scanItems', JSON.stringify(items))
                        localStorage.setItem('boxItems', JSON.stringify(boxes))
                        this.saveshipmentscan()
                        this.saveshipmentcomment('Packen Start')
                        this.$router.push({name: 'Prüfen'})
                      } else {
                        this.$showErrorMessage(this.$notify,
                        'Es wurden keine Sendung für "' + shipmentId + '" gefunden'
                        )
                        this.$router.push({name: 'Packen'})
                      }
                    }
                    loadingInstance.close()
                    this.backlink = shipmentId
                  })
                  .catch(e => {
                    loadingInstance.close()
                    if (e.response.status == 401) {
                      auth.logout()
                    }
                    this.$showErrorMessage(this.$notify,
                        'Es wurden keine Sendung für "' + shipmentId + '" gefunden'
                    )
                  })
              }
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      saveshipmentscan () {
        let url = config.routes.scanshipment
        let shipment = JSON.parse(localStorage.getItem('shipment'))
        if (shipment) {
          let loadingInstance = Loading.service({})
          axios
            .post(url, {
              'shipmentScan': {
                'shipmentId': shipment.shipment_id,
                'shipmentIncrementId': shipment.increment_id,
                  // "duration": 0,
                'packer': localStorage.getItem('username'),
                'timetype': 1,
                'ordervalue': shipment.order_value,
                'storeId': shipment.store_id
              }
            },
              { headers: auth.getAuthHeader() }
              )
            .then(response => {
              localStorage.setItem('shipmentscan_id_check', JSON.stringify(response.data.shipmentscan_id))
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      saveshipmentcomment (comment) {
        let url = config.routes.shipmentcomment
        let shipment = JSON.parse(localStorage.getItem('shipment'))
        comment = comment + '<br/>by ' + localStorage.getItem('username')
        if (shipment) {
          let loadingInstance = Loading.service({})
          axios
            .post(url, {
              'shipmentId': shipment.shipment_id,
              'comment': comment
            },
              { headers: auth.getAuthHeader() }
              )
            .then(() => {
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
      loadLvItems () {
        let url = config.routes.lvitems
        axios
            .get(url, {
              headers: auth.getAuthHeader()
            })
            .then(response => {
              var items = response.data
              if (items.length) {
                localStorage.setItem('lvItems', JSON.stringify(items))
              }
            })
            .catch(e => {
              if (e.response.status == 401) {
                auth.logout()
              }
            })
      },
      findLVItem (sku) {
        let items
        if (localStorage.getItem('lvItems')) {
          items = JSON.parse(localStorage.getItem('lvItems'))
        }
        let f
        let found
        found = items.findIndex(function (item, index) {
          f = index
          return item.sku.toLowerCase() === sku.toLowerCase()
        })
        if (found >= 0) {
          return items[f]
        }
      },
      endshipment (shipmentScanId) {
        if (shipmentScanId) {
          let url = config.routes.scanshipment + '/' + shipmentScanId
          let loadingInstance = Loading.service({})
          axios
            .put(url, {
              'shipmentScan': {
                'endtime': new Date().toISOString().replace('T', ' ').substr(0, 10) + ' ' + new Date().toLocaleTimeString()
              }
            }, { headers: auth.getAuthHeader() })
            .then(() => {
              loadingInstance.close()
            })
            .catch(e => {
              loadingInstance.close()
              if (e.response.status == 401) {
                auth.logout()
              }
            })
        }
      },
    },
    mounted () {
    },
    created () {
      if (localStorage.getItem('commission_boxes_shipment')) {
        localStorage.removeItem('commission_boxes_shipment')
      }
      this.keyboard = localStorage.getItem('keyboard')
      if (localStorage.getItem('shipmentscan_id_check')) {
        localStorage.removeItem('shipmentscan_id_check')
      }
      if (localStorage.getItem('shipmentscan_id_pack')) {
        localStorage.removeItem('shipmentscan_id_pack')
      }
      if (this.$route.params.q) {
        this.handleScan(this.$route.params.q)
      }
      if (!localStorage.getItem('lvItems')) {
        this.loadLvItems()
      }
      if (localStorage.getItem('scanItems') && localStorage.getItem('shipmentId')) {
        localStorage.removeItem('scanItems')
        localStorage.removeItem('shipmentId')
        localStorage.removeItem('shipment')
        localStorage.removeItem('boxItems')
      }
    },
    computed: {
    },
    destroyed () {}
  }
</script>
