<template>
  <div>
    <el-table :data="tableData" v-bind="$attrs" style="width: 100%">
      <slot name="columns">
        <el-table-column
            :sortable="column.sortable ? 'custom' : false"
            v-for="column in columns"
            :key="column.prop"
            v-bind="column"
        >
          <template slot-scope="{row}">
            <slot :name="column.prop || column.type || column.label" :row="row">
              {{row[column.prop]}}
            </slot>
          </template>
        </el-table-column>
      </slot>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    tableData: Array, // Data from the API call
  },
};
</script>