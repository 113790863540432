<template>
  <div class="card">
    <div class="card-content">
      <div class="row">
        <div>
          <slot name="header"></slot>
        </div>
        <div>
          <slot name="content"></slot>
        </div>
        <div>
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'product-card',
    props: ['linkto']
  }

</script>
<style scoped>
  .card-content{
    min-height: 85px;

  }

  a.card-link {  /* This is the link of the entire card */
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .card .numbers{
    font-size: 16px;
    color: #252422;
    text-align: center;
    margin-top: 1em;
  }

  @media (min-width: 992px) {
    .card .numbers {
      min-height: 50px;
      padding-left: 1em;
      padding-right: 1em;
      overflow: hidden;
    }
  }

  @media (min-width: 768px) {
    .card .numbers {
      min-height: 50px;
      padding-left: 1em;
      padding-right: 1em;
      overflow: hidden;
    }
  }

</style>
